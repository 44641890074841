import React, { useEffect, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import EventEditor from './EventEditor';
import EventList from './EventList';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useSalesManagement from '../Hooks/UseSalesManagement';

import { UserContext } from '../Contexts/UserContext';
import { EditorContext } from '../Contexts/EditorContext';
import { useMessage } from '../Contexts/SystemMessageContext';

import UserTutorial from '../Pages/UserTutorial';
import Subscriptions from '../Pages/Subscriptions';

import './UserAccount.scss';

const UserAccount = () => {
  const navigate = useNavigate();
  const { getStripeCustomer } = useSalesManagement();
  const { user, setCustomerDetails, setSubscriptionsList, showTutorial } = useContext(UserContext);
  const { setWaiting, setWaitingMessage } = useMessage();
  const { activeComponent } = useContext(EditorContext);
  const { getEvents } = useEditorManagement();

  const [ isDataLoaded, setIsDataLoaded ] = useState(false);

  const loadUserData = useCallback(async () => {
    if (!user) {
      return;
    }

    setWaiting(true);
    setWaitingMessage('Loading account details...');

    await getEvents();
    const customer = await getStripeCustomer(user.stripeCustomerId);
    setCustomerDetails(customer);
    setSubscriptionsList(user.subscriptions || []);
    setIsDataLoaded(true);

    setWaiting(false);
    setWaitingMessage('');
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }
    loadUserData();
  }, [user]);

  if (!user) {
    navigate('/');
    return;
  }

  if (!isDataLoaded) {
    return null;
  }
  if (!isDataLoaded) {
    return null;
  }

  return (
    <div className='user-account'>
      {!user.firstLogin ? (
        !user.subscriptions || user.subscriptions.length === 0 ? (
          <Subscriptions />
        ) : (
          activeComponent === 'editor' ? (
            <>
              <EventEditor />
            </>
          
          ) : <UserDashboard />
        )
      ) : (
        <UserTutorial displayName={user.displayName} />
      )}
    </div>
  );
};

export default UserAccount;

const UserDashboard = () => {
    return (
      <div className='user-dashboard'>
        <UserDetails />
        <EventList />
      </div>
    );
}

const UserDetails = () => { 
  const { user } = useContext(UserContext);
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
  
    const formattedDate = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
  
    return formattedDate;
  };
  

  return (
    <div className='user-details panel'>
      <div className='user-details-header'>
        <div className='user-details-header-title'>
          Welcome {user?.displayName}
        </div>
        <div className='user-details-header-title'>
          Johned: {formatDate(user?.joined) || 'N/A'}
        </div>
        <div className=''>
          {
            user?.subscriptions?.length > 0 ?
               (
                <div>
                  <div>Subscriptions: {user?.subscriptions?.length}</div>
                  {user?.subscriptions?.length > 0 && (
                    <div>{user?.subscriptions?.length}</div>
                  )}
                </div>
               ) : 'No Subscriptions'
          }
        </div>
      </div>
    </div>
  );
}