import React, { useState, useContext, createContext } from 'react';

const SystemMessageContext = createContext();

export const useMessage = () => useContext(SystemMessageContext);

export const SystemMessageProvider = ({ children }) => {
  const [ message, setMessage ] = useState(null);
  const [ waiting, setWaiting ] = useState(false);
  const [ waitingMessage, setWaitingMessage ] = useState('');

  const showMessage = (msg) => {
    setMessage(msg);
  };
  const clearMessage = () => setMessage(null);

  const contextValue = {
    message, showMessage, clearMessage, waiting, setWaiting, waitingMessage, setWaitingMessage
  };

  return (
    <SystemMessageContext.Provider value={contextValue}>
      {children}
    </SystemMessageContext.Provider>
  );
};
