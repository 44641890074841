// PrivacyPolicy.js
import React from 'react';

import './LegalPage.scss';

const Disclaimer = () => {
  return (
    <div className='panel'>
      <div className='legal-page'>
        <h1>Disclaimer</h1>

        <h2>General Disclaimer</h2>
        <p>The information provided by Cylandar ("we," "us," or "our") on our website and mobile application is for general informational purposes only. All information on the platform is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the platform.</p>

        <h2>No Professional Advice</h2>
        <p>The information provided by Cylandar is not a substitute for professional advice. Any use of the information provided on this platform is solely at your own risk. We are not responsible for any decisions you make based on the information found on our platform. For specific advice tailored to your situation, consult with a professional or specialist in the field.</p>

        <h2>Limitation of Liability</h2>
        <p>Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the platform or reliance on any information provided on the platform. Your use of the platform and your reliance on any information on the platform is solely at your own risk.</p>

        <h2>External Links Disclaimer</h2>
        <p>The Cylandar platform may contain (or you may be sent through the platform) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.</p>
        <p>We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the platform or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</p>

        <h2>Affiliate Disclaimer</h2>
        <p>The platform may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links. Our affiliates include (but are not limited to) third-party companies and individuals.</p>

        <h2>Errors and Omissions Disclaimer</h2>
        <p>The information given by the platform may contain errors or inaccuracies and may not be complete or current. Cylandar does not warrant the accuracy or completeness of the information, text, graphics, links, or other items contained within the platform. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update information at any time without prior notice.</p>

        <h2>Fair Use Disclaimer</h2>
        <p>Cylandar may use copyrighted material which has not always been specifically authorized by the copyright owner. We make such material available in an effort to advance understanding of environmental, political, human rights, economic, democracy, scientific, and social justice issues, etc. This constitutes a "fair use" of any such copyrighted material as provided for in section 107 of the US Copyright Law.</p>

        <h2>Views Expressed Disclaimer</h2>
        <p>The views and opinions expressed on the Cylandar platform are those of the authors and do not necessarily reflect the official policy or position of any other agency, organization, employer, or company. Comments published by users are their sole responsibility and the users will take full responsibility, liability, and blame for any libel or litigation that results from something written in or as a direct result of something written in a comment. We are not liable for any comment published by users and reserve the right to delete any comment for any reason whatsoever.</p>

        <h2>No Endorsement Disclaimer</h2>
        <p>Any references to specific products, services, or processes of third parties do not constitute or imply endorsement, sponsorship, or recommendation by Cylandar. Product and service information are solely for informational purposes.</p>

        <h2>Legal Disclaimer</h2>
        <p>The information provided by Cylandar does not constitute legal advice, nor does it create an attorney-client relationship. Legal advice must be tailored to the specific circumstances of each case, and laws are constantly changing, thus the information provided should not be used as a substitute for the advice of competent legal counsel.</p>

        <p>By using the Cylandar platform, you hereby acknowledge that you have read and understand this disclaimer and agree to be bound by its terms.</p>
      </div>
    </div>
  );
};

export default Disclaimer;
