import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../Contexts/UserContext';
import { useMessage } from '../Contexts/SystemMessageContext';

import useSalesManagement from '../Hooks/UseSalesManagement';

import CheckoutForm from '../Controls/CheckoutForm';

import './Subscriptions.scss';

const Subscriptions = () => {
  const { user, setCustomerDetails, setSubscriptionsList } = useContext(UserContext);
  const { getProductList, getStripeCustomer } = useSalesManagement();
  const navigate = useNavigate();
  const { setWaiting, setWaitingMessage } = useMessage();

  const [ productList, setProductsList ] = useState([]);
  const [ selectedProduct, setSelectedProduct ] = useState(null);
  const [ isDataLoaded, setIsDataLoaded ] = useState(false);
  
  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }
    const getProducts = async () => {
      const products = await getProductList();
      setProductsList(products);
    };
    getProducts();
  }, [getProductList]);

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }
    
    const loadUserData = async () => {
      const customer = await getStripeCustomer(user.stripeCustomerId);
      setCustomerDetails(customer);
      setSubscriptionsList(user.subscriptions || []);
      setIsDataLoaded(true);
    };
    
    loadUserData();
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }
    const message = 'Loading account details...';
    if (!isDataLoaded) {
      setWaiting(true);
      setWaitingMessage(message);
    } else {
      setWaiting(false);
      setWaitingMessage('');
    }
  }, [isDataLoaded]);

  if (!user) {
    navigate('/');
    return;
  }

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const handleSelectProduct = async(product) => {
    const productClone = JSON.parse(JSON.stringify(product));
    setSelectedProduct(productClone);
  }

  const handleCancelSubscription = () => {
    setSelectedProduct(null);
    navigate('/user-account');
  }

  const subscribeText = user.trial.started ? 'Subscribe' : 'Start Free Trial';

  return (
    <div className='panel'>
      <div className='subscriptions'>
        <div className='subscriptions-header'>
          Subscriptions
          <div className='subscription-menu'>
          {user.trial.started && <button onClick={handleCancelSubscription}>cancel</button>}
        </div>
        </div>
            
        <div className='subscriptions-list'>
          {productList.filter(product => 
            user.trial.started || (!user.trial.started && product.stripe_metadata_trial === "true")
          ).map((product) => (
            <div className={`subscriptions-list-item ${selectedProduct && selectedProduct.name === product.name ? 'selected' : ''}`} 
            key={product.name}>
              <div className='subscriptions-list-item-name'>{product.name}</div>
              <div>{product.description}</div>
              <div>1 Promo Site</div>
              <div>{product.metadata['sites']} Location-Base Site</div>
              <div>{product.metadata['media']} Media Files</div>
              <div>{product.metadata['activities']} Activities</div>
              <div>{currencyFormatter.format(product.prices[0].unit_amount / 100)}/mo</div>
                <button onClick={() => handleSelectProduct(product)} className={`subscriptions-list-item ${selectedProduct && selectedProduct.name === product.name ? 'selected' : ''}`} >
                  { selectedProduct && selectedProduct.name === product.name ? 'Selected' : subscribeText }
                </button>
            </div>
          ))
        }
        </div>
          {selectedProduct !== null &&
            <CheckoutForm 
              user={user}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
            />
          }
      </div>
    </div>
  );
}

export default Subscriptions;