import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';

import { updateUser } from '../Utilities/firestoreUtils';

import useSalesManagement from '../Hooks/UseSalesManagement';

import { UserContext } from '../Contexts/UserContext';
import { useMessage } from '../Contexts/SystemMessageContext';

import image_01 from '../Assets/tutorial_01.webp';
import image_02 from '../Assets/tutorial_02.webp';
import image_03 from '../Assets/tutorial_03.webp';
import image_04 from '../Assets/tutorial_04.webp';
import image_05 from '../Assets/tutorial_05.webp';

import './UserTutorial.scss';

const UserTutorial = () => {
  const location  = useLocation();
  const navigate = useNavigate();

  const { user, setUser, setCustomerDetails, setSubscriptionsList } = useContext(UserContext);
  const { getStripeCustomer } = useSalesManagement();
  const { setWaiting, setWaitingMessage } = useMessage();

  const [ currentPage, setCurrentPage] = useState(0);

  const [ isDataLoaded, setIsDataLoaded ] = useState(false);
  const [ isHelp, setIsHelp ] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }
    
    const loadUserData = async () => {
      const customer = await getStripeCustomer(user.stripeCustomerId);
      setCustomerDetails(customer);
      setSubscriptionsList(user.subscriptions || []);
      setIsDataLoaded(true);
    };
    
    loadUserData();
  }, [user]);

  useEffect(() => {
    if (!user) {
      navigate('/');
      return;
    }
    
    if (location.pathname === '/help') {
      setIsHelp(true);
    }
 
    const message = 'Loading account details...';
    if (!isDataLoaded) {
      setWaiting(true);
      setWaitingMessage(message);
    } else {
      setWaiting(false);
      setWaitingMessage('');
    }
  }, [isDataLoaded]);

  if (!user) {
    navigate('/');
    return;
  }
  const handleFinishTutorial = async () => {
    if (isHelp) {
      navigate('/user-account');
    } else {
      const updatedUser = { ...user, firstLogin: false };
      await updateUser(user.uid, { firstLogin: false });
      setUser(updatedUser);
    }
  };

  const tutorialSteps = [
    IntroductionToEvents,
    EnteringEventDetails,
    DefiningTheEventsPromotionalSite,
    ExplanationOfSitesAndTheirRole,
    HowToCreateAndGeofenceASite,
    OverviewOfDifferentMediaTypes,
    HowToUploadAndPlaceMedia,
    ActivitiesEngagingTasksAndChallenges,
    StepByStepGuideToCreatingAnActivity
  ];

  const isLastPage = currentPage === tutorialSteps.length - 1;
  const CurrentTutorialStep = tutorialSteps[currentPage];

  return (
      <div className='tutorial-page panel'>
        <h1>Welcome to Cylandar, {user.displayName}!</h1>
        <div className='tutorial-page-step-container'>
          <CurrentTutorialStep />
        </div>
        <div className="navigation-buttons">
            {currentPage > 0 && <button onClick={() => setCurrentPage(currentPage - 1)}>Back</button>}
            {isLastPage ? (
                <button onClick={handleFinishTutorial}>
                  { !isHelp ? 'Finish': 'Back to account'}
                </button>
            ) : (
                <button onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
            )}
        </div>
      </div>
  );
};

export default UserTutorial;

const IntroductionToEvents = () => {
  return (
      <div className="tutorial-page-step">
          <div className="content">
              <h2>Introduction to Events</h2>
              <p>An Event is an interactive experience designed in the web editor for mobile users to engage with digital content in real-world locations called Sites. Events can be used to create educational tours, interactive storytelling, or scavenger hunts. Each subscription allows access to one Event and one or more Sites.</p>

          </div>
          <div className="image">
              <img src={image_01} alt="Introduction to Events" />
          </div>
      </div>
  );
};

const EnteringEventDetails = () => (
  <div className="tutorial-page-step">
      <div className="content">
          <h2>Start by Entering the Event Details</h2>
          <p>Start by finding the address of your Sites and collecting Media files to be placed at each Site.</p>
          <p><strong>Entering the Event Details:</strong> Begin with filling in the Event name and description.</p>
          <p>Begin with filling in the Event name and description.</p>
      </div>
      <div className="image">
          <img src={image_02} alt="Event Details" />
      </div>
  </div>
);

const DefiningTheEventsPromotionalSite = () => (
  <div className="tutorial-page-step">
      <div className="content">
          <h2>Defining the Event's Promotional Site</h2>
          <p>The promotional site acts as a preview of your event and is accessible whenever a user is within range of any Site.</p>
          <p>Customize the Site: Upload promotional materials, such as images or videos, and provide details about the Event.</p>
      </div>
      <div className="image">
          <img src={image_03} alt="Event's Promotional Site" />
      </div>
  </div>
);

const ExplanationOfSitesAndTheirRole = () => (
  <div className="tutorial-page-step">
      <div className="content">
          <h2>Explanation of Sites and Their Role in Events</h2>
          <p>Sites are specific locations within an Event where activities occur, hosting various Media and Activities to enrich the user experience.</p>
      </div>
      <div className="image">
          <img src={image_04} alt="Sites Role" />
      </div>
  </div>
);

const HowToCreateAndGeofenceASite = () => (
  <div className="tutorial-page-step">
      <div className="text">
          <h2>How to Create and Geofence a Site</h2>
          <p>Add a New Site: In the Event editor, select the option to add a Site. Define the Site Location: Use the address lookup and map tool to pinpoint the exact location for the Site. Set Active Dates: Define the start and end date and time to specify when the site will be active.</p>
      </div>
      <div className="image">
          <img src={image_05} alt="Create and Geofence a Site" />
      </div>
  </div>
);

const OverviewOfDifferentMediaTypes = () => (
  <div className="tutorial-page-step">
      <div className="content">
          <h2>Overview of Different Media Types</h2>
          <p>Images: Static visuals like photos or illustrations. Audio: Sound clips or background music. Video: Moving visuals, which can be interactive or passive. 3D Meshes: Three-dimensional models that users can interact with in the app.</p>
      </div>
      <div className="image">
          <img src={image_01} alt="Different Media Types" />
      </div>
  </div>
);

const HowToUploadAndPlaceMedia = () => (
  <div className="tutorial-page-step">
      <div className="content">
          <h2>How to Upload and Place Media in the 3D Space of a Site</h2>
          <p>Upload Media: Use the media library to add media files. Place Media in 3D Space: Utilize the 3D editor to position and orient the Media within the Site’s geofenced area. Adjust Properties: Set properties like size, rotation, and scale to optimize the user experience.</p>
      </div>
      <div className="image">
          <img src={image_01} alt="Upload and Place Media" />
      </div>
  </div>
);

const ActivitiesEngagingTasksAndChallenges = () => (
  <div className="tutorial-page-step">
      <div className="content">
          <h2>Activities: Engaging Tasks and Challenges</h2>
          <p>Activities are designed as interactive tasks or challenges within an Event, such as finding objects, solving puzzles, or following a narrative path.</p>
      </div>
      <div className="image">
          <img src={image_01} alt="Activities" />
      </div>
  </div>
);

const StepByStepGuideToCreatingAnActivity = () => (
  <div className="tutorial-page-step">
      <div className="content">
          <h2>Step-by-Step Guide to Creating an Activity</h2>
          <p>Initiate an Activity Creation: Within the Event editor, select ‘Add Activity’. Define Activity Details: Name the Activity and outline its objectives and rules. Configure the Activity Settings: Set parameters like time limits, required actions, and messages to guide the users.</p>
      </div>
      <div className="image">
          <img src={image_01} alt="Creating an Activity" />
      </div>
  </div>
);
