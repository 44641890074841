import React from 'react';

import useEditorManagement from '../Hooks/UseEditorManagement';

import './CartesianValues.scss'; 

const CartesianValues = () => {
    const { setViewportType, setCartesianEditor } = useEditorManagement();

    const handleShowMediaViewport = () => {
        setCartesianEditor(true);
        setViewportType(3);
    };

    return (
        <div className='cartesian-values-editor component' onClick={() => handleShowMediaViewport()}>
           <div> 3D Coordinates </div>
           <div> Edit </div>
        </div>
    );
};

export default CartesianValues;