import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useSalesManagement from '../Hooks/UseSalesManagement';

import { UserContext } from '../Contexts/UserContext';

import './EventActivities.scss';

const EventActivities = () => {
    const [ subscription, setSubscription ] = useState([]);
    const { user} = useContext(UserContext);
    const { getSelectedEvent } = useEditorManagement();
    const { getSelectedSubscription } = useSalesManagement();

    const [ selectedStepIndex, setSelectedStepIndex ] = useState(0);
    const [ selectedEvent ] = useState(getSelectedEvent());
    const [ saveDisabled, setSaveDisabled ] = useState(true);

    const [ activities, setActivities ] = useState(selectedEvent.activities || []);
    const [ selectedActivity, setSelectedActivity ] = useState(activities[0]);

    const updateActivity = (updatedActivity) => {
        setActivities(activities.map(activity => {
            if (activity.id === updatedActivity.id) {
                return updatedActivity;
            }
            return activity;
        })); 
    };

    useEffect(() => {
        if (!user) return;
        setSubscription(getSelectedSubscription());
    }, [user]);

    useEffect(() => {
        if (selectedEvent.activities && selectedEvent.activities.length > 0) {
            setSelectedActivity(selectedEvent.activities[0]);
        } else {
            setSelectedActivity(null);
        }
    }, [selectedEvent.activities]);
    
    const handleSelectActivity = (activityId) => {
        const activity = activities.find(act => act.id === activityId);
        setSelectedActivity(activity);
    };

    const handleAddActivity = () => {
        const activitiesIncluded = subscription.activityCount || 0;
        const activitiesUsed = activities.length || 0;
    
        if (activitiesUsed >= activitiesIncluded) {
            alert('You have reached the maximum number of activities allowed for your subscription.');
            return;
        }

        const newActivityId = activities.length + 1;
        const newActivity = {
            eventId: selectedEvent.id,
            id: newActivityId, // Use the adjusted ID
            active: true,
            name: `Activity ${newActivityId}`,
            description: '',
            icon: '🆕',
            steps: [], // Ensure steps is an array
            timeBoxed: false,
            startDate: '',
            endDate: '',
            visitInOrder: false,
            completedMessage: '',
        };
    
        const defaultStep = {
            id: 1,
            type: 'Visit Site',
            siteId: selectedEvent.sites.length > 0 ? selectedEvent.sites[0].id : null,
            mediaId: 'none',
            name: '',
            description: '',
            completedMessage: '',
        };
        newActivity.steps = [defaultStep];
    
        setActivities([...activities, newActivity]);
        setSelectedActivity(newActivity);
    };   

    const handleARemoveActivity = () => {
        if (!selectedActivity) return;
    
        const filteredActivities = activities.filter(activity => activity.id !== selectedActivity.id);
        setActivities(filteredActivities);
        setSelectedActivity(filteredActivities.length > 0 ? filteredActivities[0] : null);
    };

    const newStep = () => {
        const defaultSiteId = selectedEvent.sites[0].id || null;
        const defaultMediaId = 'none'; 
        const step = {
            id: selectedActivity.steps.length + 1,
            type: 'Visit Site',
            siteId: defaultSiteId,
            mediaId: defaultMediaId,
            name: '',
            description: '',
            completedMessage: '',
        };
        return step;
    };

    const addStep = () => {   
        const updatedActivity = {
            ...selectedActivity,
            steps: [...selectedActivity.steps, newStep()]
        };
    
        setSelectedActivity(updatedActivity);
        updateActivity(updatedActivity);
        setSelectedStepIndex(updatedActivity.steps.length - 1);
    };

    const removeStep = (index) => {
        setSelectedActivity(prev => ({
            ...prev,
            steps: prev.steps.filter((_, idx) => idx !== index)
        }));
        setSelectedStepIndex(0);
    };

    return (
        <div className='event-activities-layout panel'>
            <ActivitiesList 
                activities={activities}
                selectedActivity={selectedActivity} 
                onSelectActivity={handleSelectActivity}
                onAddActivity={handleAddActivity}
                saveDisabled={saveDisabled}
                onRemoveActivity={handleARemoveActivity}
            />
            <ActivityEditor 
                selectedActivity={selectedActivity} 
                setSelectedActivity={setSelectedActivity}
                addStep={addStep}
                removeStep={removeStep}
                updateActivity={updateActivity}
                setSelectedStepIndex={setSelectedStepIndex}
                selectedStepIndex={selectedStepIndex}
                setSaveDisabled={setSaveDisabled}
            />
        </div>
    );
};

export default EventActivities;

const ActivitiesList = ({ activities, selectedActivity, onSelectActivity, onAddActivity, saveDisabled, onRemoveActivity }) => {
    const { user } = useContext(UserContext);
    const { saveActivity } = useEditorManagement();
    const { getSelectedSubscription } = useSalesManagement();
    
    const [subscription, setSubscription] = useState(null);

    useEffect(() => {
        if (user) {
            const subscriptionData = getSelectedSubscription();
            setSubscription(subscriptionData);
        }
    }, [user, getSelectedSubscription]);

    const activitySelectOptions = activities.map(activity => ({
        value: activity.id,
        label: activity.name
    }));

    const handleActivityChange = (selectedOption) => {
        onSelectActivity(selectedOption.value);
    };

    // Ensure subscription is not null before accessing its properties
    const activitiesIncluded = subscription ? subscription.activityCount || 0 : 0;
    const activitiesUsed = activities.length || 0;
    const activityCount = activitiesUsed + '/' + activitiesIncluded;
    
    return (
        <div className='activities-list panel'>
            {activities.length > 0 ? (
                <>
                    <div>Activities {activityCount}</div>
                    <div> Select Activity to edit:</div>
                    <Select
                        classNamePrefix='custom-select'
                        value={activitySelectOptions.find(option => option.value === selectedActivity.id)}
                        onChange={handleActivityChange}
                        options={activitySelectOptions}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                    />
                    <button onClick={onAddActivity}>Add</button>
                    <button onClick={onRemoveActivity} disabled={!selectedActivity}>Remove</button>
                    {selectedActivity && selectedActivity.steps.length > 0 && (
                        <button onClick={() => saveActivity(selectedActivity)} disabled={saveDisabled} type='submit'>Save</button>
                    )}
                </>
            ) : (
                <>
                    <p>This event doesn't have any activities.</p>
                    <button onClick={onAddActivity}>Add New Activity</button>
                </>
            )}
        </div>
    );
};

const ActivityEditor = ({ selectedActivity, setSelectedActivity, addStep, removeStep, updateActivity, setSelectedStepIndex, selectedStepIndex, setSaveDisabled }) => {
    const [ draggedIndex, setDraggedIndex ] = useState(null);
    const [ validationErrors, setValidationErrors ] = useState({});

    const handleDragStart = (e, index) => {
        setDraggedIndex(index);
    };

    const handleDrop = (e, dropIndex) => {
        if (draggedIndex === null || draggedIndex === dropIndex) return;

        const newSteps = [...selectedActivity.steps];
        const item = newSteps.splice(draggedIndex, 1)[0];
        newSteps.splice(dropIndex, 0, item);

        setSelectedActivity({
            ...selectedActivity,
            steps: newSteps
        });

        setDraggedIndex(null); // Reset drag state
    };
    
    const validateActivity = (activity) => {
        let errors = {};
        if (!activity.name.trim()) errors.name = 'is required';
        if (!activity.description.trim()) errors.description = 'is required';
        if (!activity.completedMessage.trim()) errors.completedMessage = 'is required';
        
        if (activity.timeBoxed) {
            const startDate = new Date(activity.startDate);
            const endDate = new Date(activity.endDate);
            const oneHour = 60 * 60 * 1000;
            if (isNaN(startDate.getTime()) || isNaN(endDate.getTime()) || startDate >= endDate || (endDate - startDate) < oneHour) {
                errors.timeRange = 'Invalid time range';
            }
            if (!activity.startDate.trim()) errors.startDate = 'Start Date is required';
            if (!activity.endDate.trim()) errors.endDate = 'End Date is required';
        }
        return errors;
    };
    
    const handleInputChange = (e, field) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const updatedActivity = { ...selectedActivity, [field]: value };

        setSelectedActivity(updatedActivity);
        updateActivity(updatedActivity);
    
        const errors = validateActivity(updatedActivity);
        setValidationErrors(errors);
    };

    const validateStep = (step) => {
        let errors = {};
        if (!step.name?.trim()) errors.name = 'Name is required';
        if (!step.description?.trim()) errors.description = 'Description is required';
        if (!step.completedMessage?.trim()) errors.completedMessage = 'Complete Message is required';
        return errors;
    };

    const validateAllSteps = (steps) => {
        if (!steps) return false;
        return steps.some(step => {
            const errors = validateStep(step);
            return Object.keys(errors).length > 0;
        });
    };

    useEffect(() => {
        if (selectedActivity) {
            const activityErrors = validateActivity(selectedActivity);
            const stepsValid = selectedActivity.steps ? !validateAllSteps(selectedActivity.steps) : false;
    
            setValidationErrors(activityErrors);
    
            const hasErrors = Object.keys(activityErrors).length > 0 || !stepsValid;
            setSaveDisabled(hasErrors);
        } else {
            setSaveDisabled(true);
        }
    }, [selectedActivity]);

    if (!selectedActivity) {
        return <div className='activity-editor'>event Activites are like quests, missions, tours or other goals that encourage Users to become more engaged with an Event.</div>;
    }

    const handleSelectStep = (index) => {
        setSelectedStepIndex(index);
    };

    return (
        <div className='activity-editor'>
            <div className='activity-editor-header panel'>
                <div className='activity-editor-header-left'>
                    <div className='activity-editor-header-active-name'>                  
                        <label className='activity-editor-header-name'>
                            {validationErrors.name ? `Activity Name: ${validationErrors.name}` : 'Activity Name:'}
                            <input
                                type='text'
                                value={selectedActivity.name}
                                onChange={(e) => handleInputChange(e, 'name')}
                            />
                        </label>
                        <label className='activity-editor-header-is-active'>
                            <input
                                type='checkbox'
                                checked={selectedActivity.active}
                                onChange={(e) => handleInputChange(e, 'active')}
                            />
                            <div>
                                {selectedActivity.active ? `Active` : 'Inactive'}
                            </div>
                        </label>   
                    </div>
                    <div className='activity-editor-header-description-completed'>
                        <label className='activity-editor-header-description'>
                            {validationErrors.description ? `Description: ${validationErrors.description}` : 'Description:'}
                            <textarea
                                type='text'
                                value={selectedActivity.description}
                                onChange={(e) => handleInputChange(e, 'description')}
                            />
                        </label>
                    
                        <label className='activity-editor-header-completed'>
                            {validationErrors.completedMessage ? `Completed Message: ${validationErrors.completedMessage}` : 'Completed Message:'}
                            <textarea
                                type='text'
                                value={selectedActivity.completedMessage}
                                onChange={(e) => handleInputChange(e, 'completedMessage')}
                            />

                        </label>
                    </div>
                </div>
                <div className='activity-editor-header-right'>
                    <div className='activity-editor-header-is-timed'>
                        <label>
                            Timeed:
                            <input
                                type='checkbox'
                                checked={selectedActivity.timeBoxed}
                                onChange={(e) => handleInputChange(e, 'timeBoxed')}
                            />
                        </label>                    
                    </div>
                    <div className='activity-editor-header-start-time'>
                        <label>
                            Start Date and Time:
                            <input
                                type='datetime-local'
                                value={selectedActivity.startDate}
                                onChange={(e) => handleInputChange(e, 'startDate')}
                                disabled={!selectedActivity.timeBoxed}
                            />
                            {validationErrors.startDate && <div className='error'>{validationErrors.startDate}</div>}
                        </label>
                    </div>
                    <div className='activity-editor-header-end-time'>
                        <label>
                            End Date and Time:
                            <input
                                type='datetime-local'
                                value={selectedActivity.endDate}
                                onChange={(e) => handleInputChange(e, 'endDate')}
                                disabled={!selectedActivity.timeBoxed}
                            />
                            {validationErrors.endDate && <div className='error'>{validationErrors.endDate}</div>}
                        </label>
                    </div>
                </div>               
            </div>
                
            <div className='activity-editor-steps'> 
                <div className='activity-editor-steps-container panel'>
                    <div className='activity-editor-steps-controls'>
                        <div>
                        Steps
                        </div>
                        <div>
                            <button onClick={addStep}>Add</button>
                        </div>
                    </div>
                    <div className='activity-editor-steps-list'>
                        {selectedActivity.steps.map((step, index) => (
                            <div key={index} className='activity-editor-step-selector'> 
                                <div className='activity-editor-step-selector-name' onClick={() => handleSelectStep(index)} draggable onDragStart={(e) => handleDragStart(e, index)} onDragOver={(e) => e.preventDefault()} onDrop={(e) => handleDrop(e, index)}>
                                    {index + 1}: {step.name}
                                </div>
                                <div className='activity-editor-step-selector-delete-button'>
                                    <button onClick={() => removeStep(index)} disabled={selectedActivity.steps.length < 2}>X</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='activity-editor-steps-editor panel'> 
                    {selectedStepIndex !== null && (
                        <StepEditor
                            selectedActivity={selectedActivity}
                            selectedStepIndex={selectedStepIndex}
                            setSelectedActivity={setSelectedActivity}
                            removeStep={removeStep}
                            handleDrop={(e) => handleDrop(e, selectedStepIndex)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

const StepEditor = ({ setSelectedActivity, selectedActivity, selectedStepIndex }) => {
    const { getSelectedEvent } = useEditorManagement();
    const [ selectedEvent ] = useState(getSelectedEvent());

    const step = selectedActivity.steps[selectedStepIndex];

    const selectedSite = selectedEvent.sites.find(site => site.id === step?.siteId) || selectedEvent.sites[0].id;

    const mediaOptions = selectedSite && selectedSite.media ? selectedSite.media : [];

    const [ stepErrors, setStepErrors] = useState({});

    useEffect(() => {
        if (selectedActivity.steps[selectedStepIndex]) {
            const step = selectedActivity.steps[selectedStepIndex];
            const errors = validateStep(step);
            setStepErrors(errors);
        }
    }, [selectedStepIndex, selectedActivity.steps]);

    const handleStepChange = (e, field) => {
        setSelectedActivity(prev => {
            const newSteps = [...prev.steps];
            newSteps[selectedStepIndex] = { ...newSteps[selectedStepIndex], [field]: e.target.value };
            return { ...prev, steps: newSteps };
        });

        const updatedStep = { ...step, [field]: e.target.value };
        const errors = validateStep(updatedStep);
        setStepErrors(errors);
    };

    const handleTypeChange = (selectedOption) => {
        setSelectedActivity(prev => {
            const newSteps = [...prev.steps];
            const updatedStep = newSteps[selectedStepIndex];
    
            updatedStep.type = selectedOption ? selectedOption.value : '';
    
            if (updatedStep.type === 'Visit Site') {
                updatedStep.mediaId = 'none';
            } else if (updatedStep.type === 'Interact with Media') {
                updatedStep.mediaId = mediaOptions.length > 0 ? mediaOptions[0].id : 'none';
            }
    
            return { ...prev, steps: newSteps };
        });
    };
    
    const handleSiteChange = (selectedOption) => {
        setSelectedActivity(prev => {
            const newSteps = [...prev.steps];
            newSteps[selectedStepIndex].siteId = selectedOption ? selectedOption.value : null;
            return { ...prev, steps: newSteps };
        });
    };

    const handleMediaChange = selectedOption => {
        setSelectedActivity(prev => {
            const newSteps = [...prev.steps];
            newSteps[selectedStepIndex].mediaId = selectedOption ? selectedOption.value : '';
            return { ...prev, steps: newSteps };
        });
    };

    const validateStep = (step) => {
        let errors = {};
        if (!step.name?.trim()) errors.name = 'is required';
        if (!step.description?.trim()) errors.description = 'is required';
        if (!step.completedMessage?.trim()) errors.completedMessage = 'is required';
        return errors;
    };

    const typeOptions = [
        { value: 'Visit Site', label: 'Visit Site' },
        { value: 'Interact with Media', label: 'Interact with Media' },
    ];

    const siteOptions = selectedEvent.sites.map(site => ({
        value: site.id,
        label: site.name
    }));

    const mediaSelectOptions = mediaOptions.map(media => ({
        value: media.id,
        label: media.name
    }));

    return (
        <div className='activity-step-editor'>
            <label className='activity-step-editor-name'>
                {stepErrors.name ? `Name: ${stepErrors.name}` : 'Name:'}
                <input
                    type='text'
                    value={step?.name || ''}
                    onChange={(e) => handleStepChange(e, 'name')}
                />
            </label>
            <div className='activity-step-editor-description-complete'>
                <label className='activity-step-editor-description-complete-item'>
                    {stepErrors.description ? `Description: ${stepErrors.description}` : 'Description:'}
                    <textarea
                        type='text'
                        value={step?.description || ''}
                        onChange={(e) => handleStepChange(e, 'description')}
                    />
                </label>
                <label className='activity-step-editor-description-complete-item'>
                    {stepErrors.completedMessage ? `Completed Message: ${stepErrors.completedMessage}` : 'Completed Message:'}
                    <textarea
                        type='text'
                        value={step?.completedMessage || ''}
                        onChange={(e) => handleStepChange(e, 'completedMessage')}
                    />
                </label>
            </div>

            <div className='activity-step-editor-activity-type'>   
                <div className='activity-step-editor-activity-type-item'>
                    <div>Type:</div>
                    <Select
                        classNamePrefix='custom-select'
                        value={typeOptions?.find(option => option.value === step?.type) || typeOptions[0] || null }
                        onChange={handleTypeChange}
                        options={typeOptions}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                    />
                </div>
                {(step.type === 'Visit Site' || step.type === 'Interact with Media') && (
                    <div className='activity-step-editor-activity-type-item'>
                        Site:
                        <Select
                            classNamePrefix='custom-select'
                            value={siteOptions.find(option => option.value === step?.siteId) || siteOptions[0]}
                            onChange={handleSiteChange}
                            options={siteOptions}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                        />
                    </div>
                )}
                {step.type === 'Interact with Media' && mediaOptions.length > 0 && (
                    <div className='activity-step-editor-activity-type-item'>
                        Media:
                        <Select
                            classNamePrefix='custom-select'
                            value={mediaSelectOptions.find(option => option.value === step?.mediaId) || mediaSelectOptions[0]}
                            onChange={handleMediaChange}
                            options={mediaSelectOptions}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                        />
                    </div>
                )}
            </div> 
        </div>
    );
};
