import React, { useState } from 'react';
import './BetaAccess.scss';

const BetaAccess = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [accessGranted, setAccessGranted] = useState(false);

    const handleLogin = (e) => {
        e.preventDefault();

        // Hard-coded check for demonstration purposes
        if (username === 'admin' && password === 'password') {
            setAccessGranted(true);
        } else {
            alert('Access denied. Incorrect username or password.');
        }
    };

    const handleEmailRequest = (e) => {
        e.preventDefault();
    
        // This opens the user's default mail client with a pre-filled recipient, subject, and body
        const mailtoLink = `mailto:playformia.info@gmail.com?subject=Access Request for Cylandar&body=Hi, I would like to request access to Cylandar. My email is: ${email}`;
    
        window.location.href = mailtoLink;
    
        // Log and alert can be removed or kept for debugging purposes
        console.log('Request access for:', email);
        alert(`Access request initiated for: ${email}`);
    };
    

    if (accessGranted) {
        return <div>Welcome to the Cylandar editor!</div>;
    }

    return (
        <div className='beta-access'>
            <h1>Cylandar is in early beta</h1>
            <p>Enter your username and password to access the editor.</p>
            <form className='beta-access-content' onSubmit={handleLogin}>
                <div>
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Username" />
                </div>
                <div>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                </div>
                <button type="submit">Login</button>
            </form>
            <p>Request access via email</p>
            <form className='beta-access-content' onSubmit={handleEmailRequest}>
                <div>
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
                </div>
                <button type="submit">Request Access</button>
            </form>
        </div>
    );
};

export default BetaAccess;
