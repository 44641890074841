import React, { useContext, useEffect, useState }  from 'react';
import { useNavigate } from 'react-router-dom';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useSalesManagement from '../Hooks/UseSalesManagement';

import { UserContext } from '../Contexts/UserContext';

import Icon from '../Controls/Icon'

import { ReactComponent as ImageIcon } from '../Assets/image-icon.svg';

import './EventList.scss';

const EventList = () => {
    const { subscriptionsList = [] } = useContext(UserContext);
    const navigate = useNavigate();
    const { subscriptionDaysRemaining, setSelectedSubscription, cancelStripeSubscription, reactivateStripeSubscription  } = useSalesManagement();
    const { user, setUser } = useContext(UserContext);

    const handleAddEvent = async (subscription) => {
        const subscriptionId = subscription.subscriptionId;
        navigate('/create', { state: { type: 'event', subscriptionId }});
        setSelectedSubscription(subscription);
    };

    return (
        <div className='event-list panel'>
            <div className='event-list-header'>
                You have {subscriptionsList?.length || 0} active subscriptions.
                <button onClick={()=>navigate('/subscriptions')}>Add Another Subscription</button>
            </div>
            {subscriptionsList.length > 0 && (
                <div className='event-list-item-container'>
                        {subscriptionsList.map((subscription) => (
                            <div key={subscription.subscriptionId} className='event-list-subscriptions-item'>
                                {subscription.event === null ? (
                                    <div >
                                        <button onClick={()=>handleAddEvent(subscription)} disabled={!(subscription.status === 'trialing' || subscription.status === 'active')}>
                                            Setup event
                                        </button>
                                        {!subscription.recurring ? 
                                            <button onClick={()=>reactivateStripeSubscription(user.uid, subscription.subscriptionId, setUser)}> Resubscribe</button> : 
                                            <button onClick={()=>cancelStripeSubscription(user.uid, subscription.subscriptionId, setUser)}> Unsubscribe</button>
                                        }
                                    </div>
                                ) : (<div>
                                    <EventCard subscription={subscription} />
                                </div>)}
                                <div> { (subscription.trial ? 'Trial ' : '') + (subscription.recurring ? 'Renews in' : 'Ends in') +' '+ (subscriptionDaysRemaining(subscription) + ' days')}</div>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
};

export default EventList;

const EventCard = ({ subscription }) => {
    const { userEvents, selectItem, setActiveComponent } = useEditorManagement();
    const { setSelectedSubscription, getSubscriptionDetails } = useSalesManagement();
    const [ event, setEvent ] = useState({});
    const [ subscriptionDetails, setSubscriptionDetails ] = useState({});

    useEffect(() => {
        const loadSubscriptionDetails = async () => {
            const foundEvent = userEvents.find(e => e.id === subscription.event);
            if (foundEvent) {
                setEvent(foundEvent);
                const details = await getSubscriptionDetails(subscription, foundEvent);
                setSubscriptionDetails(details);
            }
        };
    
        loadSubscriptionDetails();
    }, [userEvents, subscription]);
    
    if (!event) return null;

    const handleEditEvent = async (eventId) => {
        selectItem({ itemType: 'event', id: eventId });
        setActiveComponent('editor');
        setSelectedSubscription(subscriptionDetails);
    }

    return (
        <div key={event.id} className='event-list-item'>
            <div className='event-list-item-details-container'>
                <div className='event-list-item-event-name'>{event?.name}</div>
                <div className='event-list-item-event-icon'>
                    {event?.icon ? (
                        <Icon type={'event'} item={event} isEditable={false}/>
                    ) : (<ImageIcon />)}
                </div>
            </div>
            <div className='event-list-item-event-description'>
                {event.description}
            </div>     
            <div className='event-list-item-event-stats'>
                <div className='event-list-item-event-stats-visits'>
                    Sites: {subscriptionDetails.siteCount}
                </div>
                <div className='event-list-item-event-stats-interactions'>
                    Media: {subscriptionDetails.mediaCount}
                </div>
                <div className='event-list-item-event-stats-sctivities'>
                    Activities: {subscriptionDetails.activityCount}
                </div>
            </div>
            <div className='event-list-item-event-edit'>
                <button onClick={() => handleEditEvent(event.id)}>
                    Edit Now
                </button>
            </div>
        </div>
    )
};
