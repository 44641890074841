import { getFirestore, doc, getDoc, getDocs, addDoc, setDoc, deleteDoc, query, collection, where, updateDoc, arrayUnion } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, signOut, GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getStorage, ref, listAll, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';

// Firestore Utilities
// Firestore setup
export const apiKey = 'AIzaSyC75zz2k-_SXT7AbB-lwrpMXLfffZEaXks';

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: 'playformia-d3d22.firebaseapp.com',
  projectId: 'playformia-d3d22',
  storageBucket: 'playformia-d3d22.appspot.com',
  messagingSenderId: '233411564719',
  appId: '1:233411564719:web:23cda86d12a1e8ce6ef05a',
  measurementId: 'G-GYVRHY9BZY'
};

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

const functions = getFunctions(firebaseApp);

export const countUsers = httpsCallable(functions, 'countUsers');

export const getFirestoreInstance = () => firestore;
/////////////////////////
// Base Functions
export const fetchDocumentData = async (collectionName, documentId) => {
  try {
    const docRef = await getDocumentRef(collectionName, documentId);
    const docSnapshot = await getDoc(docRef);
    return docSnapshot.exists() ? { id: docSnapshot.id, ...docSnapshot.data() } : null;
  } catch (error) {
    handleFirestoreError(error, 'fetching document');
  }
};

export const updateFirestoreData = async (collectionName, documentId, updatedData, showMessage, message) => {
  try {
    const docRef = getDocumentRef(collectionName, documentId);
    await updateDoc(docRef, updatedData);

    if(showMessage !== undefined) {
      handleFirestoreAction('update', message, showMessage);
    }

  } catch (error) {
    handleFirestoreError(error, 'updating document');
  }
};

export const addNewFirestoreDocument = async (collectionName, newData, showMessage, message) => {
  try {
    const collectionRef = collection(firestore, collectionName);
    const docRef = await addDoc(collectionRef, newData);
    await updateDoc(docRef, { id: docRef.id });
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      if(showMessage !== undefined) {
        handleFirestoreAction('add', message, showMessage);
      }
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.error("No such document!");
    }
  } catch (error) {
    handleFirestoreError(error, 'adding new document');
  }
};

export const deleteFirestoreDocument = async (collectionName, documentId, showMessage, message) => {
  try {
    const docRef = getDocumentRef(collectionName, documentId);
    await deleteDoc(docRef);
    if(showMessage !== undefined) {
      handleFirestoreAction('delete', message, showMessage);
    }
  } catch (error) {
    handleFirestoreError(error, 'deleting document');
  }
};

// Helper functions
const getDocumentRef = (collectionName, documentId) => doc(firestore, collectionName, documentId);

export const handleFirestoreError = (error, operation) => {
  console.error(`${operation}: ${error}`);
};

export const handleFirestoreAction = (action, status, showMessage) => {
  let messageContent = '';
  switch (action) {
    case 'add':
      messageContent = status;
      break;
    case 'update':
      messageContent = status;
      break;
    case 'delete':
      messageContent = status;
      break;
    default:
      messageContent = 'An unknown action was performed.';
  }
  showMessage(messageContent);
};

export const queryDocuments = async (collectionName, conditions) => {
  const collectionRef = collection(firestore, collectionName);
  const queryRef = query(collectionRef, ...conditions);
  const querySnapshot = await getDocs(queryRef);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

//Stripe
export const createStripeCustomer = async (user) => {
  const userId = {
    name: user.displayName,
    email: user.email,
  };
  try {
    const response = await fetch('https://us-central1-playformia-d3d22.cloudfunctions.net/createStripeCustomer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userId),
    });

    if (!response.ok) throw new Error('Network response was not ok');
    const { stripeCustomerId } = await response.json();

    return stripeCustomerId;
  } catch (error) {
    console.error('Error in createStripeCustomer:', error);
  }
};

export const fetchActiveProducts = async () => {
  try {
    const productsCollectionRef = collection(firestore, 'products');
    const activeProductsQuery = query(productsCollectionRef, where('active', '==', true));
    const querySnapshot = await getDocs(activeProductsQuery);

    const activeProducts = await Promise.all(querySnapshot.docs.map(async (productDoc) => {
      // For each product, fetch its prices subcollection
      const pricesCollectionRef = collection(firestore, `products/${productDoc.id}/prices`);
      const pricesSnapshot = await getDocs(pricesCollectionRef);

      // Map each price document to an object
      const prices = pricesSnapshot.docs.map(priceDoc => ({ id: priceDoc.id, ...priceDoc.data() }));

      // Return the product with its prices
      return { id: productDoc.id, ...productDoc.data(), prices };
    }));

    return activeProducts;
  } catch (error) {
    console.error("Error fetching active products and their prices:", error);
    handleFirestoreError(error, 'fetching active products and prices');
    return [];
  }
};

/////////////////////////
// Account
export const addNewUser = async (user, showMessage, message) => {
  const stripeCustomerId = await createStripeCustomer(user);
  
  const userDoc = {
    uid: user.uid,
    displayName: user.displayName,
    photoURL: user.photoURL,
    email: user.email,
    role: 'user',
    joined: new Date().toISOString(),
    event: [],
    deleted: false,
    firstLogin: true,
    trial:{
      days: 30,
      started: false,
      startDate: null,
      finished: false,
    },
    stripeCustomerId: stripeCustomerId,
  };
  try {
    const userRef = doc(firestore, 'user', user.uid);
    await setDoc(userRef, userDoc);

    if (showMessage !== undefined) {
      handleFirestoreAction('add', message, showMessage);
    }

    const newUserSnapshot = await getDoc(userRef);
    if (newUserSnapshot.exists()) {
      const newUser = { id: newUserSnapshot.id, ...newUserSnapshot.data() };
      return newUser;
    } else {
      console.error('Failed to fetch the newly added user document.');
      return null;
    }
  } catch (error) {
    handleFirestoreError(error, 'adding new user');
    return null;
  }
};

export const getUserAccount = async (uid) => {
  return await fetchDocumentData('user', uid);
};

export const updateUser = async (uid, updatedUserData, showMessage) => {
  try {
    const userRef = doc(firestore, 'user', uid);
    await updateDoc(userRef, updatedUserData);

    const message = `Updated user account for: ${updatedUserData.displayName}`;
    if (showMessage !== undefined) {
      handleFirestoreAction('update', message, showMessage);
    }
  } catch (error) {
    handleFirestoreError(error, 'updating user');
  }
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { success: true, message: 'Password reset email sent successfully.' };
  } catch (error) {
    console.error('Error sending password reset email:', error);
    return { success: false, message: error.message };
  }
};

/////////////////////////
// Login
export const LoginGoogle = async () => {
  const provider = new GoogleAuthProvider();

  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const accountExists = await getUserAccount(user.uid);

    if (accountExists) {
      return accountExists;
    } else {
      return user;
    }
  } catch (error) {
    handleFirestoreError(error, 'Google login');
  }
};

export const signInWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing in with email:', error);
    return null;
  }
};

// Create Account with Email and Password
export const createAccountWithEmail = async (email, password, displayName) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password, displayName);
    userCredential.user.displayName = displayName;
    return userCredential.user;
  } catch (error) {
    console.error('Error creating account with email:', error);
    return null;
  }
};

export const Logout = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    handleFirestoreError(error, 'Google sign out');
  }
};

export const observeUserChange = (callback) => {
  return auth.onAuthStateChanged(callback);
};

/////////////////////////
// Event
export const fetchUserEvents = async (uid) => {
  try {
    // Fetch the user document from the 'user' collection by UID
    const userDocRef = doc(firestore, 'user', uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const userSubscriptions = userData.subscriptions || [];

      const userEventsIds = userSubscriptions.reduce((acc, subscription) => {
        if (subscription.event != null) {
          acc.push(subscription.event);
        }
        return acc;
      }, []);
      
      const userEventsData = [];

      for (const eventId of userEventsIds) {
        const eventDocRef = doc(firestore, 'event', eventId);
        const eventDocSnap = await getDoc(eventDocRef);

        if (eventDocSnap.exists()) {
          userEventsData.push(eventDocSnap.data());
        } else {
          console.warn(`No such event document for ID: ${eventId}!`);
        }
      }
      return userEventsData;
    } else {
      console.error("No such user document!");
      return [];
    }
  } catch (error) {
    handleFirestoreError(error, 'fetching user events');
    return [];
  }
};

export const fetchEventSites = async (eventId) => {
  try {
    const conditions = [where('eventId', '==', eventId), where('deleted', '==', false)];
    return await queryDocuments('site', conditions);
  } catch (error) {
    handleFirestoreError(error, 'fetching event sites');
    return [];
  }
};

export const addEvent = async (subscriptionId, uid, eventDetails, siteDetails, showMessage) => {
  try {
    const newEvent = {
      active: false,
      deleted: false,
      created: new Date().toISOString(),
      icon: '',
      uid: uid,
      name: eventDetails.eventName,
      description: eventDetails.eventDescription,
      sites: [],
      activities: [],
      tags: [],
      eventstats: null,
    };

    const message = `Added event "${eventDetails.eventName}"`;
    const event = await addNewFirestoreDocument('event', newEvent, showMessage, message);
    
    const eventStats = {
      eventId: event.id,
      userInteractions: [],
    };
    
    const eventStatsDoc = await addNewFirestoreDocument('eventstats', eventStats, showMessage, `Created event stats for "${eventDetails.eventName}"`);
    newEvent.eventstats = eventStatsDoc.id;
    await updateFirestoreData('event', event.id, { eventstats: newEvent.eventstats }, showMessage, `Updated event "${eventDetails.eventName}" with eventstats ID`);

    const eventIcon = eventDetails.eventIcon;

    if (eventIcon) {
      const fileAlreadyExists = await fileExists(uid, event.id, eventIcon.name);
      if (fileAlreadyExists) {
        const existingMediaQuerySnapshot = await getDocs(query(collection(firestore, 'media'), where('uid', '==', uid), where('eventId', '==', event.id), where('name', '==', eventIcon.name)));
        const existingMediaEntry = existingMediaQuerySnapshot.docs[0].data();
        newEvent.icon = existingMediaEntry.id;
      } else {
        const onSuccess = (fileName) => console.warn(`${fileName} uploaded successfully`);
        const onError = (error) => console.error(`Error uploading file: ${error}`);
        const uploadMessage = `Uploading event preview image`;
        
        const media = await uploadFileToUserMedia(uid, event.id, eventIcon, true, onSuccess, onError, showMessage, uploadMessage);
        if (media && media.id) {
          const updatedEventData = { icon: media.id };
          await updateFirestoreData('event', event.id, updatedEventData, showMessage, `Updated event icon with media ID ${media.id}`);
          newEvent.icon = media.id;
        }
      }
    }

    const newSiteId = await addSite(uid, event.id, siteDetails, showMessage);
    newEvent.sites.push(newSiteId);
    event.sites.push(newSiteId);
    
    const promoSite = {
      promo: true,
      previewUrl: siteDetails.previewUrl,
      siteAddress: { city: '', coordinates: { latitude: 0, longitude: 0 }, country: '', postalCode: '', state: '', street: '' },
      siteIcon: siteDetails.siteIcon,
      siteName: 'Promo Site',
      siteDescription: 'This is a promo site for the event.',
      startDateTime: '1970-01-01T00:00:00.000Z',
      endDateTime: '1970-01-01T00:00:00.001Z',
    };
    const promoSiteId = await addSite(uid, event.id, promoSite, showMessage);
    newEvent.sites.push(promoSiteId);
    event.sites.push(promoSiteId);
    
    await addEventToUser(subscriptionId, uid, event, showMessage);
    return event;
  } catch (error) {
    handleFirestoreError(error, 'adding event');
    return null;
  }
};

const addEventToUser = async (subscriptionId, uid, event, showMessage) => {
  try {
    const userRef = doc(firestore, 'user', uid);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      let userData = userSnapshot.data();

      const subscriptionIndex = userData.subscriptions.findIndex(sub => sub.subscriptionId === subscriptionId);
      if (subscriptionIndex !== -1) {
        let subscription = userData.subscriptions[subscriptionIndex];
        subscription.event = event.id || null;

        userData.subscriptions[subscriptionIndex] = subscription;

        await updateDoc(userRef, { subscriptions: userData.subscriptions });

        if (showMessage !== undefined) {
          const message = `Added event "${event.name}" to subscription ${subscriptionId}`;
          handleFirestoreAction('update', message, showMessage);
        }
      } else {
        console.warn(`Subscription with ID: ${subscriptionId} not found!`);
      }
    } else {
      console.warn("No such user document!");
    }
  } catch (error) {
    handleFirestoreError(error, 'adding event to user subscriptions');
  }
};

export const addSite = async (uid, eventId, siteDetails, showMessage) => {
  try {
    const startDate = new Date(siteDetails.startDateTime);
    const endDate = new Date(siteDetails.endDateTime);

    const startDateTimestamp = {
      seconds: Math.floor(startDate.getTime() / 1000),
      nanoseconds: (startDate.getTime() % 1000) * 1000000,
    };

    const endDateTimestamp = {
      seconds: Math.floor(endDate.getTime() / 1000),
      nanoseconds: (endDate.getTime() % 1000) * 1000000,
    };

    const coordinates = {
      center: {
        latitude: siteDetails.siteAddress.coordinates.latitude,
        longitude: siteDetails.siteAddress.coordinates.longitude
      },
      northwest: {  
        latitude: siteDetails.siteAddress.coordinates.latitude + 0.00022522522522522523,
        longitude: siteDetails.siteAddress.coordinates.longitude - 0.00022522522522522523
      },
      southeast: {
        latitude: siteDetails.siteAddress.coordinates.latitude - 0.00022522522522522523,
        longitude: siteDetails.siteAddress.coordinates.longitude + 0.00022522522522522523
      }
    };

    const newSite = {
      promo: siteDetails.promo || false,
      active: true,
      deleted: false,
      eventId: eventId,
      icon: '',
      name: siteDetails.siteName,
      description: siteDetails.siteDescription,
      startDate: startDateTimestamp,
      endDate: endDateTimestamp,
      address: siteDetails.siteAddress,
      coordinates: coordinates,
      media: []
    };

    const siteIcon = siteDetails.siteIcon;

    let promoMedia = null;

    if (siteIcon) {
      const fileAlreadyExists = await fileExists(uid, eventId, siteIcon.name);

      if (fileAlreadyExists) {
        const existingMediaQuerySnapshot = await getDocs(query(collection(firestore, 'media'), where('uid', '==', uid), where('eventId', '==', eventId), where('name', '==', siteIcon.name)));
        const existingMediaEntry = existingMediaQuerySnapshot.docs[0].data();
        newSite.icon = existingMediaEntry.id;
        promoMedia = existingMediaEntry;

      } else {
        const onSuccess = (fileName) => console.warn(`${fileName} uploaded successfully`);
        const onError = (error) => console.error(`Error uploading file: ${error}`);
        const uploadMessage = `Uploading site preview image`;

        const media = await uploadFileToUserMedia(uid, eventId, siteIcon, true, onSuccess, onError, showMessage, uploadMessage);
        if (media && media.id) {
          newSite.icon = media.id;
          promoMedia = media;
        }
      }
      if (siteDetails.promo) {
        const currentDate = new Date().toISOString().slice(0, 10);
        promoMedia.guid = `${promoMedia.id}_${currentDate}`;
        promoMedia.startDate = {nanoseconds: 0, seconds: 0};
        promoMedia.endDate = {nanoseconds: 0, seconds: 0};
        promoMedia.position = [0, 0, 0];
        promoMedia.rotation = [0, 0, 0];
        promoMedia.scale = [1, 1, 1];
        newSite.media.push(promoMedia);
      }
    }

    const message = `Added site "${newSite.name}"`;
    const site = await addNewFirestoreDocument('site', newSite, showMessage, message);
    if (site && site.id) {
      const eventRef = doc(firestore, 'event', eventId);
      await updateDoc(eventRef, {
        sites: arrayUnion(site.id)
      });
    }

    if (newSite.icon) {
      await updateFirestoreData('site', site.id, { icon: newSite.icon }, showMessage, `Updated site icon with media ID ${newSite.icon}`);
    }

    return site.id;
  } catch (error) {
    handleFirestoreError(error, 'adding new site');
    return null;
  }
};

/////////////////////////
// Sites
export const fetchSiteDetails = async (siteId) => {
  try {
    return await fetchDocumentData('site', siteId);
  } catch (error) {
    handleFirestoreError(error, 'fetching site details');
    return null;
  }
};

// Storage
export const createPlaceholderFile = async (uid) => {
  const storage = getStorage();
  const placeholderRef = ref(storage, `usermedia/${uid}/placeholder.txt`);
  const placeholderContent = new Blob(['This is a placeholder file.'], { type: 'text/plain' });

  try {
      await uploadBytes(placeholderRef, placeholderContent);
  } catch (error) {
    handleFirestoreError('Error creating placeholder file: ', error);
  }
};

export const checkUserMediaFolder = async (uid) => {
  const storage = getStorage();
  const listRef = ref(storage, `usermedia/${uid}`);

  try {
      const res = await listAll(listRef);
      if (res.items.length > 0) {
      } else {
          await createPlaceholderFile(uid);
      }
  } catch (error) {
      if (error.code === 'storage/object-not-found') {
          await createPlaceholderFile(uid);
      } else {
        handleFirestoreError('Error checking media folder: ', error);
      }
  }
};

export const uploadFileToUserMedia = async (uid, eventId, file, isNewFile, onSuccess, onError, showMessage , message) => {
  const storage = getStorage();
  const fileTypeDirectory = getFileTypeDirectory(file.type);
  const fileRef = ref(storage, `usermedia/${uid}/${eventId}/${fileTypeDirectory}/${file.name}`);

  try {
    const uploadResult = await uploadBytes(fileRef, file);
    const url = await getDownloadURL(uploadResult.ref);
    const mediaEntry = {
        uid: uid,
        eventId: eventId,
        fileType: file.type,
        size: file.size,
        uploadedDate: new Date().toISOString(),
        thumbnail: url,
        name: file.name,
        url: url,
        fullPath: uploadResult.ref.fullPath,
        ...(file.type.includes('mesh') && { texture: '' })
    };
    let media;
    if (isNewFile) {
        media = await addNewFirestoreDocument('media', mediaEntry);
    } 
    onSuccess(file.name);
    return media;
  } catch (error) {
    onError(error);
    handleFirestoreError(error, 'upload File To User Media');
  }
};

const fileTypeDirectoryMap = {
  'image/': 'images',
  'audio/': 'audio',
  'video/': 'videos',
  'mesh/': 'meshes',
  'default': 'others'
};

const getFileTypeDirectory = (mimeType) => {
  return Object.keys(fileTypeDirectoryMap).find(key => mimeType.startsWith(key)) || fileTypeDirectoryMap.default;
};

export const fileExists = async (uid, eventId, fileName) => {
  try {
    const querySnapshot = await getDocs(query(collection(firestore, 'media'), where('uid', '==', uid), where('eventId', '==', eventId), where('name', '==', fileName)));
    return !querySnapshot.empty;
  } catch (error) {
    handleFirestoreError('Error checking if file exists: ', error);
    return false;
  }
};

// Media
export const fetchUserMedia = async (selectedEventId) => {
  try {
    const mediaEntries = await queryDocuments('media', [where('eventId', '==', selectedEventId)]);
    return mediaEntries;
  } catch (error) {
    handleFirestoreError('Error fetching media entries: ', error);
    return [];
  }
};

export const fetchUserMediaByType = async (selectedEventId, fileType) => {
  try {
    const allMediaEntries = await queryDocuments('media', [where('eventId', '==', selectedEventId)]);

    let filteredMediaEntries;
    if (fileType === 'all') {
      filteredMediaEntries = allMediaEntries;
    } else {
      filteredMediaEntries = allMediaEntries.filter(entry => entry.fileType && entry.fileType.includes(fileType));
    }
    return filteredMediaEntries;
  } catch (error) {
    handleFirestoreError('Error fetching media entries by type: ', error);
    return [];
  }
};

export const deleteMediaFromFirebase = async (media, isDuplicate) => {
  try {
      await deleteFirestoreDocument('media', media.id);
      if (!isDuplicate) {
        const storage = getStorage();
        const fileRef = ref(storage, media.fullPath);
        await deleteObject(fileRef);
      }

  } catch (error) {
    handleFirestoreError('Error deleting media: ', error);
  }
};

export const copyMediaFileInFirebase = async (selectedMedia) => {
  if (!selectedMedia || !selectedMedia.id) {
    handleFirestoreError('Error copying file:', 'Invalid or missing media selected for copying.');
    return;
  }

  try {
    const newMediaName = `copy_${selectedMedia.name}`;
    const newMediaRecord = {
      ...selectedMedia,
      name: newMediaName,
    };
    delete newMediaRecord.id;
  
    return await addNewFirestoreDocument('media', newMediaRecord);
  } catch (error) {
    handleFirestoreError('Error copying media file in Firestore:', error);
    return null;
  }
};
