// PrivacyPolicy.js
import React from 'react';

import './LegalPage.scss';

const AccessibilityStatement = () => {
  return (
    <div className='panel'>
      <div className='legal-page'>
        <h1>Commitment to Accessibility</h1>
        
        <p>
          Cylandar is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone and applying the relevant accessibility standards to enhance the usability of our platform.
        </p>

        <h2>Accessibility of Cylandar Platform</h2>
        <p>
          Cylandar's platform, including the 3D editor and augmented reality content, has been designed with accessibility in mind. However, we acknowledge that certain aspects of 3D environments and augmented reality may not be fully accessible to everyone. The nature of this content and the technical requirements for 3D rendering can present challenges for individuals with certain disabilities.
        </p>

        <h2>Efforts for Accessibility</h2>
        <p>
          We have made significant efforts to ensure that our platform is as accessible as possible by:
        </p>
        <ul>
          <li>Implementing features that support screen readers and keyboard navigation.</li>
          <li>Providing alternative text and descriptions for images and non-text content.</li>
          <li>Ensuring color contrasts meet accessibility guidelines to aid users with visual impairments.</li>
          <li>Offering user-friendly navigation and a coherent structure to assist users with cognitive disabilities.</li>
        </ul>
        <p>
          Despite our efforts, we understand that some aspects of the platform, especially the 3D editor and certain augmented reality features, may pose usability challenges for some users. We continuously work to improve accessibility and inclusiveness, but the evolving nature of the content and technology may result in some limitations.
        </p>

        <h2>Feedback and Contact Information</h2>
        <p>
          We welcome your feedback on the accessibility of Cylandar's platform. Please let us know if you encounter any barriers:
        </p>
        <ul>
          <li>Email: [Contact Email for Accessibility Feedback]</li>
          <li>Phone: [Contact Phone Number for Accessibility Issues]</li>
          <li>Address: [Physical Address for Accessibility Correspondence]</li>
        </ul>
        <p>
          Your feedback is crucial in guiding our ongoing efforts to improve accessibility. We aim to respond to feedback promptly and effectively address any issues that are brought to our attention.
        </p>

        <h2>Continuous Improvement</h2>
        <p>
          Cylandar is committed to continuous improvement in accessibility and to conforming to the Web Content Accessibility Guidelines (WCAG) 2.1, Level AA standards, and complying with the Americans with Disabilities Act (ADA) effective communication requirements, and other applicable regulations.
        </p>
        <p>
          To ensure this, we have undertaken the following actions:
        </p>
        <ul>
          <li>Regularly reviewing our platform to identify and fix potential accessibility issues.</li>
          <li>Training our staff to understand and implement accessibility guidelines and standards.</li>
          <li>Engaging with users and accessibility experts to gather feedback and make improvements.</li>
        </ul>

        <h2>Legal Compliance</h2>
        <p>
          We believe in a proactive approach to accessibility and strive to adhere to both moral and legal standards. Our goal is to ensure that Cylandar is as accessible and usable as possible for all users, recognizing the diversity of our community and the unique challenges presented by our platform's innovative features.
        </p>
        <p>
          By acknowledging the limitations and actively working towards enhancing accessibility, Cylandar aims to provide an inclusive and accessible digital environment for all users.
        </p>
      </div>
    </div>
  );
};

export default AccessibilityStatement;
