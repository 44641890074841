import React, { useContext, useRef, useState, useEffect } from 'react';

import useEditorManagement from '../Hooks/UseEditorManagement';

import { ModalContext } from '../Contexts/ModalContext';

import './DateRangeEditor.scss'; 

const DateRangeEditor = ({collection}) => {
    const { getSelectedEvent, getSelectedSite, getSelectedMedia, calculateSiteDuration } = useEditorManagement();

    const { showModal } = useContext(ModalContext);

    const componentRef = useRef(null);
    const [ position, setPosition ] = useState({ top: 0, left: 0 });

    useEffect(() => {
        // Function to calculate and update position
        const calculatePosition = () => {
            if (componentRef.current) {
                const rect = componentRef.current.getBoundingClientRect();
                setPosition({
                    top: 64,
                    left: rect.right
                });
            }
        };

        calculatePosition();
        window.addEventListener('resize', calculatePosition);

        return () => window.removeEventListener('resize', calculatePosition);
    }, []);

    let data;
    if (collection === 'site') {
        data = getSelectedSite();
    } else if (collection === 'media') {
        data = getSelectedMedia();
        if(!data) return;
    }

    const handleShowModal = () => {
        showModal('date', collection, data, position);
    };

    const getDuration = () => {
        const remaining = calculateSiteDuration(getSelectedEvent().id, getSelectedSite().id);
        if (remaining.daysRemaining < 0) {
            return 0;
        }
        else {
            return remaining.daysRemaining;
        }
    }

    const ends = getDuration.daysRemaining  === 0 ? 'Ends' : 'Ended';
    const siteDuration = getDuration();
    return (
        !getSelectedSite().promo ? (
            <div className='date-range component' onClick={handleShowModal} ref={componentRef}>
              <div>
                Days: {siteDuration} {ends}: {secondsToDate(data.endDate.seconds)}
              </div>
              <div
                >Edit
                </div>
            </div>
        ) : (
            <div className='date-range-inactive component-inactive'>
              <div>No Time limit</div>
            </div>
        )
    );
};

export default DateRangeEditor;

function secondsToDate(seconds) {
    const date = new Date(seconds * 1000);
    return date.toLocaleDateString();
}