import React, { createContext, useState, useCallback } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [ modalContent, setModalContent ] = useState(null);
    const [ isVisible, setIsVisible ] = useState(false);
    const [ position, setPosition ] = useState({});

    const showModal = useCallback((modalType, collection, data, position, handleAction) => {
        setModalContent({ modalType, collection, data, handleAction });
        setPosition(position);
        setIsVisible(true);
    }, []);

    const closeModal = useCallback(() => {
        setIsVisible(false);
        setModalContent(null);
        setPosition({});
    }, []);

    return (
        <ModalContext.Provider value={{ showModal, closeModal, modalContent, isVisible, position }}>
            {children}
        </ModalContext.Provider>
    );
};
