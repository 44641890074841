import { useEffect, useRef, useState, useContext } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { createRectangularGrid, createMapPlane, createMediaMeshes, loadMapTexture, createsSky, createLights } from '../Utilities/ThreeDMapUtils';

import useEditorManagement from './UseEditorManagement';
import useMediaManagement from './UseMediaManagement';

import { GoogleMapsContext } from '../Contexts/GoogleMapsContext';
import { EditorContext } from '../Contexts/EditorContext';
import { pl } from 'date-fns/locale';

const useThreeScene = (ref, onLoaded) => {
    const { updateItem, getSelectedSite, getSelectedMedia, selectItem, cameraPosition, setCameraPosition, cameraRotation, setCameraRotation, transformMode, setTransformMode, transformSpace, setTransformSpace, mediaRemoved, setCachedAssetRemoved, viewType } = useEditorManagement();
    
    const { uniformScaleMode } = useContext(EditorContext);

    const { getCachedAsset, setCachedAsset } = useMediaManagement();
    const { zoomRange, googleMapsApiKey } = useContext(GoogleMapsContext);

    const selectedSite = getSelectedSite();
    const selectedMedia = getSelectedMedia();

    const [ needsUpdate, setNeedsUpdate ] = useState(false);
    const [ mediaMeshesLoaded, setMediaMeshesLoaded ] = useState(false);

    const [ scene, setScene] = useState(null);
    const [ originalColor, setOriginalColor ] = useState(null);
    
    const [ isInitialized, setIsInitialized ] = useState(false);

    const clock = new THREE.Clock();

    //Refs
    const cameraRef = useRef();
    const rendererRef = useRef();
    const controlsRef = useRef();
    const planeRef = useRef();
    const transformControlsRef = useRef();
    const selectableObjectsRef = useRef([]);
    const raycasterRef = useRef(new THREE.Raycaster());
    const mouseRef = useRef(new THREE.Vector2());
    const selectedObjectRef = useRef(null);
    const selectedMediaRef = useRef(selectedMedia);
    const mixersRef = useRef([]);
    const cameraPositionRef = useRef(cameraPosition);
    const cameraRotationRef = useRef(cameraRotation);
    const scaleRef = useRef(selectedMedia?.scale || [1, 1, 1]);
    const uniformScaleModeRef = useRef(uniformScaleMode);

    useEffect(() => {
        scaleRef.current = selectedMedia?.scale || [1, 1, 1];
        uniformScaleModeRef.current = uniformScaleMode;
    }, [uniformScaleMode, selectedMedia?.id]);

    // Utility function for debouncing
    function debounce(func, timeout = 300){
        let timer;
        return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    // Initialization
    useEffect(() => {
        const currentRef = ref.current;

        const newScene = new THREE.Scene();
        setScene(newScene);
    
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        cameraRef.current = camera;
        if (cameraPosition && cameraRotation) {
            cameraRef.current.position.copy(cameraPosition);
            cameraRef.current.rotation.copy(cameraRotation);
        }
        else camera.position.set(0, 10, 20);
    
        const renderer = new THREE.WebGLRenderer();
        renderer.setSize(ref.current.clientWidth, ref.current.clientHeight);
        ref.current.appendChild(renderer.domElement);
        rendererRef.current = renderer;
    
        const controls = new OrbitControls(camera, renderer.domElement);
        controlsRef.current = controls;
        controls.target.set(0, 0, 0);
        controls.minDistance = 3;
        controls.maxDistance = 300;
        controls.update();
    
        const updateCameraStateDebounced = debounce(() => {
            setCameraPosition(camera.position.clone());
            setCameraRotation(camera.rotation.clone());
        });
        const onChange = () => {
            updateCameraStateDebounced();
        };
        
        controls.addEventListener('change', onChange);
        
        const transformControls = new TransformControls(camera, renderer.domElement);
        transformControls.setMode(transformMode);
        transformControls.setSpace(transformSpace);
        transformControlsRef.current = transformControls;
        newScene.add(transformControls);
    
        const grid = createRectangularGrid(selectedSite);
        grid.position.y = 0.1;
        newScene.add(grid);

        const plane = createMapPlane('map', selectedSite, zoomRange);

        newScene.add(plane);
        planeRef.current = plane;
        
        const initializeMedia = () => {
            return new Promise(async (resolve, reject) => {
                try {
                    createsSky(newScene);
                    createLights(newScene);
                    await createMediaMeshes(newScene, selectedSite, selectableObjectsRef.current, { getCachedAsset, setCachedAsset });
                    setMediaMeshesLoaded(true);
                    resolve();
                } catch (error) {
                    console.error(error);
                    reject(error); // Reject the promise in case of an error
                }
            });
        };

        (async () => {
            try {
                await initializeMedia();
                setIsInitialized(true);
                onLoaded();
    
                if (selectedMedia) {
                    const associatedMesh = selectableObjectsRef.current.find(mesh => mesh.userData.guid === selectedMedia.guid);
                    if (associatedMesh && associatedMesh.material) {
                        setOriginalColor(associatedMesh.material.color.clone());
                        associatedMesh.material.color.set(0xff0000);
                        selectedObjectRef.current = associatedMesh;
                        transformControlsRef.current.attach(associatedMesh);
                    }
                }
            } catch (error) {
                console.error("Error initializing media: ", error);
            }
        })();

        // Attach event listeners after initializing transformControls
        transformControls.addEventListener('dragging-changed', (event) => {
            controls.enabled = !event.value;
            if (!event.value) { // Dragging has stopped
                onTransformEnd();
            }
        });
    
        transformControls.addEventListener('objectChange', () => {
            if (selectedObjectRef.current) {
                selectedObjectRef.current.updateMatrix();
            }
        });

        rendererRef.current.domElement.addEventListener('mousedown', onDocumentMouseDown);

        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);

            if (selectedObjectRef.current) {
                selectedObjectRef.current.updateMatrix();
            }

            if (cameraRef.current) {
                cameraPositionRef.current = cameraRef.current.position.clone();
                cameraRotationRef.current = cameraRef.current.rotation.clone();
            }

            if (needsUpdate) {
                setNeedsUpdate(false);
            }

            const delta = clock.getDelta();
            mixersRef.current.forEach(mixer => {
                mixer.update(delta);
            });
            renderer.render(newScene, camera);
        };

        animate();

        return () => {
            if (controlsRef.current) {
                controls.removeEventListener('change', onChange);
            }
            if (transformControlsRef.current) {
                transformControlsRef.current.removeEventListener('objectChange');
                transformControlsRef.current.removeEventListener('dragging-changed');
            }
        
            if (currentRef && currentRef.contains(rendererRef.current.domElement)) {
                currentRef.removeChild(rendererRef.current.domElement);
            }
            setIsInitialized(false);
        };
    }, [selectedSite?.id, viewType ]);
    
    useEffect(() => {
        mixersRef.current = selectableObjectsRef.current
            .map(obj => obj.userData.mixer)
            .filter(mixer => mixer !== undefined);
    }, [selectableObjectsRef.current, isInitialized]);

    useEffect(() => {
        const updateSize = () => {
            const width = ref.current.clientWidth;
            const height = ref.current.clientHeight;
    
            if (cameraRef.current) {
                cameraRef.current.aspect = width / height;
                cameraRef.current.updateProjectionMatrix();
            }
    
            if (rendererRef.current) {
                rendererRef.current.setSize(width, height);
            }
        };
    
        const onResize = () => {
            if (cameraRef.current && rendererRef.current && ref.current) {
                const width = ref.current.clientWidth;
                const height = ref.current.clientHeight;
    
                rendererRef.current.setSize(width, height);
                cameraRef.current.aspect = width / height;
                cameraRef.current.updateProjectionMatrix();
            }
        };
        
        updateSize();
        onResize();
    
        window.addEventListener('resize', onResize);
        window.addEventListener('resize', updateSize);
    
        return () => {
            window.removeEventListener('resize', onResize);
            window.removeEventListener('resize', updateSize);
        };
    }, []);

    const updateCameraState = () => {
        setCameraPosition(cameraPositionRef.current);
        setCameraRotation(cameraRotationRef.current);
    };

    // Switch plane map texture
    useEffect(() => {
        const updateTexture = (newTexture) => {
            if (planeRef.current) {
                planeRef.current.material.map = newTexture;
                planeRef.current.material.needsUpdate = true;
            }
        };
    
        loadMapTexture(viewType, selectedSite, updateTexture, { getCachedAsset, setCachedAsset }, googleMapsApiKey);
    }, [viewType, selectedSite]);
    
    // Add and remove mouse down events
    useEffect(() => {
        const domElement = rendererRef.current.domElement;
        domElement.addEventListener('mousedown', onDocumentMouseDown);
    
        return () => {
            domElement.removeEventListener('mousedown', onDocumentMouseDown);
        };
    }, [selectedObjectRef.current]);

    // Handle selection of media
    useEffect(() => {
        if (selectedMedia) {
            selectedMediaRef.current = selectedMedia;
            const associatedMesh = selectableObjectsRef.current.find(mesh => mesh.userData.guid === selectedMedia.guid);
    
            if (!associatedMesh) {
                console.warn(`Associated mesh not found for the selected media with GUID: ${selectedMedia.guid}`);
                deselectObject(); // Optionally deselect the current object if the associated mesh is not found
                return; // Return early to avoid further actions
            }
    
            selectObject(associatedMesh);
        } else {
            deselectObject();
        }
    }, [selectedMedia, mediaMeshesLoaded]); 

    // Drag End
    useEffect(() => {
        const onDraggingChanged = (event) => {
          if (!event.value) { // If dragging has ended
            onTransformEnd();
          }
        };
      
        if (transformControlsRef.current) {
          transformControlsRef.current.addEventListener('dragging-changed', onDraggingChanged);
        }
      
        return () => {
          if (transformControlsRef.current) {
            transformControlsRef.current.removeEventListener('dragging-changed', onDraggingChanged);
          }
        };
    }, [selectedObjectRef.current, selectedMedia]);

    // Hot keys
    useEffect(() => {
        const handleKeyDown = (event) => {
            switch(event.key) {
                case 'w':
                    updateTransformMode('translate');
                    break;
                case 'e':
                    updateTransformMode('rotate');
                    break;
                case 'r':
                    updateTransformMode('scale');
                    break;
                case 't': // Toggle between local and world space
                    const newSpace = transformSpace === 'local' ? 'world' : 'local';
                    updateTransformModeAndSpace(transformMode, newSpace);
                    break;
                default:
                    break;
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [transformMode, transformSpace, selectedObjectRef.current]);

    // Handle media removal
    useEffect(() => {
        if (mediaRemoved) {
            const objectToRemove = selectableObjectsRef.current.find(obj => obj.userData.guid === mediaRemoved.guid);
            if (objectToRemove) {
                scene.remove(objectToRemove);
                selectableObjectsRef.current = selectableObjectsRef.current.filter(obj => obj !== objectToRemove);
                
                // Detach transform controls if necessary
                if (transformControlsRef.current && selectedObjectRef.current === objectToRemove) {
                    transformControlsRef.current.detach();
                    selectedObjectRef.current = null;
                }
            }
    
            setCachedAssetRemoved(null);
        }
    }, [mediaRemoved, scene, setCachedAssetRemoved]);    

    // Deselect after delete object
    useEffect(() => {
        if (selectedMediaRef.current && !selectedSite.media.some(media => media.guid === selectedMediaRef.current.guid)) {
            // The selected media has been removed
            if (selectedObjectRef.current) {
                // Detach transform controls and reset the selected object
                transformControlsRef.current.detach();
                selectedObjectRef.current = null;
            }
            // Reset selected media
            selectItem({ itemType: 'media', id: null });
            selectedMediaRef.current = null;
        }
    }, [selectedSite.media]);

    // Function to select an object
    const selectObject = (object) => {
        const setColor = (mesh, color) => {
            if (mesh.material) {
                mesh.material.color.set(color);
            }
        };
        
        // Deselect previously selected object
        deselectObject();
    
        // Set original color and select new object
        if (object.isMesh) {
            object.userData.originalColor = object.material.color.clone();
            setColor(object, 0xff0000);
            transformControlsRef.current.attach(object);
            selectedObjectRef.current = object;
        } else if (object.isGroup) {
            object.traverse(child => {
                if (child.isMesh) {
                    child.userData.originalColor = child.material.color.clone();
                    setColor(child, 0xff0000);
                }
            });
            transformControlsRef.current.attach(object);
            selectedObjectRef.current = object;
        }
    
        const media = selectedSite.media.find(m => m.guid === object.userData.guid);
        selectedMediaRef.current = media;
        selectItem({ itemType: 'media', id: media.guid });
    };
    
    
    const deselectObject = () => {
        if (selectedObjectRef.current) {
            if (selectedObjectRef.current.isMesh) {
                if (selectedObjectRef.current.userData.originalColor) {
                    selectedObjectRef.current.material.color.copy(selectedObjectRef.current.userData.originalColor);
                }
            } else if (selectedObjectRef.current.isGroup) {
                selectedObjectRef.current.traverse(child => {
                    if (child.isMesh && child.userData.originalColor) {
                        child.material.color.copy(child.userData.originalColor);
                    }
                });
            }
    
            if (transformControlsRef.current) {
                transformControlsRef.current.detach();
            }
    
            selectedObjectRef.current = null;
        }
    
        selectItem({ itemType: 'media', id: null });
        selectedMediaRef.current = null;
    };
    
    
    const onTransformEnd = async () => {
        if (selectedObjectRef.current) {
            const currentSelectedMedia = selectedMediaRef.current;
    
            if (currentSelectedMedia) {
                // Apply uniform scale if needed
                const finalScale = applyUniformScale(selectedObjectRef.current.scale.toArray());
    
                // Update the transformation of the selected object with potentially new scale
                selectedObjectRef.current.scale.set(...finalScale);
    
                const updatedMedia = {
                    ...currentSelectedMedia,
                    position: selectedObjectRef.current.position.toArray(),
                    rotation: selectedObjectRef.current.rotation.toArray(),
                    scale: finalScale // Use the potentially updated finalScale
                };
    
                const updatedSite = {
                    ...selectedSite,
                    media: selectedSite.media.map(mediaItem => {
                        if (mediaItem.guid === currentSelectedMedia.guid) {
                            return updatedMedia;
                        }
                        return mediaItem;
                    })
                };
    
                updateItem('site', updatedSite);
            }
        }
    };

    const validateRotation = (rotation) => rotation.map(val => isNaN(val) ? 0 : val).slice(0, 3);

    const applyUniformScale = (scale) => {
        let finalScale = scale;
        if (uniformScaleModeRef.current) {
            const scaleChangeIndex = scale.findIndex((scale, i) => scale !== scaleRef.current[i]);
    
            if (scaleChangeIndex !== -1) {
                const scaleFactor = scale[scaleChangeIndex] / scaleRef.current[scaleChangeIndex];
                finalScale = scaleRef.current.map(originalScale => originalScale * scaleFactor);
            }
        }
        scaleRef.current = [...finalScale];
        return finalScale;
    };   

    const updateSelectedObjectTransformation = async (position, rotation, scale) => {
        if (selectedObjectRef.current) {
            const finalScale = applyUniformScale(scale);
    
            selectedObjectRef.current.position.set(...position);
            const validRotation = validateRotation(rotation);
            selectedObjectRef.current.rotation.set(...validRotation);
            selectedObjectRef.current.scale.set(...finalScale);
            selectedObjectRef.current.updateMatrix();
    
            onTransformEnd();
        }
    };

    const updateTransformModeAndSpace = (mode, space = transformSpace) => {
        setTransformMode(mode);
        setTransformSpace(space);
        if (transformControlsRef.current && selectedObjectRef.current) {
            transformControlsRef.current.setMode(mode);
            transformControlsRef.current.setSpace(space);
            transformControlsRef.current.attach(selectedObjectRef.current); // Attach to the current selected object
        }
    };

    const updateTransformMode = (mode) => {
        setTransformMode(mode);
        if (transformControlsRef.current) {
            transformControlsRef.current.setMode(mode);
            if (selectedObjectRef.current) {
                transformControlsRef.current.attach(selectedObjectRef.current);
            }
        }
    };

    const toggleTransformSpace = () => {
        const newSpace = transformSpace === 'local' ? 'world' : 'local';
        setTransformSpace(newSpace);
        if (transformControlsRef.current) {
            transformControlsRef.current.setSpace(newSpace);
        }
    };

    const onDocumentMouseDown = (event) => {
        event.preventDefault();
    
        // Calculate mouse position in normalized device coordinates
        const rect = rendererRef.current.domElement.getBoundingClientRect();
        mouseRef.current.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouseRef.current.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    
        // Update the picking ray with the camera and mouse position
        raycasterRef.current.setFromCamera(mouseRef.current, cameraRef.current);
    
        // Check if the transform controls are currently being dragged
        if (transformControlsRef.current && transformControlsRef.current.dragging) {
            return;
        }
    
        const intersects = raycasterRef.current.intersectObjects(selectableObjectsRef.current, true);
    
        if (intersects.length > 0) {
            // An object is intersected, handle its selection
            let intersectedObject = intersects[0].object;
            while (intersectedObject && !selectableObjectsRef.current.includes(intersectedObject)) {
                intersectedObject = intersectedObject.parent;
            }
    
            if (selectableObjectsRef.current.includes(intersectedObject)) {
                selectObject(intersectedObject);
            }
        } else {
            setTimeout(() => {
                if (controlsRef.current && !controlsRef.current.enabled) {
                    deselectObject();
                }
            }, 10);
        }
    };

    return {
        scene, 
        camera: cameraRef.current, 
        renderer: rendererRef.current, 
        controls: controlsRef.current, 
        transformControls: transformControlsRef.current,
        selectableObjects: selectableObjectsRef.current,
        selectObject,
        deselectObject,
        onTransformEnd,
        updateTransformMode,
        toggleTransformSpace,
        updateCameraState,
        updateSelectedObjectTransformation
    };
};

export default useThreeScene