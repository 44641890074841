import React, { useEffect, useState } from 'react';
import { useMessage } from '../Contexts/SystemMessageContext';
import './SystemNotification.scss'; 

const SystemNotification = ({ message }) => {
  const { clearMessage } = useMessage();
  const [ visible, setVisible ] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        clearMessage();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message, clearMessage]);

  return (
    <div className={`message-container ${visible ? 'fade-in' : 'fade-out'}`}>
      {message}
    </div>
  );
};

export default SystemNotification;
