import React from 'react';

import Auth from '../Controls/Auth';

import './Login.scss';

const Login = () => {
    return (
        <div className='login-page panel'>
            <div className='login-page-title'>Login</div>
            <Auth joining={false} />
        </div>
    );
}

export default Login;