import React from 'react';

import UserTutorial from './UserTutorial';
import './Help.scss';

const Help = () => {

  return (
    <div className='help-page panel'>
        <UserTutorial />
    </div>
  );
}

export default Help;
