import React, { createContext, useState, useEffect } from 'react';
import { observeUserChange, getUserAccount, Logout } from '../Utilities/firestoreUtils';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [ user, setUser ] = useState(undefined);
  const [ authUser, setAuthUser ] = useState(undefined);
  const [ initializing, setInitializing ] = useState(true);
  const [ customerDetails, setCustomerDetails ] = useState(null);
  const [ subscriptionsList, setSubscriptionsList ] = useState(null);
  const [ selectedSubscriptionDetails, setSelectedSubscriptionDetails ] = useState(null);
  const [ showTutorial, setShowTutorial ] = useState(false);

  useEffect(() => {
    const unsubscribe = observeUserChange(async (firebaseUser) => {
      if (firebaseUser) {
        const accountExists = await getUserAccount(firebaseUser.uid);
        if (accountExists) {
          setUser(accountExists);
          setSubscriptionsList(accountExists.subscriptions);
        } else {
          setUser(null);
        }
      } else {
        setUser(null);
      }
      setInitializing(false);
    }
  );
  
    return () => unsubscribe();
  }, []);
  
  const userLogout = () => {
    Logout();
  }

  return (
    <UserContext.Provider value = {{ 
        initializing, userLogout, 
        user, setUser, authUser, setAuthUser, 
        customerDetails, setCustomerDetails, 
        subscriptionsList, setSubscriptionsList,
        selectedSubscriptionDetails, setSelectedSubscriptionDetails,
        showTutorial, setShowTutorial
      }}>
      {children}
    </UserContext.Provider>
  );
};
