// PrivacyPolicy.js
import React from 'react';

const CookiePolicy = () => {
  return (
      <div className='panel'>
    <div className='legal-page'>
        <h1>Cookie Policy for Cylandar</h1>
        
        <h2>Introduction</h2>
        <p>
          Cylandar provides a location-based augmented reality platform as a service, 
          offering users the ability to create, manage, and participate in Events and Activities. 
          In delivering our services, we prioritize user privacy and data protection.
        </p>

        <h2>Use of Cookies</h2>
        <p>
          Cylandar does not use cookies or similar tracking technologies on its website or mobile application. 
          This means that when you use our platform, we do not collect or store information that would allow us 
          to track your browsing behavior over time and across third-party websites.
        </p>

        <h2>What are Cookies?</h2>
        <p>
          Cookies are small text files that websites place on your device while you are browsing. 
          They are used for various purposes, such as remembering your preferences, tracking user activity, 
          and facilitating online advertising.
        </p>

        <h2>Our Stance on Privacy</h2>
        <p>
          We believe in providing a transparent and secure environment for our users. Given the nature of our 
          services and our commitment to privacy, we have chosen not to employ cookies or any tracking mechanisms 
          that could compromise user privacy.
        </p>

        <h2>Changes to the Cookie Policy</h2>
        <p>
          We may update this cookie policy to reflect changes to our practices or for other operational, legal, 
          or regulatory reasons. Users will be informed of any significant changes through our website.
        </p>

        <h2>Contact Us</h2>
        <p>
          For further information about our cookie policy or any privacy-related questions, please contact us at:
        </p>
        <ul>
          <li>Email: [Insert Cylandar’s email address]</li>
          <li>Phone: [Insert Cylandar’s phone number]</li>
          <li>Address: [Insert Cylandar’s physical address]</li>
        </ul>
      </div>
    </div>
  );
};

export default CookiePolicy;
