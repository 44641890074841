import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useSalesManagement from '../Hooks/UseSalesManagement';

import DateRangeEditor from '../Controls/DateRangeEditor';
import LocationEditor from '../Controls/LocationEditor';
import NameDescription from '../Controls/NameDescription';
import Icon from '../Controls/Icon';

import { ReactComponent as AddIcon } from '../Assets/add-icon.svg';
import { ReactComponent as DeleteIcon } from '../Assets/delete-icon.svg';

import './SiteDetails.scss'

const SiteDetails = () => {
  const navigate = useNavigate();
  const { getSelectedEvent, getSelectedSite, selectItem, updateItem, removeItem } = useEditorManagement();
  const { getSelectedSubscription } = useSalesManagement();  
  const [ siteDetails, setSiteDetails] = useState(getSelectedSite());
  const [ selectedEventSites, setSelectedEventSites] = useState(getSelectedEvent().sites);
  const [ subscription ] = useState(getSelectedSubscription);

  useEffect(() => {
    const site = getSelectedSite();
    setSiteDetails(site);
    setSelectedEventSites(getSelectedEvent().sites);
  }, [getSelectedSite]);

  useEffect(() => {
    const fetchSite = async () => {
      let site = siteDetails.id
      if(!site) {
        site = selectedEventSites[0].id
      }
      const details = await getSelectedSite();
      if (details && JSON.stringify(details) !== JSON.stringify(siteDetails)) {
        selectItem({ itemType: 'site', id: details.id });
      }
    };

    fetchSite();
  }, [siteDetails?.id]);
  
  const handleSiteChange = async (siteId) => {
    const selectedSite = selectedEventSites.find(site => site.id === siteId);
    selectItem({ itemType: 'site', id: selectedSite.id });
  }

  const handleToggleActive = async () => {
    const newActiveStatus = !siteDetails.active;
    updateItem('site', { active: newActiveStatus });
  };

  const handleAddNewSite = async () => {
    if (selectedEventSites.length > subscription.siteCount) {
      alert('You have reached the maximum number of sites allowed for your subscription.');
      return;
    } else {
      navigate('/create', { state: { type: 'site' }});
    }
  };

  const handleRemoveSite = async () => {
    const selectedEvent = getSelectedEvent();
    let isSiteUsedInActivities = false;
  
    selectedEvent.activities.forEach(activity => {
      activity.steps.forEach(step => {
        if(step.siteId === siteDetails.id) {
          isSiteUsedInActivities = true;
        }
      });
    });
  
    if(isSiteUsedInActivities) {
      alert('This site is being used in one or more activities and cannot be deleted.');
      return;
    }

    if (selectedEventSites.length <= 1) {
      alert('An event needs at least one site. This site cannot be deleted.');
    } else {
      const confirmation = window.confirm('Are you sure you want to delete this site?');
      if (confirmation) {
        await updateItem('site', { deleted: true })
        const remainingSites = selectedEventSites.filter(site => site.id !== siteDetails.id);
        const newSelectedSiteId = remainingSites.length > 0 ? remainingSites[0].id : null;
        if (newSelectedSiteId) {
          setSiteDetails(selectedEventSites.find(site => site.id === newSelectedSiteId));
          selectItem({ itemType: 'site', id: newSelectedSiteId });
        }
        await removeItem('site', siteDetails.id);
      }
    }
  };

  const promoSiteOption = getSelectedEvent().promo ? [{
    value: getSelectedEvent().promo.id,
    label: getSelectedEvent().promo.name
  }] : [];

  const siteOptions = promoSiteOption.concat(selectedEventSites.map(site => ({
      value: site.id,
      label: site.name
    })));

  const sitesUsed = selectedEventSites.length-1 +' / '+subscription.siteCount;
  
  return (
    <div className='site-details panel'>
      {siteDetails && selectedEventSites.length > 0 ? (
      <>
        <div className='site-details-sites-list'>
          <div>{sitesUsed}</div>
          <Select
            classNamePrefix="custom-select"
            value={siteOptions.find(option => option.value === siteDetails?.id) || ''}
            onChange={(selectedOption) => handleSiteChange(selectedOption.value)}
            options={siteOptions}
            menuPortalTarget={document.body}
          />
          <button onClick={handleAddNewSite}>
            <AddIcon width="1rem" height="1rem" />
          </button>
          <button onClick={handleRemoveSite} disabled={selectedEventSites.length <= 2  || siteDetails.promo === true }>
            <DeleteIcon width="1rem" height="1rem" />
          </button>
        </div>

        <div className='site-details-menu'>
          <div className='site-details-left'>
            <div className='site-details-site-icon-container'>
              <Icon type='site' item={ siteDetails } />
            </div>
            <div className='site-details-site-deactivate-container'>
              { (selectedEventSites.length > 1 && !siteDetails.promo) && <button onClick={handleToggleActive}>{siteDetails.active ? 'Deactivate' : 'Activate'}</button> }
            </div>
          </div>

          { siteDetails && <div className='selected-site-inputs'>
            <NameDescription collection={'site'} data={siteDetails} />
            <DateRangeEditor collection={'site'} />
            <LocationEditor collection={'site'} />
          </div>}
        </div>
      </>
      ) : (
      <p>No sites found for the selected event.</p>
      )}
    </div>
  );
};

export default SiteDetails;