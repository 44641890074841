import { createContext, useState } from 'react';

export const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
    const [ cache, setCache ] = useState({});
    const [ mediaLibraryFiles, setMediaLibraryFiles ] = useState([]);
    const [ selectedMediaType, setSelectedMediaType ] = useState('all');
    const [ selectedMediaFile, setSelectedMediaFile ] = useState(null);

    const contextValue = {
        cache, setCache, mediaLibraryFiles, setMediaLibraryFiles, selectedMediaType, setSelectedMediaType, selectedMediaFile, setSelectedMediaFile
    };

    return (
        <MediaContext.Provider value={contextValue}>
            {children}
        </MediaContext.Provider>
    );
};
