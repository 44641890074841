import React, { useContext } from 'react';
import ReactDOM from 'react-dom'

import { ModalContext } from '../Contexts/ModalContext';

import DateRangeEditorUI from './ModalUI/DateRangeEditorUI';
import LocationEditorUI from './ModalUI/LocationEditorUI';
import NameDescriptionUI from './ModalUI/NameDescriptionUI';
import RenameUI from './ModalUI/RenameUI';

import './Modal.scss';

const Modal = () => {
    const { modalContent, isVisible, position, closeModal } = useContext(ModalContext);
    const rootElement = document.getElementById('root');

    if (!isVisible) return null;

    const modalStyle = {
        position: 'absolute',
        top: position.top + 'px',
        left: position.left + 'px',
        zIndex: 1000,
    };
    
    return ReactDOM.createPortal(
        <div className='modal-backdrop' onClick={closeModal}>
            <div className='modal-content' style={modalStyle} onClick={e => e.stopPropagation()}>
                <ModalUI modalType={modalContent.modalType} collection={modalContent.collection} data={modalContent.data} handleAction={modalContent.handleAction} onClose={closeModal} />
                <div className='modal-close' onClick={closeModal}>x</div>
            </div>
        </div>,
        rootElement
    );
};

export default Modal;

const ModalUI = ({ modalType, collection, data, handleAction }) => {
    let modal = null;
    switch (modalType) {
        case 'namedescription':
            modal = <NameDescriptionUI collection={collection} data={data}/>;
            break;
        case 'date':
            modal = <DateRangeEditorUI collection={collection} data={data}/>;
            break;
        case 'location':
            modal = <LocationEditorUI />;
            break;
        case 'rename':
            modal = <RenameUI data={data} handleAction={handleAction} />;
            break;           
        default:
    }
    return (
        <div className='modal-UI'>
            {modal}
        </div>
    );
}
