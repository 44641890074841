import { useContext, useCallback } from 'react';

import { fetchUserMedia, deleteMediaFromFirebase, copyMediaFileInFirebase, uploadFileToUserMedia, fileExists } from '../Utilities/firestoreUtils';

import useEditorManagement from './UseEditorManagement';

import { MediaContext } from '../Contexts/MediaContext';
import { EditorContext } from '../Contexts/EditorContext';
import { useMessage } from '../Contexts/SystemMessageContext';
import { UserContext } from '../Contexts/UserContext';
import { ModalContext } from '../Contexts/ModalContext';

function useMediaManagement() {
    const { getSelectedEvent, getSelectedSite, updateItem } = useEditorManagement();
    const selectedEvent = getSelectedEvent();
    const selectedSite = getSelectedSite();
    const { user } = useContext(UserContext);
    const { cache, setCache, mediaLibraryFiles, setMediaLibraryFiles, selectedMediaFile, setSelectedMediaFile } = useContext(MediaContext);
    const { selectedEventId } = useContext(EditorContext);
    const { showMessage } = useMessage();
    const { closeModal } = useContext(ModalContext);

    const fileTypes = {
        'image/jpeg': 3 * 1024 * 1024, 
        'image/png': 3 * 1024 * 1024,
        'audio/mpeg': 10 * 1024 * 1024,
        'video/mp4': 20 * 1024 * 1024,
        'mesh/obj': 5 * 1024 * 1024, // Example size limit for OBJ
        'mesh/fbx': 10 * 1024 * 1024, // Example size limit for FBX
    };

    const getCachedAsset = (key) => {
        return cache[key];
    };

    const setCachedAsset = (key, texture) => {
        setCache((prevCache) => ({ ...prevCache, [key]: texture }));
    };

    const clearCache = () => {
        setCache({});
    };

    const deleteCachedAsset = (key) => {
        setCache((prevCache) => {
            const newCache = { ...prevCache };
            delete newCache[key];
            return newCache;
        });
    };

    const getMediaLibrary = async () => {
        const fetchedMedia = await fetchUserMedia(selectedEventId);
        setMediaLibraryFiles(fetchedMedia);
    };

    const getEventMediaCount = async (eventId) => {
        const fetchedMedia = await fetchUserMedia(eventId);
        return fetchedMedia.length;
    };

    const getMediaLibraryFile = (index) => {
        return mediaLibraryFiles[index];
    };

    const addMediaLibraryFile = (file) => {
        if (!file) return;
        setMediaLibraryFiles((prevFiles) => [...prevFiles, file]);
        showMessage(`File "${file.name}" added to media library`)
    };

    const removeMediaLibraryFile = (index) => {
        setMediaLibraryFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const deleteMedia = useCallback(async (media) => {
        try {
            const isDuplicateUrl = mediaLibraryFiles.filter((file) => file.url === media.url).length > 1;
            await deleteMediaFromFirebase(media, isDuplicateUrl);
            const updatedMediaLibraryFiles = mediaLibraryFiles.filter((file) => file.id !== media.id);
            setMediaLibraryFiles(updatedMediaLibraryFiles);
            showMessage(`Media "${media.name}" deleted`);
            
            if (!isDuplicateUrl) {
                deleteCachedAsset(media.url);
            }
        } catch (error) {
            console.error("Error in delete media:", error);
        }
    }, [showMessage]);

    const addMediaToSite = async (media) => {
        const mediaToAdd = {
            guid: `${media.id}_${new Date().getTime()}`,
            startDate: selectedSite.startDate,
            endDate: selectedSite.endDate,
            id: media.id,
            name: media.name,
            title: media.name,
            description: 'Add Description',
            fileType: media.fileType,
            position: [0,0,0],
            rotation: [0,0,0],
            scale: [1,1,1],
        };
    
        const updatedSite = {
            ...selectedSite,
            media: [...selectedSite.media, mediaToAdd]
        };

        updateItem('site', updatedSite);
    };
    
    const copyMedia = async (media) => {
        if (!media) return;
        try {
            const copiedMedia = await copyMediaFileInFirebase(media);
            setMediaLibraryFiles(prevMediaFiles => [...prevMediaFiles, copiedMedia]);
            showMessage(`File "${media.name}" copied successfully`);
        } catch (error) {
            console.error('Error copying media:', error);
        }
    };
    
    const renameMedia = (newName) => {
        if (!selectedMediaFile || !newName) return;
        updateItem('mediaFile', { ...selectedMediaFile, name: newName });
        
        const updatedMediaFile = { ...selectedMediaFile, name: newName };
        const fileIndex = mediaLibraryFiles.findIndex(file => file.id === selectedMediaFile.id);
    
        if (fileIndex !== -1) {
            const updatedMediaLibraryFiles = [...mediaLibraryFiles];
            updatedMediaLibraryFiles[fileIndex] = updatedMediaFile;
            setMediaLibraryFiles(updatedMediaLibraryFiles);
        }
    
        setSelectedMediaFile(updatedMediaFile);
        
        closeModal();
    };

    const isValidFileType = (mediaItem) => {
        if (fileTypes.hasOwnProperty(mediaItem.type)) {
            return true;
        }
    };

    const isValidFileSize = (mediaItem) => {
        const sizeLimit = fileTypes[mediaItem.type] || 0;    
        return mediaItem.size <= sizeLimit;
    };

    const uploadFiles = (files, setUploadStatus) => {
        Array.from(files).forEach(async file => {
            if(file.type === '') {
                file = modifyFileMIMEType(file);
            }
    
            if (!isValidFileType(file)) {
                setUploadStatus(prevStatus => [
                    ...prevStatus, 
                    { name: file.name, status: 'failed', reason: 'Unsupported file type' }
                ]);
            } else if (!isValidFileSize(file)) {
                setUploadStatus(prevStatus => [
                    ...prevStatus, 
                    { name: file.name, status: 'failed', reason: 'File size exceeds limit' }
                ]);
            } else {
                const exists = await fileExists(user.uid, selectedEvent.id, file.name);
                if (exists) {
                    const overwrite = window.confirm(`File "${file.name}" already exists. Do you want to overwrite it?`);
                    if (overwrite) {
                        fileOverwrite(file, setUploadStatus);
                    }
                } else {
                    uploadFile(file, true, setUploadStatus);
                }
            }
        });
    };

    const uploadFile = async(file, isNewFile, setUploadStatus) => {
        setUploadStatus(prevStatus => [...prevStatus, { name: file.name, status: 'uploading' }]);
    
        const message = isNewFile ? `Uploading file "${file.name}"` : `Overwriting file "${file.name}"`;
        const media = await uploadFileToUserMedia(user.uid, selectedEvent.id, file, isNewFile, () => {
            setUploadStatus(prevStatus => prevStatus.map(
                f => f.name === file.name ? { ...f, status: 'successful' } : f
            ));

        }, (error) => {
            setUploadStatus(prevStatus => prevStatus.map(
                f => f.name === file.name ? { ...f, status: 'failed', reason: error.message } : f
            ));
        }, message);

        if (media) {
            setMediaLibraryFiles(prevMediaFiles => [...prevMediaFiles, media]);
            showMessage(`File "${file.name}" uploaded successfully`);
        }
    };

    const fileOverwrite = (file, setUploadStatus) => {
        setUploadStatus(prevStatus => prevStatus.filter(f => f.name !== file.name));
        uploadFile(file, false, setUploadStatus);
    };

    const modifyFileMIMEType = (file) => {
        const fileNameLower = file.name.toLowerCase();
    
        if (fileNameLower.endsWith('.obj')) {
            return new File([file], file.name, { type: 'mesh/obj' });
        } else if (fileNameLower.endsWith('.fbx')) {
            return new File([file], file.name, { type: 'mesh/fbx' });
        }
    
        return file;
    };
    
    return  { getCachedAsset, setCachedAsset, clearCache, deleteCachedAsset, uploadFiles, getMediaLibrary, getMediaLibraryFile, getEventMediaCount, addMediaLibraryFile, removeMediaLibraryFile, deleteMedia, copyMedia, renameMedia, addMediaToSite };
}

export default useMediaManagement