export const createSubscriptionUrl = 'https://us-central1-playformia-d3d22.cloudfunctions.net/createSubscription';

export const getStripeCustomerUrl = 'https://us-central1-playformia-d3d22.cloudfunctions.net/getStripeCustomer';

export const startFreeTrialUrl = 'https://us-central1-playformia-d3d22.cloudfunctions.net/startFreeTrial';

export const createStripeCustomerUrl = 'https://us-central1-playformia-d3d22.cloudfunctions.net/createStripeCustomer';

export const cancelSubscriptionUrl = 'https://us-central1-playformia-d3d22.cloudfunctions.net/cancelSubscription';

export const reactivateSubscriptionUrl = 'https://us-central1-playformia-d3d22.cloudfunctions.net/reactivateSubscription';

