import React, { useEffect, useState, useContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchDocumentData } from '../Utilities/firestoreUtils';
import { loadImageTexture } from '../Utilities/ThreeDMapUtils';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useMediaManagement from '../Hooks/UseMediaManagement';

import MediaPicker from '../Controls/MediaPicker';

import { MediaContext } from '../Contexts/MediaContext';

import { ReactComponent as ImageIcon } from '../Assets/image-icon.svg';

import './Icon.scss'

import _ from 'lodash';

const Icon = ({type, item, isEditable=true}) => {
    const { updateItem, getSelectedEvent } = useEditorManagement();
    const { getCachedAsset, setCachedAsset } = useMediaManagement();
    const { mediaLibraryFiles } = useContext(MediaContext);

    const [ texture, setTexture ] = useState(null);
    const [ showMediaPicker, setShowMediaPicker ] = useState(false);

    useEffect(() => {
        if (item.icon ==='') return;
        const LoadIcon = async () => {
            try {
                const mediaItem = await fetchDocumentData ('media', item.icon);
                const loadedTexture = await loadImageTexture(mediaItem, { getCachedAsset, setCachedAsset });

                setTexture(loadedTexture);
            } catch (error) {
                setTexture(null);
            }
        };
    
        LoadIcon();
    }, [getSelectedEvent(), mediaLibraryFiles, getCachedAsset, setCachedAsset]);
    
    const handleIconSelect = async (icon) => {
        updateItem(type, { icon: icon.id });
        setShowMediaPicker(false);
    }; 

    const handleMediaPickerOpen = () => {
        setShowMediaPicker(true);
    };

    const hadleEditIcon = () => {
        if (isEditable) {
            handleMediaPickerOpen();
        }
    }

    return (
        <div className='icon-container'>
            {texture && texture.image ? (
                <img onClick={hadleEditIcon} src={texture.image.src} alt={'Missing Icon'} key={texture?.image?.src}/>
            ) : (
                <ImageIcon onClick={hadleEditIcon}/>
            )}
            {/* { isEditable &&
                <button onClick={() => {handleMediaPickerOpen()}}>Edit</button>
            } */}
            {showMediaPicker && (
                <MediaPicker
                    mediaType={'image'}
                    onSelect={handleIconSelect}
                    onClose={() => setShowMediaPicker(false)}
                />
            )}
        </div>
    );
}

export default Icon;
