import React, { useContext, useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import _ from 'lodash';
import Select from 'react-select';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useSalesManagement from '../Hooks/UseSalesManagement';

import { EditorContext } from '../Contexts/EditorContext';
import { UserContext } from '../Contexts/UserContext';

import NameDescription from '../Controls/NameDescription';
import Icon from '../Controls/Icon'

import './EventDetails.scss'

const EventDetails = () => {
    const { userEvents, getSelectedEvent, selectItem, updateItem, removeItem, getPublicTags } = useEditorManagement();
    const { setUserEvents, setSelectedSite, setActiveComponent } = useContext(EditorContext);

    const currentEvent = getSelectedEvent();

    const [ selectedSiteStats, setSelectedSiteStats ] = useState(null);
    const [ rightPanelContent, setRightPanelContent ] = useState('eventStats');

    const [ publicTags, setPublicTags ] = useState([]);

    const currentTags = currentEvent?.tags || [];

    useEffect(() => {
        const fetchAndLogPublicTag = async () => {
          try {
            const publicTags = await getPublicTags();
            setPublicTags(publicTags.tags);
          } catch (error) {
            console.error('Failed to fetch public tag:', error);
          }
        };
    
        fetchAndLogPublicTag();
    }, []);

    const handleDeleteEvent = async () => {
        const confirmDelete = window.confirm(`Are you sure you want to delete the event "${currentEvent.name}"?`);
        if (confirmDelete) {
            const eventId = currentEvent.id;
            await removeItem('event', eventId);

            const updatedUserEvents = userEvents.filter((event) => event.id !== eventId);
            setUserEvents(updatedUserEvents);
            setActiveComponent('userAccount');
            if (currentEvent === eventId) {
                selectItem({ itemType: 'event', id: updatedUserEvents.length > 0 ? updatedUserEvents[0].id : null });
                selectItem({ itemType: 'event', id: updatedUserEvents[0].id })
                const eventSites = updatedUserEvents[0].sites || [];
                setSelectedSite(eventSites.length > 0 ? eventSites[0].id : null);
            }
        }
    };
    
    const handleToggleActive = async () => {
        const newActiveStatus = !currentEvent.active;
        updateItem('event', { active: newActiveStatus });
    };

    const handleEventStatsClick = () => {
        setRightPanelContent('eventStats');
    };

    const handleSiteClick = (site) => {
        setSelectedSiteStats(site);
        setRightPanelContent('siteStats');
    };

    const handleAddTag = (tag) => {
        const currentTags = currentEvent.tags ? [...currentEvent.tags] : [];
        
        if (!currentTags.includes(tag.value)) {
          const updatedTags = [...currentTags, tag.value];
          updateItem('event', { tags: updatedTags });
        } else {
          console.warn('Tag already exists:', tag);
        }
    };

    const handleRemoveTag = (tagValue) => {
        const currentTags = currentEvent.tags ? [...currentEvent.tags] : [];
        const updatedTags = currentTags.filter(tag => tag !== tagValue);
        
        updateItem('event', { tags: updatedTags });
    };
    
    const tagSelectOptions = publicTags
      .filter(tag => !currentTags.includes(tag))
      .map(tag => ({
        value: tag,
        label: tag
      }));
   
    return (
        <div className='event-details panel'>
            <div className='event-details-left'>
                <div className='event-details-icon-details'>
                    <div className='event-details-icon'>
                        <Icon type='event' item={ currentEvent } />
                    </div>
                    <div className='event-details-menu'>
                        <div className='event-details-buttons'> 
                            <button onClick={() => handleDeleteEvent(currentEvent.id)}> Delete </button>
                            <button onClick={handleToggleActive}>{currentEvent.active ? 'Active' : 'inactive'}</button>
                        </div>
                        <div className='event-details-name-description'>
                            <NameDescription collection={'event'} data={currentEvent} />
                        </div>
                    </div>
                </div>
                <div className='event-details-tags'>
                    { tagSelectOptions.length >0 && <Select
                        classNamePrefix='custom-select'
                        value={tagSelectOptions[0] }
                        onChange={handleAddTag}
                        options={tagSelectOptions}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                    />}
                    <div className='event-details-tags-assigned'>
                        Currently Assigned Tags
                        <div className='event-details-tags-assigned-list'>
                            { currentEvent.tags?.length > 0 && currentEvent.tags?.map((tag, index) => {
                                return (
                                    <div key={index} className='event-details-tag'>
                                        {tag} <button onClick={() => handleRemoveTag(tag)}>X</button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className='event-details-sites-list'>
                    <div>Select a catagory to view</div>
                    <div className='event-details-sites-list-item' onClick={handleEventStatsClick}>
                        Event Stats
                    </div>
                    {currentEvent.sites.map((site, index) => (
                        <div className='event-details-sites-list-item' key={index} onClick={() => handleSiteClick(site)}>
                            Site {site.name} Stats
                        </div>
                    ))}
                </div>
            </div>
            <div className='event-details-right'> 
                {rightPanelContent === 'siteStats' && <SiteStats site={selectedSiteStats} />}
                {rightPanelContent === 'eventStats' && <EventStats />}
            </div>
        </div>
    );
};

export default EventDetails;

const SiteStats = ({ site }) => {
    if (!site) return <div>No site selected.</div>;

    return (
        <div>
            <h4>Site Details</h4>
            <p><strong>Name:</strong> {site.name}</p>
            <p><strong>Description:</strong> {site.description || 'No description provided.'}</p>
        </div>
    );
};

const EventStats = () => {
    const { getSelectedEvent, getEventStats } = useEditorManagement();
    const { getSelectedSubscription, subscriptionDaysRemaining, cancelStripeSubscription, reactivateStripeSubscription } = useSalesManagement();
    const { user, setUser } = useContext(UserContext);

    const [ event ] = useState(getSelectedEvent());
    const [ subscriptionDetails, setSubscriptionDetails ] = useState({});

    const [ setUserInteractions ] = useState([]);

    useEffect(() => {
        const fetchEventStats = async () => {
          try {
            const stats = await getEventStats(event.eventstats);
            setUserInteractions(stats.userInteractions || []);
          } catch (error) {
            console.error('Failed to fetch Event Stats:', error);
          }
        };
    
        // fetchEventStats();
    }, []);

    useEffect(() => {
        const fetchSelectedSubscriptionDetails = async () => {
            const selectedSubscription = getSelectedSubscription();
            setSubscriptionDetails(selectedSubscription);
        };
    
        fetchSelectedSubscriptionDetails();
    }, []);

    if (!event) return <div>No site selected.</div>;

    return (
        <div>
            <h4>Event Stats</h4>
            <div> Site Count: {subscriptionDetails.siteCount} </div>
            <div> Media Count: {subscriptionDetails.mediaCount} </div>
            <div> Activity Count: {subscriptionDetails.activityCount} </div>
            <div className='event-details-time-details'>
                <div>
                    {subscriptionDetails.daysRemaining || 0} of {subscriptionDetails.totalDays || 0} days remaining
                </div>
                <div>
                    {subscriptionDetails.daysRemaining > 0 ? 'Ends on: ' : 'Ended on: '}
                    {subscriptionDetails.endDate || 'N/A'}
                    <div> { (subscriptionDetails.trial ? 'Trial ' : '') + (subscriptionDetails.recurring ? 'Renews in' : 'Ends in') +' '+ (subscriptionDaysRemaining(subscriptionDetails) + ' days')}</div>
                </div>
                {!subscriptionDetails.recurring ? 
                    <button onClick={()=>reactivateStripeSubscription(user.uid, subscriptionDetails.subscriptionId, setUser)}> Resubscribe</button> : 
                    <button onClick={()=>cancelStripeSubscription(user.uid, subscriptionDetails.subscriptionId, setUser)}> Unsubscribe</button>
                }
            </div>
        </div>
    );
};