import React, { useEffect, useState, useCallback, useContext } from 'react';
import ReactDOM from 'react-dom'

import { ThreeDModelViewer } from '../Utilities/ThreeDModelViewer';
import { loadImageTexture, loadVideoTexture } from '../Utilities/ThreeDMapUtils';

import useMediaManagement from '../Hooks/UseMediaManagement';

import { MediaContext } from '../Contexts/MediaContext';

import './MediaPicker.scss';

const MediaPicker = ({ mediaType, onSelect, onClose }) => {
    const { mediaLibraryFiles, selectedMediaFile } = useContext(MediaContext);
    let filteredMediaFiles = mediaLibraryFiles.filter(item => item.fileType.includes(mediaType));
    if (mediaType === 'all') {
        filteredMediaFiles = mediaLibraryFiles;
    }

    const modalStyle = {
        position: 'absolute',
        top: 0+ 'px',
        left: 0 + 'px',
        zIndex: 1000,
    };

    return ReactDOM.createPortal(
        <div className="media-picker-backdrop" >
            <div className="media-picker-content" style={modalStyle} onClick={e => e.stopPropagation()}>
                <div className='media-picker panel'>
                    <h3>Select a Media</h3>
                    <div className='media-picker-grid'>
                        {filteredMediaFiles.length > 0 ?
                            filteredMediaFiles.map((media, index) => ( 
                                <MediaPickerThumbnail key={media.id+index} mediaItem={media} onSelect={onSelect} selectedImage={selectedMediaFile} />
                            )) :
                            <div>No media in library</div>
                        }
                    </div>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>,
        document.body
    );
};

export default MediaPicker;

export const MediaPickerThumbnail = ({ mediaItem, onSelect }) => {
    return (
        <div className="media-picker-thumbnail" onClick={() => onSelect(mediaItem)} >
            <MediaThumbnail mediaItem={mediaItem} label={true} shouldLoad={true} />
        </div>
    );
};

export const MediaThumbnail = ({ mediaItem, label, shouldLoad }) => {
    const { getCachedAsset, setCachedAsset } = useMediaManagement();
    const [ mediaeUrl, setCachedAssetUrl ] = useState(null);

    const loadAsset = useCallback(async () => {
        if (mediaItem.fileType.includes('image')) {
            try {
                const texture = await loadImageTexture(mediaItem, { getCachedAsset, setCachedAsset });
                setCachedAssetUrl(texture.image.src);
            } catch (error) {
                console.error('Error loading texture:', error);
            }
        } else if (mediaItem.fileType.includes('video')) {
            try {
                const texture = await loadVideoTexture(mediaItem, { getCachedAsset, setCachedAsset });
                setCachedAssetUrl(texture.image.src);
            } catch (error) {
                console.error('Error loading video:', error);
            }
        }
    }, [mediaItem, getCachedAsset, setCachedAsset, loadImageTexture, loadVideoTexture]);

    useEffect(() => {
        if (shouldLoad) {
            loadAsset();
        }
    }, [shouldLoad, loadAsset]);

    if (mediaItem.length === 0) return null;
    if ((mediaItem.fileType.includes('image') || mediaItem.fileType.includes('video') || mediaItem.fileType.includes('mesh')) && !mediaeUrl) {
        loadAsset();
    }

    const fileType = mediaItem.fileType;
    
    let thumbnail = null;
    if (fileType.includes('image')) {
        thumbnail = mediaeUrl ? <img key={mediaItem.name} src={mediaeUrl} alt={mediaItem.name} /> : <div>Loading...</div>;
    } else if (fileType.includes('audio')) {
        thumbnail = <audio controls preload="auto" key={mediaItem.name} src={mediaeUrl} />;
    } else if (fileType.includes('video')) {
        thumbnail = <video controls preload="auto" key={mediaItem.name} loop src={mediaeUrl}/>;
    } else if (fileType.includes('mesh')) {
        thumbnail = <ThreeDModelViewer key={mediaItem.name} mediaItem={mediaItem} />;
    } else {
        thumbnail = <div>Unsupported File Type</div>;
    }

    return (
        <div className="media-thumbnail">
            <div className='media-thumbnail-view'>
                {thumbnail}
            </div>
            {label && <div className='media-thumbnail-label'>{mediaItem.name}</div>}
        </div>
    );
};
