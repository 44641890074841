import React, { createContext, useState } from 'react';

export const EditorContext = createContext(null);

export const EditorProvider = ({ children }) => {
  const [ userEvents, setUserEvents ] = useState([]);
  const [ activeComponent, setActiveComponent ] = useState('list');

  // Event
  const [ selectedEventId, setSelectedEventId ] = useState(null);

  // Site
  const [ selectedSiteId, setSelectedSiteId ] = useState(null);
  
  // Media
  const [ selectedMediaId, setSelectedMediaId ] = useState(null);
  const [ mediaRemoved, setCachedAssetRemoved ] = useState(null);

  // Editor Viewport
  const [ viewportType, setViewportType ] = useState(null);
  const [ cameraPosition, setCameraPosition ] = useState([10,10,10]);
  const [ cameraRotation, setCameraRotation ] = useState(null);
  const [ transformMode, setTransformMode ] = useState('translate');
  const [ transformSpace, setTransformSpace ] = useState('world');
  const [ uniformScaleMode, setUniformScaleMode ] = useState(true);
  const [ viewType, setViewType ] = useState('world');
  const [ mapLoadingStatus, setMapLoadingStatus ] = useState('loading');
  const [ cartesianEditor, setCartesianEditor ] = useState(false);

  const [ addEventWizzard, setAddEventWizzard ] = useState(false);

  const contextValue = {
    userEvents, setUserEvents,
    activeComponent, setActiveComponent,
    selectedEventId, setSelectedEventId,
    selectedSiteId, setSelectedSiteId,
    selectedMediaId, setSelectedMediaId,
    mediaRemoved, setCachedAssetRemoved,
    cameraPosition, setCameraPosition,
    cameraRotation, setCameraRotation,
    transformMode, setTransformMode,
    transformSpace, setTransformSpace,
    uniformScaleMode, setUniformScaleMode,
    viewportType, setViewportType,
    mapLoadingStatus, setMapLoadingStatus,
    viewType, setViewType,
    cartesianEditor, setCartesianEditor,
    addEventWizzard, setAddEventWizzard,
  };

  return (
    <EditorContext.Provider value={contextValue}>
      {children}
    </EditorContext.Provider>
  );
};