import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Auth from '../Controls/Auth';

import image_01 from '../Assets/onboard_01.webp';
import image_02 from '../Assets/onboard_02.webp';
import image_03 from '../Assets/onboard_05.webp';

import './Join.scss';

const Join = () => {
  const navigate = useNavigate();

  const [ currentStep, setCurrentStep ] = useState(0);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handlecancel = () => {
    navigate('/');
  }
  
  const tutorialSteps = [
    StepOne,
    StepTwo,
    StepThree,
    Auth,
  ];

  const CurrentStepComponent = tutorialSteps[currentStep];

  return (
    <div className='join-page panel'>
      <div className='join-page-title'>Join Cylandar</div>
      <CurrentStepComponent joining={currentStep === 3} />
      <div className='join-page-footer'>
        <button onClick={handlecancel}>Cancel</button>
        {currentStep > 0 && <button onClick={handlePrev}>Back</button>}
        {currentStep < tutorialSteps.length - 1 && <button onClick={handleNext}>Next</button>}
      </div>
    </div>
  );
}

export default Join;

const StepOne = () => (
  <div className='join-page-body'>
    <div className='join-page-body-text'>
      <div>Welcome to Cylandar</div>
      <p>Welcome to Cylandar, a platform where the physical and digital worlds merge through augmented reality. Here, you can create Events anchored to real-world locations, each defined by geofenced Sites. Populate these Sites with diverse Media such as images, audio clips, videos, and 3D models to craft an immersive experience that users can explore through the Cylandar app.
      </p>
    </div>
    <div className='join-page-body-image'>
      <img src={image_01} alt='My WebP' /> 
    </div>
  </div>
);

const StepTwo = () => (
  <div className='join-page-body'>
    <div className='join-page-body-text'>
      <div>Interaction and Analytics</div>
      <p>Make your Events interactive by adding Activities, like quests or guided tours, which provide engaging and purposeful experiences for users. In the background, our powerful analytics tools in the website editor allow you to track how users interact with your content. This insight helps you to continuously improve the user experience, making each Event more engaging and effective over time.</p>
    </div>
    <div className='join-page-body-image'>
      <img src={image_02} alt='My WebP' /> 
    </div>
  </div>
);

const StepThree = () => (
  <div className='join-page-body'>
    <div className='join-page-body-text'>
      <div>Get Started with Cylandar</div>
      <p>Start creating your augmented reality content on our user-friendly website. Design your Events, set up Sites, and place Media to construct an engaging AR experience. Once published, these Events can be explored through the Cylandar mobile app, where the digital creations come to life, overlaying the physical world as you move through the geofenced locations..</p>

      <p>Ready to get started? Click 'Next' to sign up and begin your journey with Cylandar!</p>
  </div>
    <div className='join-page-body-image'>
      <img src={image_03} alt='My WebP' /> 
    </div>
  </div>
);
