import React, {useEffect, useCallback, useState} from 'react';

import useEditorManagement from '../Hooks/UseEditorManagement';

import { ReactComponent as AudioIcon } from '../Assets/audio-icon.svg';
import { ReactComponent as ImageIcon } from '../Assets/image-icon.svg';
import { ReactComponent as VideoIcon } from '../Assets/video-icon.svg';
import { ReactComponent as MeshIcon } from '../Assets/mesh-icon.svg';

import MediaPicker from '../Controls/MediaPicker';
import NameDescription from '../Controls/NameDescription';
import DateRangeEditor from '../Controls/DateRangeEditor';
import CartesianValues from '../Controls/CartesianValues';
import { MediaThumbnail } from '../Controls/MediaPicker';

import './MediaList.scss';

const MediaList = () => {
  const { getSelectedEvent, getSelectedSite, getSelectedMedia, updateItem, selectItem, setViewportType, setCachedAssetRemoved } = useEditorManagement();
  const selectedMedia = getSelectedMedia();
  const selectedSite = getSelectedSite();
  const [ showMediaPicker, setShowMediaPicker ] = useState(false);

  const handleSelectMedia = useCallback((media) => {
    let id = null;
    if (media) {
      id = media.guid;
    }
    selectItem({ itemType: 'media', id: id});
  }, [selectItem, setViewportType]); 

  useEffect(() => {
    if (selectedSite && selectedSite.media && selectedSite.media.length > 0) {
      handleSelectMedia(selectedSite.media[0]);
    }
  }, [selectedSite, selectedSite.media]);

  const handleShowMediaViewport = () => {
    setViewportType(3);
  };

  const handleShowMediaLibrary = () => {
    setViewportType(4);
  };

  const handleRemoveMedia = async (mediaToRemove) => {
    const selectedEvent = getSelectedEvent();
    let isMediaUsedInActivities = false;
  
    selectedEvent.activities.forEach(activity => {
      activity.steps.forEach(step => {
        if(step.mediaId === mediaToRemove.id) {
          isMediaUsedInActivities = true;
        }
      });
    });
  
    if(isMediaUsedInActivities) {
      alert('This Media is being used in one or more activities and cannot be deleted.');
      return;
    }
    if (selectedSite.media.length === 1) {
      alert('A site must have at least one media. You cannot remove the last media item.');
      return;
    }
  
    const confirmRemoval = window.confirm(`Are you sure you want to remove "${mediaToRemove.name}" from "${selectedSite.name}"?`);
    
    if (confirmRemoval) {
      const updatedMedia = selectedSite.media.filter(media => media.guid !== mediaToRemove.guid);
      updateItem('site', { media: updatedMedia });
  
      if (selectedMedia && mediaToRemove.guid === selectedMedia.guid) {
        handleSelectMedia(null);
      }
  
      setCachedAssetRemoved(mediaToRemove);
    }
  };  
  
  const handleToggleActive = async () => {
    const newActiveStatus = !selectedMedia.active;
    updateItem('sitemedia', { active: newActiveStatus });
  };

  const handleMediaChange = async () => {
    setShowMediaPicker(true);
  }; 

  const handleSetNewMedia = async (newMedia) => {
    if (!selectedSite || !selectedMedia) return;
  
    const mediaIndex = selectedSite.media.findIndex(media => media.id === getSelectedMedia().id);
  
    if (mediaIndex === -1) {
      console.error('Selected media not found in site');
      return;
    }
  
    const originalMedia = selectedSite.media[mediaIndex];
  
    const updatedMedia = {
      ...originalMedia,
      id: newMedia.id,
      guid: `${newMedia.id}_${new Date().getTime()}`,
    };
  
    const updatedMediaArray = [
      ...selectedSite.media.slice(0, mediaIndex),
      updatedMedia,
      ...selectedSite.media.slice(mediaIndex + 1),
    ];
    
    updateItem('site', {
      ...selectedSite,
      media: updatedMediaArray,
    });
  
    handleSelectMedia(updatedMedia);
  
    setShowMediaPicker(false);
  };
  

  const getCachedAssetIcon = (fileType) => {
    if (fileType.includes('audio')) {
      return <AudioIcon />;
    } else if (fileType.includes('image')) {
      return <ImageIcon />;
    } else if (fileType.includes('video')) {
      return <VideoIcon />;
    } else if (fileType.includes('mesh')) {
      return <MeshIcon />;
    } else if (fileType.includes('audio')) {
      return <AudioIcon />;
    } else {
      return null;
    }
  };
 
  return (
    <div className='media panel'>
      <div className='media-details-menu'>
        <div className='media-details-left'>
          <div className='selected-media-thumbanil-container panel' onClick={handleMediaChange}>
            { selectedMedia ? (
                <MediaThumbnail key={selectedMedia.id} mediaItem={selectedMedia} label={false} />
              ) : ( <div className='selected-media-thumbanil-none'>
                      <div className='no-media-message'>No media Selected</div>
                    </div>
            )}
          </div>
          <div className='selected-media-deactivate-container'>
            { selectedSite.media.length > 1 && <button onClick={handleToggleActive}>{selectedMedia?.active ? 'Deactivate' : 'Activate'}</button> }
          </div>
        </div>
        <div className='selected-media-inputs'>
          <NameDescription collection={'sitemedia'}/>
          <DateRangeEditor collection={'media'} />
          <CartesianValues collection={'media'} />
        </div>
      </div>
      <div className='media-add-new-container'>
        <button onClick={handleShowMediaLibrary}>Add Media</button>
      </div>
      <div className='media-list-container'>
        {selectedSite.media.length > 0 ? (
          <div className='media-list'>
            {selectedSite.media.map((media, index) => (
              <div className={selectedMedia && media.guid === selectedMedia.guid ? 'media-item-selected' : 'media-item'}
                key={index} 
                onClick={() => handleSelectMedia(media)}
              >
                <div> {getCachedAssetIcon(media.fileType)}</div>
                <div className="media-text">{media.name.replace(/\.[^/.]+$/, "")}</div> 
                {selectedMedia && media.guid === selectedMedia.guid && (
                  <div>
                    <button onClick={() => handleShowMediaViewport(media)}>Edit 3D</button>
                    { selectedSite.media.length > 1 && <button onClick={() => handleRemoveMedia(media)}>X</button>}
                  </div>
                  )}
              </div>
            ))}
          </div>
        ) : (
          <p>No media found for this site.</p>
        )}
      </div>
      {showMediaPicker && (
                <MediaPicker
                    mediaType={'all'}
                    onSelect={handleSetNewMedia}
                    onClose={() => setShowMediaPicker(false)}
                />
            )}
    </div>
  );
};

export default MediaList;
