import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoginGoogle, fetchDocumentData, addNewUser, handleFirestoreError } from '../Utilities/firestoreUtils';
import { signInWithEmail, createAccountWithEmail, resetPassword } from '../Utilities/firestoreUtils';

import { UserContext } from '../Contexts/UserContext';
import { useMessage } from '../Contexts/SystemMessageContext';

import './Auth.scss';

const Auth = ({joining}) => {
  const navigate = useNavigate();
  const { setUser, setAuthUser } = useContext(UserContext);
  const { showMessage, setWaiting, setWaitingMessage } = useMessage();
  const [ isPasswordResseting, setIsPasswordResseting ] = useState(false);

  const handleGoogleSignIn = async () => {
    setWaiting(true);
    setWaitingMessage('Signing in with Google...');
    const userDetails = await LoginGoogle();
    processUserDetails(userDetails);
  };

  const handleEmailSignIn = async (email, password, displayName) => {
    setWaiting(true);
    setWaitingMessage('Checking account...');
  
    let userDetails = await signInWithEmail(email, password);
    if (!userDetails) {
      setWaitingMessage('Creating account...');
      userDetails = await createAccountWithEmail(email, password, displayName);
      if (!userDetails) {
        showMessage('Failed to create an account.');
        return;
      }
    }
  
    processUserDetails(userDetails);
  };

  const processUserDetails = async (userDetails) => {
    const getUserAccount = await fetchDocumentData('user', userDetails.uid);
    if (getUserAccount) {
      setUser(getUserAccount);
    } else {
      setAuthUser(userDetails);
      const newUser = await createAccount(userDetails);

      if (newUser) {
        setUser(newUser);
      }
    }
    setWaiting(false);
    setWaitingMessage('');
    navigate('/user-account');
  };

  const createAccount = async (user) => {
    try {
      const existingUser = await fetchDocumentData('user', user.uid);
      if (!existingUser) {
        const message = `Created new user account for: ${user.displayName}`;
        const newUser = await addNewUser(user, showMessage, message);
        setUser(newUser);
        return newUser;
      }
    } catch (error) {
      handleFirestoreError(error, 'creating account');
    }
  };
  
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const email = event.target.username.value;
    const password = event.target.password.value;
    const displayName = event?.target?.displayname?.value;
    handleEmailSignIn(email, password, displayName);
  };

  const signup = joining ? 'Sign up' : 'Log in';

  return (
    <div className='signup-page'>
      <form onSubmit={handleSubmit}>
          { !isPasswordResseting && 
          <div className='signup-page-social'>
            <div className='signup-page-header'> {signup} using Social Media</div>
            <button type="button" onClick={handleGoogleSignIn} className='google'> 
              {signup} with Google
            </button>
          </div>}

          <div className='signup-page-email'>
            { isPasswordResseting? (<>
              <p>Reset Password</p>
              </>): 
              (<>
              <p>Or</p>
              <p>{signup} using email and password</p>
              </>)
            }

            { isPasswordResseting ? <PasswordResetComponent /> : (
              <>
                {joining && 
                  <input type='text' name='displayname' placeholder='Display Name' required />
                }
                <input type='text' name='username' placeholder='Username' required />
                <input type='password' name='password' placeholder='Password' required />
                <input type='submit' value={signup} />
              </>
            )}
            <button type="button" onClick={() => setIsPasswordResseting(!isPasswordResseting)}>
              {isPasswordResseting ? 'Back to login' : 'Forgot Password?'}
            </button>
          </div>
      </form>
    </div>
  );
}

export default Auth;

function PasswordResetComponent() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handlePasswordReset = async () => {
    const response = await resetPassword(email);
    setMessage(response.message);
  };

  return (
    <div className='signup-page-email-reset'>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Enter your email"
      />
      <button type="button" onClick={handlePasswordReset}>Reset Password</button>
      {message && <p>{message}</p>}
    </div>
  );
}