import React, { useRef, useState, useEffect, useCallback, useContext } from 'react';
import { debounce } from 'lodash';

import useThreeScene from '../Hooks/UseThreeScene'
import useEditorManagement from '../Hooks/UseEditorManagement';

import { EditorContext } from '../Contexts/EditorContext';

import './ThreeDMap.scss';

const ThreeDMap = () => {
    const { transformSpace, setMapLoadingStatus } = useEditorManagement();

    const ref = useRef();
    
    const { transformMode, updateTransformMode, toggleTransformSpace, updateSelectedObjectTransformation } = useThreeScene(ref, () => setMapLoadingStatus('loaded'),  );

    return  <div className="threeDMap">
                <div className="threeDMap-viewport" ref={ref}></div>   
                <ThreeDMapControls 
                    updateTransformMode={updateTransformMode}
                    transformMode={transformMode}
                    toggleTransformSpace={toggleTransformSpace}
                    transformSpace={transformSpace}
                />
                <CartesianValues updateSelectedObjectTransformation={updateSelectedObjectTransformation}/>
            </div>
};

export default ThreeDMap;
    
const ThreeDMapControls = ({ updateTransformMode, toggleTransformSpace, transformSpace }) => {
    const { viewType, setViewType } = useEditorManagement();
    const { uniformScaleMode, setUniformScaleMode, transformMode, setTransformMode } = useContext(EditorContext);

    const handleButtonClick = (mode) => {
        if (mode === 'uniformScale') {
            const newMode = !uniformScaleMode ? 'uniformScale' : 'scale';
            updateTransformMode(newMode)
            setUniformScaleMode(!uniformScaleMode);
            setTransformMode(newMode);
        } else {
            updateTransformMode(mode);
            setTransformMode(mode);
            if (uniformScaleMode) {
                setUniformScaleMode(false);
            }
        }
    }

    const toggleViewType = () => {
        setViewType(viewType === 'map' ? 'satellite' : 'map');
    };

    return (
        <div className="threeDMap-viewport-ui">
            <button onClick={toggleViewType} className="toggle-view-button">
                {viewType === 'map' ? 'Road Map' : 'Satellite' }
            </button>
            <button onClick={toggleTransformSpace} className="toggle-space-button">
                {transformSpace === 'local' ? 'Local Space' : 'World Space'}
            </button>
            <button className="toggle-view-button" onClick={(e) => handleButtonClick('translate')}>Move</button>
            <button className="toggle-view-button" onClick={(e) => handleButtonClick('rotate')}>Rotate</button>
            <button className="toggle-view-button" onClick={(e) => handleButtonClick('scale')}>Scale</button>
            {
                (transformMode === 'scale' || transformMode === 'uniformScale') && <button className="toggle-view-button" onClick={(e) => handleButtonClick('uniformScale')}>{uniformScaleMode ? 'Uniform Scale': 'Freeform Scale'}</button>
            }
        </div>
    );
};

const toRadians = (degrees) => degrees * (Math.PI / 180);
const toDegrees = (radians) => radians * (180 / Math.PI);

const CartesianValues = ({ updateSelectedObjectTransformation }) => {

    const { getSelectedMedia } = useEditorManagement();

    const selectedMedia = getSelectedMedia();
    const [mediaValues, setMediaValues] = useState({
        position: selectedMedia ? selectedMedia.position : [0, 0, 0],
        rotation: selectedMedia ? selectedMedia.rotation.map(toDegrees) : [0, 0, 0],
        scale: selectedMedia ? selectedMedia.scale : [1, 1, 1],
    });

    const hasChanges = useRef(false);

    const isValidNumber = (value) => !isNaN(value) && value !== '';

    const updateMediaValues = useCallback((field, index, value) => {
        if (!isValidNumber(value)) return;
        hasChanges.current = true;
        setMediaValues((prevState) => ({
            ...prevState,
            [field]: [
                ...prevState[field].slice(0, index),
                parseFloat(value),
                ...prevState[field].slice(index + 1),
            ],
        }));
    }, []);

    const saveChanges = useRef(debounce(async (getCurrentMediaValues) => {
        const currentMediaValues = getCurrentMediaValues();
        // Validate all inputs before saving
        // const allValuesValid = Object.values(currentMediaValues).every((values) =>
        //     values.every(isValidNumber)
        // );
    
        if (!hasChanges.current || !selectedMedia) return;
    
        await updateSelectedObjectTransformation(
            currentMediaValues.position,
            currentMediaValues.rotation.map(toRadians),
            currentMediaValues.scale
        );
        hasChanges.current = false;
    }, 500)).current;
    
    useEffect(() => {
        const handleSave = () => saveChanges(() => mediaValues);
        handleSave();
    
        return () => saveChanges.cancel();
    }, [mediaValues, saveChanges]);

    useEffect(() => {
        if (selectedMedia) {
            setMediaValues({
                position: selectedMedia.position,
                rotation: selectedMedia.rotation.map(toDegrees),
                scale: selectedMedia.scale,
            });
        }
    }, [selectedMedia]);

    return (
        <div className='threeDMap-values-editor panel'>
            {Object.entries(mediaValues).map(([field, values]) => (
                <div key={field} className='threeDMap-values-editor-inputs'>
                    {`${field.charAt(0).toUpperCase() + field.slice(1)}`}
                    {values.slice(0, 3).map((value, index) => (
                        <input
                            key={`${field}-${index}`}
                            type="number"
                            value={value}
                            onChange={(e) => updateMediaValues(field, index, e.target.value)}
                            onBlur={() => saveChanges(() => mediaValues)}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};
