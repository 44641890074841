import React, { useContext, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../Contexts/UserContext';
import '../App.scss';

const UserControls = () => {
  const { user, userLogout } = useContext(UserContext);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const handleJoin = () => {
    navigate('/join');
    setIsDropdownVisible(false);
  };
  
  const handleLogin = () => {
    navigate('/login');
    setIsDropdownVisible(false);
  };

  const handleShowAccountDetails = () => {
    navigate('/accountdetailspage');
    setIsDropdownVisible(false);
  };

  const handleLogOut = () => {
    userLogout();
    setIsDropdownVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Generate a URL for a placeholder image with a random color
  const getRandomColorImage = () => {
    const firstLetter = user.displayName ? user.displayName.charAt(0).toUpperCase() : 'U';
    return `https://via.placeholder.com/150/ADD8E6/ffffff?text=${firstLetter}`;
  };

  return (
    <div className='user-controls' ref={dropdownRef}>
      {user ? (
        <div>
          <img
            src={user.photoURL || getRandomColorImage()}
            className='profile-thumbnail'
            onClick={handleToggleDropdown}
            alt='Profile'
          />
          {isDropdownVisible && (
            <div className={`dropdown-menu ${isDropdownVisible ? 'dropdown-visible' : ''}`}>
              <button onClick={handleShowAccountDetails}>Payments</button>
              <button onClick={handleLogOut}>Logout</button>
            </div>
          )}
        </div>
      ) : (
        <div>
          <button onClick={handleJoin}>Join</button>
          <button onClick={handleLogin}>Login</button>
        </div>
      )}
    </div>
  );
};

export default UserControls;
