import React, { useState } from 'react';

import '../Modal.scss';

const RenameUI = ({ data, handleAction }) => {
    const [originalName] = useState(data.newName);
    const [newName, setNewName] = useState(data.newName);

    return (
        <div className="rename-modal">
            <h2>Rename Media</h2>
            <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                placeholder="Enter new name"
            />
            {newName !== originalName && (<button onClick={() => handleAction(newName)}>Rename</button>)}
        </div>
    );
}

export default RenameUI;
