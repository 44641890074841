import React, { useEffect, useState } from 'react';

import useEditorManagement from '../../Hooks/UseEditorManagement';

import Calendar from 'react-calendar';
import TimePicker from 'react-time-picker';
import 'react-calendar/dist/Calendar.css';
import './DateRangeEditorUI.scss'; 

const DateRangeEditorUI = ({ collection, data }) => {
    const { updateItem, getSelectedSite, getSelectedMedia } = useEditorManagement();
    
    const [ startSiteDate, setStartSiteDate ] = useState(formatDate(getSelectedSite().startDate.seconds));
    const [ startMediaDate, setMediaStartDate ] = useState(formatDate(getSelectedMedia().startDate.seconds));
    const [ tempStartDate, setTempStartDate ] = useState(startSiteDate);
    
    const [ startTime, setStartTime ] = useState(formatTime(getSelectedSite().startDate.seconds));
    const [ startMediaTime, setMediaStartTime ] = useState(formatTime(getSelectedSite().startDate.seconds));
    
    const [ endSiteDate, setEndSiteDate ] = useState(formatDate(getSelectedSite().endDate.seconds));
    const [ endMediaDate, setEndMediaDate ] = useState(formatDate(getSelectedMedia().endDate.seconds));
    const [ tempEndDate, setTempEndDate ] = useState(endSiteDate);

    const [ endTime, setEndTime ] = useState(formatTime(getSelectedSite().endDate.seconds));
    const [ endMediaTime, setMediaEndTime ] = useState(formatTime(getSelectedSite().endDate.seconds));
    const [ duration, setDuration ] = useState('');

    const [ hasUnsavedChanges, setHasUnsavedChanges ] = useState(false);

    useEffect(() => {
        if (collection === 'media') {
            setTempStartDate(startMediaDate);
            setTempEndDate(endMediaDate);
        }
    }, []);

    useEffect(() => {
        calculateAndSetDuration();
    }, [startSiteDate, endSiteDate, startTime, endTime]);

    const onStartDateChange = newDate => {
        const newStartDate = new Date(newDate);

        if (collection === 'site') {
            const existingEndDate = endSiteDate;

            if (newStartDate > existingEndDate) {
                alert('Start date cannot be later than end date.');
                setTempStartDate(startSiteDate);
            } else {
                setStartSiteDate(formatDate(newStartDate.getTime() / 1000));
                setTempStartDate(formatDate(newStartDate.getTime() / 1000));
                setHasUnsavedChanges(true);
            }
        } else if (collection === 'media') {
            const existingEndDate = endMediaDate;

            if (newStartDate > endSiteDate) {
                alert('Media start date cannot be later than Site end date.');
                setMediaStartDate(startMediaDate);
            } else if (newStartDate < startSiteDate) {
                alert('Media start date cannot be earlier than Site start date.');
                setMediaStartDate(startMediaDate);
            } else if (newStartDate > endMediaDate) {
                alert('Media start date cannot be later than Media end date.');
                setMediaStartDate(startMediaDate);
            } else {
                setMediaStartDate(formatDate(newStartDate.getTime() / 1000));
                setTempStartDate(formatDate(newStartDate.getTime() / 1000));
                setHasUnsavedChanges(true);
            }
        }
    };

    const onEndDateChange = newDate => {
        const newEndDate = new Date(newDate);
        if (collection === 'site') {
            const existingStartDate = startSiteDate;

            if (newEndDate < existingStartDate) {
                alert('End date cannot be earlier than start date.');
                setTempEndDate(endSiteDate);
            } else {
                setEndSiteDate(formatDate(newEndDate.getTime() / 1000));
                setTempEndDate(formatDate(newEndDate.getTime() / 1000));
                setHasUnsavedChanges(true);
            }
        } else if (collection === 'media') {
            const existingStartDate = startSiteDate;

            if (newEndDate < existingStartDate) {
                alert('Media end date cannot be earlier than Site start date.');
                setEndMediaDate(endMediaDate);
            } else if (newEndDate > endSiteDate) {
                alert('Media end date cannot be later than Site end date.');
                setEndMediaDate(endMediaDate);
            } else if (newEndDate < startMediaDate) {
                alert('Media end date cannot be earlier than Media start date.');
                setEndMediaDate(endMediaDate);
            }else {
                setEndMediaDate(formatDate(newEndDate.getTime() / 1000));
                setTempEndDate(formatDate(newEndDate.getTime() / 1000));
                setHasUnsavedChanges(true);
            }
        }
    };

    const onStartTimeChange = newTime => {
        if(collection === 'site') { 
            const formattedStartDate = startSiteDate.toISOString().split('T')[0];
            const formattedEndDate = endSiteDate.toISOString().split('T')[0];

            const newStartTimeString = `${formattedStartDate}T${newTime}`;
            const existingEndTimeString = `${formattedEndDate}T${endTime}`;

            const newStartTime = new Date(newStartTimeString);
            const existingEndTime = new Date(existingEndTimeString);

            if (isSameDay(startSiteDate, endSiteDate) && newStartTime >= existingEndTime) {
                alert('Start time cannot be later than end time on the same day.');
                setStartTime(formatTime(data.startDate.seconds));
            } else {
                setStartTime(newTime);
                setHasUnsavedChanges(true);
            }
        } else if (collection === 'media') {
            const formattedStartDate = startMediaDate.toISOString().split('T')[0];
            const formattedEndDate = endMediaDate.toISOString().split('T')[0];

            const newStartTimeString = `${formattedStartDate}T${newTime}`;
            const existingEndTimeString = `${formattedEndDate}T${endMediaTime}`;

            const newStartTime = new Date(newStartTimeString);
            const existingEndTime = new Date(existingEndTimeString);

            if (isSameDay(startMediaDate, endMediaDate) && newStartTime >= existingEndTime) {
                alert('Start time cannot be later than end time on the same day.');
                setMediaStartTime(formatTime(data.startDate.seconds));
            } else {
                setMediaStartTime(newTime);
                setHasUnsavedChanges(true);
            }
        }
    };

    const onEndTimeChange = newTime => {
        if (collection === 'site') {
            const formattedStartDate = startSiteDate.toISOString().split('T')[0];
            const formattedEndDate = endSiteDate.toISOString().split('T')[0];
        
            const existingStartTimeString = `${formattedStartDate}T${startTime}`;
            const newEndTimeString = `${formattedEndDate}T${newTime}`;
        
            const existingStartTime = new Date(existingStartTimeString);
            const newEndTime = new Date(newEndTimeString);
        
            if (isSameDay(startSiteDate, endSiteDate) && newEndTime <= existingStartTime) {
                alert('End time cannot be earlier than start time on the same day.');
                setEndTime(formatTime(data.endDate.seconds));
            } else {
                setEndTime(newTime);
                setHasUnsavedChanges(true);
            }
        } else if (collection === 'media') {
            const formattedStartDate = startMediaDate.toISOString().split('T')[0];
            const formattedEndDate = endMediaDate.toISOString().split('T')[0];

            const existingStartTimeString = `${formattedStartDate}T${startMediaTime}`;
            const newEndTimeString = `${formattedEndDate}T${newTime}`;

            const existingStartTime = new Date(existingStartTimeString);
            const newEndTime = new Date(newEndTimeString);

            if (isSameDay(startMediaDate, endMediaDate) && newEndTime <= existingStartTime) {
                alert('End time cannot be earlier than start time on the same day.');
                setMediaEndTime(formatTime(data.endDate.seconds));
            } else {
                setMediaEndTime(newTime);
                setHasUnsavedChanges(true);
            }
        }
    };

    const isSameDay = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
               date1.getMonth() === date2.getMonth() &&
               date1.getDate() === date2.getDate();
    };

    const calculateAndSetDuration = () => {
        const startDateTime = new Date(`${startSiteDate.toISOString().split('T')[0]}T${startTime}`);
        const endDateTime = new Date(`${endSiteDate.toISOString().split('T')[0]}T${endTime}`);
        const durationInSeconds = (endDateTime - startDateTime) / 1000;
        
        if (durationInSeconds < 0) {
            setDuration('Invalid duration');
        } else {
            setDuration(formatDuration(durationInSeconds));
        }
    };

    const formatDuration = (seconds) => {
        const days = Math.floor(seconds / 86400);
        const hours = Math.floor((seconds % 86400) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);

        return `${days} days, ${hours} hours, ${minutes} minutes`;
    };

    const onSave = async () => {
        const combineDateAndTime = (date, time) => {
            const timeParts = time.split(':');
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(), parseInt(timeParts[0]), parseInt(timeParts[1]));
        };
        let newStartDate;
        let newEndDate;
        if (collection === 'site') { 
            newStartDate = startSiteDate;
            newEndDate = endSiteDate;
        } else if (collection === 'media') {
            newStartDate = startMediaDate;
            newEndDate = endMediaDate;
        }
        const combinedStartDate = combineDateAndTime(newStartDate, startTime);
        const combinedEndDate = combineDateAndTime(newEndDate, endTime);
    
        const convertToFirestoreTimestamp = (date) => {
            return {
                seconds: Math.floor(date.getTime() / 1000),
                nanoseconds: (date.getTime() % 1000) * 1000000
            };
        };
    
        const firestoreStartDate = convertToFirestoreTimestamp(combinedStartDate);
        const firestoreEndDate = convertToFirestoreTimestamp(combinedEndDate);
    
        try {
            if (collection === 'site') {
                const updateData = {
                    startDate: firestoreStartDate,
                    endDate: firestoreEndDate
                };
                await updateItem(collection, updateData);
            } else if (collection === 'media') {
                let site = getSelectedSite();
                let mediaIndex = site.media.findIndex(media => media.id === getSelectedMedia().id);
                if (mediaIndex !== -1) {
                    site.media[mediaIndex].startDate = firestoreStartDate;
                    site.media[mediaIndex].endDate = firestoreEndDate;
                    await updateItem('site', site);
                }
            }
            setHasUnsavedChanges(false);
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };
    
    const resetChanges = () => {
        setStartSiteDate(tempStartDate);
        setEndSiteDate(tempEndDate);
        setStartTime(formatTime(data.startDate.seconds));
        setEndTime(formatTime(data.endDate.seconds));
        setHasUnsavedChanges(false);
    };

    const isWeekend = (date) => {
        const day = date.getDay();
        // Check if the day is Saturday (6) or Sunday (0)
        return day === 0 || day === 6;
    };

    return (
        <div className="date-range-editor">
            <div className="date-range-editor-title">
                {collection} Duration
            </div>
            <div className="date-range-editor-instructions">
                Set the length of the {collection} by changing the start and end dates and times.
            </div>
            <div className="date-range-editor-text">
                Duration: {duration}
            </div>
            <div className="date-range-editor-calendar-date-container">
                <div className="date-range-editor-calendar-containerr">
                    <div className='date-range-editor-label ' >Start Date </div>
                    <div className="date-range-editor-calendar">
                        <Calendar
                            onChange={onStartDateChange}
                            value={tempStartDate}
                            tileClassName={({ date, view }) => {
                                if (view === 'month' && date.getDate() === new Date(startSiteDate).getDate() && date.getMonth() === new Date(startSiteDate).getMonth() && date.getFullYear() === new Date(startSiteDate).getFullYear()) {
                                    return 'calendar-highlight-start';
                                }
                                if (view === 'month' && date > startSiteDate && date < endSiteDate && isWeekend(date)) {
                                    return 'date-range-selected';
                                }
                                if (view === 'month' && isWeekend(date)) {
                                    return 'calendar-weekend-style';
                                }
                                if (view === 'month' && date > startSiteDate) {
                                    return 'date-range-selected';
                                }
                            }}
                            calendarType="gregory"
                        />
                    </div>
                    <div className="date-range-editor-time">
                        <TimePicker
                            onChange={onStartTimeChange}
                            value={startTime}
                        />
                    </div>
                </div>
                
                <div className="date-range-editor-calendar-container">
                    <div className='date-range-editor-label ' >End Date </div>
                    <div className="date-range-editor-calendar">
                        <Calendar
                            className={''}
                            onChange={onEndDateChange}
                            value={tempEndDate}
                            tileClassName={({ date, view }) => {
                                if (view === 'month' && date.getDate() === new Date(endSiteDate).getDate() && date.getMonth() === new Date(endSiteDate).getMonth() && date.getFullYear() === new Date(endSiteDate).getFullYear()) {
                                    return 'calendar-highlight-start';
                                }
                                if (view === 'month' && date > startSiteDate && date < endSiteDate && isWeekend(date)) {
                                    return 'date-range-selected';
                                }
                                if (view === 'month' && isWeekend(date)) {
                                    return 'calendar-weekend-style';
                                }
                                if (view === 'month' && date < endSiteDate) {
                                    return 'date-range-selected';
                                }
                            }}
                            calendarType="gregory"
                        />
                        <div className="date-range-editor-time">
                            <TimePicker
                                onChange={onEndTimeChange}
                                value={endTime}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="save-button-container">
                {hasUnsavedChanges && (
                    <div className="save-button-container">
                        <button onClick={onSave} className="save-button">
                            Save Changes
                        </button>
                        <button onClick={resetChanges} className="reset-button">
                            Reset Changes
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DateRangeEditorUI;

function formatDate(seconds) {
    return new Date(seconds * 1000);
}

const formatTime = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

