import { useCallback, useContext } from 'react';

import { differenceInCalendarDays } from 'date-fns';

import { fetchUserEvents, fetchEventSites, addEvent, addSite, updateFirestoreData, fetchDocumentData, getUserAccount } from '../Utilities/firestoreUtils';

import { UserContext } from '../Contexts/UserContext';
import { EditorContext } from '../Contexts/EditorContext';
import { useMessage } from '../Contexts/SystemMessageContext';

function useEditorManagement() {
  const { user, setUser } = useContext(UserContext);
  const { userEvents, setUserEvents, selectedEventId, setSelectedEventId, selectedSiteId, setSelectedSiteId, selectedMediaId, setSelectedMediaId, viewportType, setViewportType, mapLoadingStatus, setActiveComponent, transformSpace, viewType, setViewType, setMapLoadingStatus, cameraPosition, setCameraPosition, cameraRotation, setCameraRotation, transformMode, setTransformMode,  setTransformSpace, mediaRemoved, setCachedAssetRemoved, cartesianEditor, setCartesianEditor } = useContext(EditorContext);

  const { showMessage } = useMessage();

  const getUserAccountDetails = async () => {
    try {
      const userAccount = await getUserAccount(user.uid);
      await getEvents();
      if (!userAccount) {
        console.error('No user account found.');
        return null;
      }
      return userAccount;
    } catch (error) {
      console.error("Error fetching the user account:", error);
      return null;
    }
  }

  const getEvents = async () => {
    try {
      const userEvents = await fetchUserEvents(user.uid);
      const eventsWithSitesPromises = userEvents.map(async event => {

        const eventSites = await fetchEventSites(event.id);
        return { ...event, sites: eventSites };
      });
  
      const eventsWithSites = await Promise.all(eventsWithSitesPromises);
      setUserEvents(eventsWithSites);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const selectItem = useCallback(({ itemType, id }) => {
    if (itemType === 'event') {
      const selectedEvent = userEvents.find(event => event.id === id);
      const firstSiteId = selectedEvent?.sites?.[0]?.id;
      const selectedSite = selectedEvent?.sites?.find(site => site.id === firstSiteId) || {}; // Fallback to an empty object
      const firstMediaId = selectedSite.media?.[0]?.id;
      setSelectedEventId(id);
      setSelectedSiteId(firstSiteId || null);
      setSelectedMediaId(firstMediaId || null);

    } else if (itemType === 'site') { 
      const selectedSite = getSelectedEvent()?.sites?.find(site => site.id === id);
      const firstMediaId = selectedSite?.media?.[0]?.id;
      setSelectedSiteId(id);
      setSelectedMediaId(firstMediaId || null);
    } else if (itemType === 'media') {
      if(id===null || id===undefined){
        setSelectedMediaId(null);
      } else {
        setSelectedMediaId(id);
      }
    }
  }, [userEvents, selectedEventId]);

  const getSelectedEvent = useCallback(() => {
    return userEvents.find(event => event.id === selectedEventId) || null;
  }, [userEvents, selectedEventId]);

  const getSelectedSite = useCallback(() => {
    const selectedEvent = userEvents.find(event => event.id === selectedEventId)
    return selectedEvent?.sites.find(site => site.id === selectedSiteId) || null;
  }, [userEvents, selectedEventId, selectedSiteId]);

  const getSelectedMedia = useCallback(() => {
    const selectedEvent = userEvents.find(event => event.id === selectedEventId);
    const selectedSite = selectedEvent?.sites.find(site => site.id === selectedSiteId);
    return selectedSite.media.find(media => media.guid === selectedMediaId) || null;
  }, [userEvents, selectedEventId, selectedSiteId, selectedMediaId]);

  const updateItem = useCallback( async(updateType, data) => {
    try {
      if (updateType === 'event') {
        const message = `Updated event details for "${getSelectedEvent().name}"`;
        await updateFirestoreData(updateType, selectedEventId, data, showMessage, message);
      } else if (updateType === 'site') {
        const message = `Updated site details for "${getSelectedSite().name}"`;
        await updateFirestoreData(updateType, getSelectedSite().id, data, showMessage, message);
      } else if (updateType === 'media') {
        const message = `Updated media details for "${getSelectedMedia().name}"`;
        await updateFirestoreData(updateType, selectedMediaId, data, showMessage, message);
      } else if (updateType === 'mediaFile') {
        updateType = 'media';
        const message = `Updated media details for "${data.name}"`;
        await updateFirestoreData(updateType, data.id, data, showMessage, message);
      } else if (updateType === 'sitemedia') {
        updateType = 'site';
        const siteData = getSelectedSite();

        const mediaIndex = siteData.media.findIndex(mediaItem => mediaItem.guid === selectedMediaId);
        siteData.media[mediaIndex] = {
          ...getSelectedSite().media[mediaIndex],
          ...data
        };

        data = siteData;
        const message = `Updated media details for "${siteData.name}"`;
        await updateFirestoreData(updateType, selectedSiteId, data, showMessage, message);
      }
  
      setUserEvents(prevEvents => {
        let updatedEvents = [...prevEvents];
  
        if (updateType === 'event') {
          updatedEvents = updatedEvents.map(event => 
            event.id === selectedEventId ? { ...event, ...data } : event
          );
        } else if (updateType === 'site') {
          updatedEvents = updatedEvents.map(event => {
            if (event.id === selectedEventId) {
              return {
                ...event,
                sites: event.sites.map(site =>
                  site.id === selectedSiteId ? { ...site, ...data } : site
                )
              };
            }
            return event;
          });
        } else if (updateType === 'media') {
          updatedEvents = updatedEvents.map(event => {
            if (event.id === selectedEventId) {
              return {
                ...event,
                sites: event.sites.map(site => {
                  if (site.id === selectedSiteId) {
                    return {
                      ...site,
                      media: site.media.map(mediaItem =>
                        mediaItem.id === selectedMediaId ? { ...mediaItem, ...data } : mediaItem
                      )
                    };
                  }
                  return site;
                })
              };
            }
            return event;
          });
        }
  
        return updatedEvents;
      });
      
    } catch (error) {
      console.error("Error in updateItem:", error);
    }
  }, [selectedEventId, selectedSiteId, selectedMediaId, showMessage]);
  
  const removeItem = useCallback(async (itemType, itemId) => {
    try {
      if (itemType === 'event') {
        const message = `Deleted event "${getSelectedEvent().name}"`;
        await updateFirestoreData('event', itemId, { deleted: true }, showMessage, message);
        const filteredEventIds = userEvents.filter(event => event.id !== itemId).map(event => event.id);
        await updateFirestoreData('user', user.uid, { event: filteredEventIds }, showMessage, message);

        setUserEvents(prevEvents => {
          if (itemType === 'event') {
            const userEvents = prevEvents.filter(event => event.id !== itemId);
            return userEvents;
          } else if (itemType === 'site') {
            // Add logic to filter out deleted site
          } else if (itemType === 'media') {
            // Add logic to filter out deleted media
          }
          return prevEvents;
        });
      } else if (itemType === 'site') {
        setUserEvents(prevEvents => {
          const updatedEvents = prevEvents.map(event => {
            if (event.sites.some(site => site.id === itemId)) {
              const updatedEvent = { ...event, sites: event.sites.filter(site => site.id !== itemId) };
              const message = `Updated event "${event.name}" to remove deleted site.`;
              updateFirestoreData('event', selectedEventId, { sites: updatedEvent.sites }, showMessage, message);
              return updatedEvent;
            }
            return event;
          });
          return updatedEvents;
        });
      } else if (itemType === 'media') {

      }

    } catch (error) {
      console.error("Error in removeItem:", error);
    }
  }, [showMessage]);

  const addNewEvent = async (subscriptionId, eventDetails, siteDetails) => {
    try {
      const newEvent = await addEvent(subscriptionId, user.uid, eventDetails, siteDetails, showMessage);
      setUser(prevUser => {
        const updatedSubscriptions = prevUser.subscriptions.map(subscription => {
          if (subscription.subscriptionId === subscriptionId) {
            return { ...subscription, event: newEvent.id };
          }
          return subscription;
        });
        return { ...prevUser, subscriptions: updatedSubscriptions };
      });
      await getEvents();
      return newEvent;
    } catch (error) {
      console.error("Error in add event:", error);
    }
  };

  const addNewSite = async (siteDetails) => {
    try {
      const newSite = await addSite(user.uid, selectedEventId, siteDetails, showMessage);
      await getEvents();
      return newSite;
    } catch (error) {
      console.error("Error in add site:", error);
    }
  };

  const calculateEventDuration = useCallback((eventId) => {
    const selectedEvent = userEvents.find(event => event.id === eventId);
    // Exclude sites where promo is true
    const filteredSites = selectedEvent.sites.filter(site => !site.promo);
  
    if (!selectedEvent || !filteredSites || filteredSites.length === 0) {
      return 0;
    }
  
    let earliestDate = new Date(filteredSites[0].startDate.seconds * 1000);
    let latestDate = new Date(filteredSites[0].endDate.seconds * 1000);
  
    filteredSites.forEach(site => {
      const startDate = new Date(site.startDate.seconds * 1000);
      const endDate = new Date(site.endDate.seconds * 1000);
      if (startDate < earliestDate) earliestDate = startDate;
      if (endDate > latestDate) latestDate = endDate;
    });
  
    const totalDays = differenceInCalendarDays(latestDate, earliestDate)+1;
    const daysRemaining = differenceInCalendarDays(latestDate, new Date()) ;
    const formattedStartDate = earliestDate.toLocaleDateString();
    const formattedEndDate = latestDate.toLocaleDateString();
  
    return { totalDays, daysRemaining, startDate: formattedStartDate, endDate: formattedEndDate };
  
  }, [getSelectedEvent]);

  const calculateSiteDuration = useCallback((eventId, siteId) => {
    const event = userEvents.find(event => event.id === eventId);
    const site = event?.sites.find(site => site.id === siteId);

    if (!site) {
      return { totalDays: 0, daysRemaining: 0 };
    }

    const startDate = new Date(site.startDate.seconds * 1000);
    const endDate = new Date(site.endDate.seconds * 1000);

    const totalDays = differenceInCalendarDays(endDate, startDate) + 1;
    const daysRemaining = differenceInCalendarDays(endDate, new Date()) + 1;

    return { totalDays: totalDays, daysRemaining: daysRemaining };
  }, [userEvents]);

  const calculateMediaDuration = useCallback((media) => {
    if (!media) {
      return { totalDays: 0, daysRemaining: 0 };
    }

    const startDate = new Date(media.startDate.seconds * 1000);
    const endDate = new Date(media.endDate.seconds * 1000);
    const formattedStartDate = startDate.toLocaleDateString();
    const formattedEndDate = endDate.toLocaleDateString();

    const totalDays = differenceInCalendarDays(endDate, startDate) + 1;
    let daysRemaining = differenceInCalendarDays(endDate, new Date()) + 1;
    if(daysRemaining < 0){
      daysRemaining = 0;
    }

    return { totalDays: totalDays, daysRemaining: daysRemaining, startDate: formattedStartDate, endDate: formattedEndDate }

  }, []);

  const saveActivity = async (activity) => {
    try {
      const selectedEvent = getSelectedEvent();
      if (!selectedEvent) {
        showMessage("No event selected", "error");
        return;
      }
  
      const activities = selectedEvent.activities || [];
      const activityIndex = activities.findIndex(act => act.id === activity.id);
      
      if (activityIndex > -1) {
        activities[activityIndex] = activity;
      } else {
        activities.push(activity);
      }
  
      const updatedEvent = { ...selectedEvent, activities };
  
      await updateFirestoreData('event', selectedEvent.id, { activities: updatedEvent.activities }, showMessage, "Activity saved successfully");
  
      setUserEvents(prevEvents => prevEvents.map(evt => 
        evt.id === selectedEvent.id ? updatedEvent : evt
      ));
  
      showMessage("Activity saved successfully.", "success");
    } catch (error) {
      console.error("Error saving activity:", error);
      showMessage("Failed to save activity.", "error");
    }
  };

  const getPublicTags = async () => {
    try {
      const publicTag = await fetchDocumentData('tags', 'public');
      if (!publicTag) {
        console.error('No public tags found.');
        return null;
      }
      return publicTag;
    } catch (error) {
      console.error("Error fetching the public tags:", error);
      return null;
    }
  };

  const getEventStats = async (eventstatsId) => {
    try {
      const eventStats = await fetchDocumentData('eventstats', eventstatsId);
      if (!eventStats) {
        console.error('No stats found.');
        return null;
      }
      return eventStats;
    } catch (error) {
      console.error("Error fetching the public tags:", error);
      return null;
    }
  }

  return  { getEvents, userEvents, 
            getSelectedEvent, getSelectedSite, getSelectedMedia,
            selectItem, updateItem, removeItem,
            addNewEvent, addNewSite, calculateEventDuration,
            calculateSiteDuration, calculateMediaDuration, saveActivity, getPublicTags, getEventStats,
            getUserAccountDetails,
            viewportType, setViewportType, mapLoadingStatus,
            setActiveComponent, transformSpace, viewType, setViewType,
            setMapLoadingStatus, cameraPosition, setCameraPosition, cameraRotation,
            setCameraRotation, transformMode, setTransformMode, setTransformSpace,
            mediaRemoved, setCachedAssetRemoved, cartesianEditor, setCartesianEditor
          };
}

export default useEditorManagement