import { useCallback, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { createStripeCustomer, fetchActiveProducts } from '../Utilities/firestoreUtils';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useMediaManagement from '../Hooks/UseMediaManagement';

import { UserContext } from '../Contexts/UserContext';
import { useMessage } from '../Contexts/SystemMessageContext';

import { startFreeTrialUrl, createSubscriptionUrl, cancelSubscriptionUrl, reactivateSubscriptionUrl, getStripeCustomerUrl } from '../Utilities/stripeUrls';

function useSalesManagement() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, setUser, selectedSubscriptionDetails, setSelectedSubscriptionDetails, customerDetails } = useContext(UserContext);
    const { getEventMediaCount } = useMediaManagement();
    const { getUserAccountDetails } = useEditorManagement();

    const { setWaiting, setWaitingMessage } = useMessage();

    const subscriptionDaysRemaining = (subscription) => {
        if (!subscription.startDate) return;
        let startDate;
    
        if (typeof subscription.startDate === 'object' && subscription.startDate.seconds) {
            startDate = new Date(subscription.startDate.seconds * 1000);
        } else if (typeof subscription.startDate === 'string') {
            startDate = new Date(subscription.startDate);
        } else {
            console.error('Invalid or missing startDate format');
            return;
        }
    
        const futureDate = new Date(startDate);
        futureDate.setDate(futureDate.getDate() + 30);  // Assuming 30 days for subscription duration
    
        const today = new Date();
        const differenceInMilliseconds = futureDate - today;
        const days = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
    
        return days;
    };
    
    const getSelectedSubscription = useCallback(() => {
        return selectedSubscriptionDetails;
      }, [selectedSubscriptionDetails]);
    
    const setSelectedSubscription = useCallback((subscription) => {
        setSelectedSubscriptionDetails(subscription);
    } , [setSelectedSubscriptionDetails]);

    const getSubscriptionDetails = async (subscription, event) => {
        const mediaUsed = await getEventMediaCount(event.id);
        const siteCountIncluded = parseInt(subscription?.siteCount) + 1 || 0;
        const sitesUsed = event?.sites?.length || 0;
        const siteCount = sitesUsed +'/'+ siteCountIncluded;
    
        const mediaCountIncluded = parseInt(subscription?.mediaCount) || 0;
        const mediaCount = mediaUsed +'/'+ mediaCountIncluded;
    
        const activitiesCountIncluded = parseInt(subscription?.activityCount) || 0;
        const activitiesUsed = event?.activities?.length || 0;
        const activityCount = activitiesUsed +'/'+ activitiesCountIncluded;

        const totalDays = 30;
        const startDate = new Date(subscription?.startDate.seconds * 1000);
        const endDate = new Date(startDate);
        
        endDate.setDate(startDate.getDate() + totalDays);

        const subscriptionDetails = {
            subscriptionId: subscription?.subscriptionId,
            status: subscription?.status,
            recurring: subscription?.recurring,
            trial: subscription?.trial,
            event: subscription?.event,
            siteCount,
            mediaCount,
            activityCount,
            startDate: subscription?.startDate,
            priceId: subscription?.priceId,
            status: subscription?.status,
            daysRemaining: subscriptionDaysRemaining(subscription),
            totalDays: totalDays,
            endDate: endDate.toLocaleDateString(),
        }
        return subscriptionDetails;
    }

    //stripe
    const createtStripeCustomer = async () => {
    try {
        const stripeCustomer = await createStripeCustomer(user);
        return stripeCustomer;
    } catch (error) {
        console.error("Error fetching the stripe customer:", error);
        return null;
    }
    }

    const getStripeCustomer = async (customerId) => {
        try {
          const response = await fetch(getStripeCustomerUrl, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ customerId })
          });
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          return data;
        } catch (error) {
            console.error("Error fetching the stripe customer:", error);
            return null;
        }
    }

    const getProductList = async () => {
    try {
        const products = await fetchActiveProducts();
        return products;
    } catch (error) {
        console.error("Error fetching the public tags:", error);
        return null;
    }
    }

    const createStripeSubscription = async ( selectedProduct, stripe, formData, cardNumberElement, setError, changeCard, newUserData ) => {
        let started = true;
        if (user.trial.started) {
          started = false;
        }
    
        let paymentMethodId;
        if (!customerDetails.customer.invoice_settings.default_payment_method || changeCard) {
            const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardNumberElement,
        });

        if (stripeError) {
            setError(stripeError.message);
            return;
        }
    
        paymentMethodId = paymentMethod.id;
        } else {
          paymentMethodId = customerDetails.customer.invoice_settings.default_payment_method;
        }
    
        const priceId = selectedProduct.prices[0].id;

        try {
          const response = await fetch(createSubscriptionUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                stripeCustomerId: customerDetails.customer.id,
                priceId,
                paymentMethodId,
                userId:user.uid,
                event: null,
                siteCount: selectedProduct.stripe_metadata_sites,
                mediaCount: selectedProduct.stripe_metadata_media,
                activityCount: selectedProduct.stripe_metadata_activities,
                newUserData: newUserData,
                trial: started,
                days: selectedProduct.stripe_metadata_trialDays,
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                address: formData.address,
                city: formData.city,
                state: formData.state,
                zip: formData.zip,
                country: formData.country,
                setDefaultCard: formData.setDefaultCard,
            }),
          });

          const subscriptionData = await response.json();
          if (response.ok) {
            const userAccount = await getUserAccountDetails();
            setUser(userAccount);
          } else {
            throw new Error(subscriptionData.message || 'Failed to create subscription');
          }
          if (location.pathname === '/subscriptions') {
            navigate('/user-account');
          }
    
        } catch (error) {
          console.error('Error creating subscription:', error);
          setError(error.message || 'Failed to create subscription');
        }
    }

    const createStripeTrialSubscription = async (selectedProduct) => {
        const priceId = selectedProduct.prices[0].id;
        const trialPeriodDdays = selectedProduct.stripe_metadata_trialDays;
        const siteCount = selectedProduct.stripe_metadata_sites;
        const mediaCount = selectedProduct.stripe_metadata_media;
        const activityCount = selectedProduct.stripe_metadata_activities;
        const event = null;

        const body = JSON.stringify({
            stripeCustomerId: user.stripeCustomerId,
            priceId,
            trialPeriodDdays,
            userId: user.uid,
            event,
            siteCount, mediaCount, activityCount,
        });

        try {
            const response = await fetch(startFreeTrialUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: body,
            });

            const subscriptionData = await response.json();

            if (response.ok) {
                const userAccount = await getUserAccountDetails();
                setUser(userAccount);
                if (location.pathname === '/subscriptions') {
                    navigate('/user-account');
                }
                return subscriptionData;
            } else {
                throw new Error(subscriptionData.message || 'Failed to start trial subscription');
            }
        } catch (error) {
            console.error('Error starting trial subscription:', error);
            throw error;
        }
    }

    const cancelStripeSubscription = async(userId, subscriptionId, setUser) => {
        try {
        setWaiting(true);
        setWaitingMessage('Canceling subscription...');
        const response = await fetch(cancelSubscriptionUrl, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId, subscriptionId }),
        });
        
        const result = await response.json();
        
        if (response.ok) {
            setUser(prevUser => {
                const updatedSubscriptions = prevUser.subscriptions.map(sub => {
                    if (sub.subscriptionId === subscriptionId) {
                        return { ...sub, recurring: false };
                    }
                    return sub;
                });
            
                return {
                    ...prevUser,
                    subscriptions: updatedSubscriptions
                };
            });
            setSelectedSubscriptionDetails(prevDetails => {
                if (!prevDetails) return null;
            
                return { ...prevDetails, recurring: false };
            });
        } else {
            throw new Error(result.message || 'Failed to cancel subscription');
        }
        setWaiting(false);
        setWaitingMessage('');
        } catch (error) {
        console.error('Error canceling subscription:', error);
        }
    }
    
    const reactivateStripeSubscription = async(userId, subscriptionId) => {
        try {
            setWaiting(true);
            setWaitingMessage('Reactivating subscription...');
            const response = await fetch(reactivateSubscriptionUrl, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId, subscriptionId }),
            });
            const result = await response.json();
            
            if (response.ok) {
                setUser(prevUser => {
                    const updatedSubscriptions = prevUser.subscriptions.map(sub => {
                        if (sub.subscriptionId === subscriptionId) {
                            return { ...sub, recurring: true };
                        }
                        return sub;
                    });
                
                    return {
                        ...prevUser,
                        subscriptions: updatedSubscriptions
                    };
                });
                setSelectedSubscriptionDetails(prevDetails => {
                    if (!prevDetails) return null;
                
                    return { ...prevDetails, recurring: true };
                });
            } else {
                throw new Error(result.message || 'Failed to cancel subscription');
            }
            setWaiting(false);
            setWaitingMessage('');
        } catch (error) {
        console.error('Error canceling subscription:', error);
        }
    }

    return  { 
        getProductList, getStripeCustomer, createtStripeCustomer,
        createStripeSubscription, createStripeTrialSubscription,
        subscriptionDaysRemaining, 
        setSelectedSubscription, getSelectedSubscription, getSubscriptionDetails,
        cancelStripeSubscription, reactivateStripeSubscription
        
    };
}

export default useSalesManagement