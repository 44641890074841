import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Link, Routes, Route, Navigate } from 'react-router-dom';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import Modal from './Controls/Modal';
import SystemWaiting from './Controls/SystemWaiting';

import { SystemMessageProvider } from './Contexts/SystemMessageContext';
import { ModalProvider } from './Contexts/ModalContext';
import { UserProvider, UserContext } from './Contexts/UserContext';
import { MediaProvider } from './Contexts/MediaContext';
import { GoogleMapProvider } from './Contexts/GoogleMapsContext';
import { EditorProvider } from './Contexts/EditorContext';

import UserAccount from './Components/UserAccount';
import UserControls from './Components/UserControls';
import BetaAccess from './Components/BetaAccess';

import HomePage from './Pages/HomePage';
import Join from './Pages/Join';
import Login from './Pages/Login';
import AccountDetailsPage from './Pages/AccountDetailsPage';
import CreateWizzard from './Pages/CreateWizzard';
import AccessibilityStatement from './Pages/AccessibilityStatement';
import CookiePolicy from './Pages/CookiePolicy';
import Disclaimer from './Pages/Disclaimer';
import DMCAPolicy from './Pages/DMCAPolicy';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import TermsOfService from './Pages/TermsOfService';
import Subscriptions from './Pages/Subscriptions';
import Help from './Pages/Help';

import './App.scss';

function App() {
  const key = "pk_test_51OtCl7BlIgp0SE831JGSV3rL9dXXqW4QkbCH9OGZwSHpxEuu3tewq0WcCHznPmqbKuKPDmJHHv6O3ISj9tvIlCUI00NAye6i84"
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    loadStripe(key)
      .then(resolvedStripe => {
        setStripe(resolvedStripe);
      });
  }, []);

  if (!stripe) {
    return <div>Loading...</div>;
  }

  return (
    <Elements  stripe={stripe}>
      <SystemMessageProvider>
        <ModalProvider>
          <UserProvider>
              <MediaProvider>
                <GoogleMapProvider>
                  <EditorProvider>
                      <BrowserRouter>
                        <div id='app' className='app hero-background'>
                          <div className='nav-container'>
                            <nav className='nav'>
                              <Link to='/homepage'><div className='title'>Cylandar</div></Link>
                              <UserContext.Consumer>
                                {({ user }) => (
                                  <div className='nav-user-controls'>
                                    <Link to='/user-account'><div className=''>{(user != null && user.Subscriptions > 0) ? 'Your Events':''}</div></Link>
                                    <UserControls user={user} />
                                  </div>
                                )}
                              </UserContext.Consumer>
                            </nav>
                          </div>
                          <div id='page-container' className='page-container'>
                            <Routes>
                              <Route path='/login' element={<Login />} />
                              <Route path='/join' element={<StartJoin />} />
                              <Route path='/create' element={<CreateWizzard />} />
                              <Route path='/' element={<MainPage />} />
                              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                              <Route path='/terms-of-service' element={<TermsOfService />} />
                              <Route path='/cookie-policy' element={<CookiePolicy />} />
                              <Route path='/disclaimer' element={<Disclaimer />} />
                              <Route path='/dmca-policy' element={<DMCAPolicy />} />
                              <Route path='/accessibility-statement' element={<AccessibilityStatement />} />
                              <Route path='/user-account' element={<UserAccount />} />
                              <Route path='/homepage' element={<HomePage />} />
                              <Route path='/accountdetailspage' element={<AccountDetailsPage />} />
                              <Route path='/subscriptions' element={<Subscriptions />} />
                              <Route path='/help' element={<Help />} />                              
                            </Routes>
                          </div>
                          <div className='footer-container'>
                            <Footer />
                          </div>
                        </div>
                        <Modal />
                        <SystemWaiting />
                        <BetaAccess />
                      </BrowserRouter>
                  </EditorProvider>
                </GoogleMapProvider>
              </MediaProvider>
          </UserProvider>
        </ModalProvider>
      </SystemMessageProvider>
    </Elements>
  );
}

function MainPage() {
  const { user } = useContext(UserContext);
  return user ? <Navigate to='/user-account' /> : <HomePage />;
}

function StartJoin() {
  const { user } = useContext(UserContext);
  return user ? <Navigate to='/user-account' /> : <Join />;
}

const Footer = () => {
  return (
      <footer className='footer'>
          <div> © 2024 Cylandar LLC. All rights reserved.</div>
          <Link to='/privacy-policy'>Privacy Policy</Link>
          <Link to='/terms-of-service'>Terms of Service</Link>
          <Link to='/cookie-policy'>Cookie Policy</Link>
          <Link to='/disclaimer'>Disclaimer</Link>
          <Link to='/dmca-policy'>DMCA Policy</Link>
          <Link to='/accessibility-statement'>Accessibility Statement</Link>
          <Link to='/help'>Help</Link>
      </footer>
  );
};

export default App;
