import React, { useState, useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useMediaManagement from '../Hooks/UseMediaManagement';
import useSalesManagement from '../Hooks/UseSalesManagement';

import { GoogleMapsContext } from '../Contexts/GoogleMapsContext';
import { EditorContext } from '../Contexts/EditorContext';
import { UserContext } from '../Contexts/UserContext';
import { useMessage } from '../Contexts/SystemMessageContext';

import MediaLibrary from '../Components/MediaLibrary';

import SystemWaiting from '../Controls/SystemWaiting';

import './CreateWizzard.scss';
import '../Controls/Modal.scss';

const CreateWizzard = () => {
    const { user } = useContext(UserContext);
    const { setSelectedSubscription } = useSalesManagement();    
    const navigate = useNavigate();
    const location = useLocation();
    const { setWaiting, setWaitingMessage } = useMessage();

    const { addNewEvent, addNewSite, selectItem, setActiveComponent } = useEditorManagement();


    const { getMediaLibrary } = useMediaManagement();

    const type = location.state.type || 'event';
    const subscriptionId = location.state.subscriptionId || null;

    let startPage = type === 'event' ? 1 : 2;

    const [ currentStep, setCurrentStep ] = useState(startPage);
    const [ eventDetails, setEventDetails ] = useState(null);
    const [ siteDetails, setSiteDetails ] = useState(null);
    const [ save, setSave ] = useState(false);
    const [ creating,  ] = useState(false);

    useEffect(() => {
        if (!user) {
            navigate('/');
        }
    }, [user]);

    useEffect(() => {
        if (save && (eventDetails !== null || siteDetails !== null)){
            const saveItem = async() => {
                await create();
                await getMediaLibrary()
                nextStep();
            }
            saveItem();
        }
    }, [save]);

    const nextStep = () => {
        if (currentStep < formPages.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const create = async() => {
        setWaiting(true);
        if (type === 'event'){ 
            setWaitingMessage('Canceling Event...');
            const newEvent = await addNewEvent(subscriptionId, eventDetails, siteDetails);
            selectItem({itemType: 'event', id: newEvent.id});
            selectItem({itemType: 'site', id: newEvent.sites[0]});
        } else if (type === 'site') {
            setWaitingMessage('Canceling Site...');
            const newSite = await addNewSite(siteDetails);
            selectItem({itemType: 'site', id: newSite});
        }
        setWaiting(false);
        setWaitingMessage('');

        setActiveComponent('editor');
        const subscription = user.subscriptions.find(sub => sub.subscriptionId === subscriptionId);
        setSelectedSubscription(subscription);
    }

    const finish = () => {
        navigate('/user-account');
    }

    const formPages = [
        <WizzardWelcome nextStep={nextStep} finish={finish}/>,
        <NewEventDetails prevStep={prevStep} nextStep={nextStep} eventDetails={eventDetails} setEventDetails={setEventDetails} finish={finish}/>,
        <NewSiteDetails prevStep={prevStep} siteDetails={siteDetails} setSiteDetails={setSiteDetails} setSave={setSave} type={type} finish={finish}/>,
        <AddMedia finish={finish}/>,
    ];

    return (
    <div className='create-wizard panel'>
        { formPages[currentStep] }
    </div>
    );
};

export default CreateWizzard;

const WizzardWelcome = ({nextStep, finish}) => {
    const ctaButtonText = 'Sart a new Event';
    
    return (
        <div className='welcome-screen'>
            <h1>Welcome to the 3D Extended Reality Platform!</h1>
            <p>
                We're excited to have you onboard. This platform enables you to build immersive,
                interactive 3D extended reality experiences. With our intuitive editor, you can
                place your content at any location in the world and share your creations with
                anyone, anywhere.
            </p>
            <p>
                Start your journey by creating an event. This will be your first step towards
                bringing your ideas to life in a whole new dimension. Let's create something
                amazing together!
            </p>
            <div>
                <p>Ready to begin? Start by creating your first event below:</p>
            </div>
            <div className='create-wizard-navigation'>
                <button onClick={finish}>Cancel</button>
                <button onClick={nextStep}>{ctaButtonText}</button>
            </div>
        </div>
    );
};

const NewEventDetails = ({ prevStep, nextStep, eventDetails, setEventDetails, finish }) => {
    const { userEvents } = useContext(EditorContext);
    const ctaButtonText = 'Finish Event Details';
    let placeholderName = 'Enter New Event Name';
    let placeholderDescription = 'Add a description of the New Event';

    const [eventName, setEventName] = useState(eventDetails?.eventName || '');
    const [eventDescription, setEventDescription] = useState(eventDetails?.eventDescription || '');
    const [eventIcon, setEventIcon] = useState(eventDetails?.eventIcon || null);
    const [previewUrl, setPreviewUrl] = useState(eventDetails?.previewUrl || null);
    const [showFileInput, setShowFileInput] = useState(!eventDetails?.previewUrl);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => { 
        handleValidation('eventIcon');
    }, [previewUrl]);

    const handleValidation = (field) => {
        let errors = { ...formErrors };
        switch (field) {
            case 'eventName':
                if (eventName === placeholderName || eventName.trim() === '') {
                    errors.eventName = 'Please enter a valid event name.';
                } else if (userEvents.map(event => event.name).includes(eventName.trim())) {
                    errors.eventName = 'Event name already exists.';
                } else if (userEvents.map(event => event.name).includes(eventName.trim())) {
                    errors.eventName = 'Event name already exists.';
                } else {
                    delete errors.eventName;
                }
                break;
            case 'eventDescription':
                if (eventDescription === placeholderDescription || eventDescription.trim() === '') {
                    errors.eventDescription = 'Please enter a valid event description.';
                } else {
                    delete errors.eventDescription;
                }
                break;
            case 'eventIcon':
                if (!previewUrl) {
                    errors.eventIcon = 'Please upload an event icon.';
                } else {
                    delete errors.eventIcon;
                }
                break;
            default:
                break;
        }
        setFormErrors(errors);
    };

    const handleIconChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setEventIcon(file);
                setPreviewUrl(reader.result);
                setShowFileInput(false);
                handleValidation('eventIcon');
            };
            reader.readAsDataURL(file);
        }
    };
    
    const handleChangeIconClick = () => {
        setShowFileInput(true);
        handleValidation('eventIcon');
    };
    
    const setDeails = () => {
        const details = {
            active: true,
            eventName,
            eventDescription,
            eventIcon,
            previewUrl,
        };
        setEventDetails(details);
    }
    const handleBack = (e) => { 
        e.preventDefault();
        setDeails();
        prevStep();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(formErrors).length === 0 && eventName && eventDescription && eventIcon) {
            setDeails();
            nextStep();
        } else {
            handleValidation('eventName');
            handleValidation('eventDescription');
            handleValidation('eventIcon');
        }
    };

    const handleEventNameChange = (e) => {
        setEventName(e.target.value);
        handleValidation('eventName');
    };

    const handleEventDescriptionChange = (e) => {
        setEventDescription(e.target.value);
        handleValidation('eventDescription');
    };

    const isFormValid = () => Object.keys(formErrors).length === 0 && eventName && eventDescription && eventIcon;

    return (
        <div className='create-wizard-event'>
            <h1>Create Your Event</h1>
            <p>Each event serves as a starting point for your extended reality experience. Provide a name, a description, and a graphic icon for your event. These details will be visible to users of the mobile app, helping them understand and engage with your event.</p>
            
            <form className='create-wizard-event-form' onSubmit={handleSubmit}>
                <div className='create-wizard-event-form-text'>
                    <div className='create-wizard-event-form-group'>
                        <label htmlFor='eventName'>Event Name</label>
                        <input
                            type='text'
                            id='eventName'
                            placeholder={placeholderName}
                            value={eventName}
                            onChange={handleEventNameChange}
                            onBlur={() => handleValidation('eventName')}
                            required
                        />
                        {formErrors.eventName && <p className='error'>{formErrors.eventName}</p>}
                    </div>
                    
                    <div className='create-wizard-event-form-group'>
                        <label htmlFor='eventDescription'>Event Description</label>
                        <textarea
                            id='eventDescription'
                            placeholder={placeholderDescription}
                            value={eventDescription}
                            onChange={handleEventDescriptionChange}
                            onBlur={() => handleValidation('eventDescription')}
                            required
                        ></textarea>
                        {formErrors.eventDescription && <p className='error'>{formErrors.eventDescription}</p>}
                    </div>
                </div>
                <div className='create-wizard-event-form-icon'>
                    {previewUrl && (
                        <img src={previewUrl} alt='Missing Icon' style={{maxWidth: '100%', maxHeight: '200px'}} />
                    )}
                    {formErrors.eventIcon && <p className='error'>{formErrors.eventIcon}</p>}
                    {showFileInput ? (
                        <div className='create-wizard-event-form-group'>
                            <label htmlFor='eventIcon' className='fileInputLabel'>Select Event Icon</label>
                            <input
                                type='file'
                                id='eventIcon'
                                onChange={handleIconChange}
                                onLoad={() => handleValidation('eventIcon')}
                                accept='image/*'
                                style={{display: 'none'}}
                            />
                            </div>
                        ) : (
                            <>
                                <button type='button' onClick={handleChangeIconClick}>Change Icon</button>
                            </>
                        )}
                </div>
                
            </form>

            <div className='create-wizard-navigation'>
                <button onClick={finish}>Cancel</button>
                <button onClick={handleBack}>Back</button>
                <button onClick={handleSubmit} disabled={!isFormValid()}>{ctaButtonText}</button>
            </div>
        </div>
    );
};

const NewSiteDetails = ({ prevStep, siteDetails, setSiteDetails, setSave, type, finish }) => {
    const { getSelectedEvent } = useEditorManagement();

    const ctaButtonText = 'Finsih Site Details';
    let placeholderName = 'Enter new Site name';
    let placeholderDescription = 'Describe the new Site';

    const now = new Date();
    let start = siteDetails && siteDetails.startDateTime ? new Date(siteDetails.startDateTime) : new Date(now.getTime() + 24 * 60 * 60 * 1000);
    let end = siteDetails && siteDetails.endDateTime ? new Date(siteDetails.endDateTime) : new Date(start.getTime() + 30 * 24 * 60 * 60 * 1000);
    
    if (siteDetails) {
        start = new Date(siteDetails.startDateTime);
        end = new Date(siteDetails.endDateTime);
    }

    const { isLoaded } = useContext(GoogleMapsContext);
    const [ siteName, setSiteName ] = useState(siteDetails?.siteName || '');
    const [ siteDescription, setSiteDescription ] = useState(siteDetails?.siteDetails || '');
    const [ siteAddress, setSiteAddress ] = useState(siteDetails?.siteAddress || '');
    const [ isAddressValid, setIsAddressValid ] = useState(false);
    const [ siteIcon, setSiteIcon ] = useState(siteDetails?.siteIcon || '');
    const [ previewUrl, setPreviewUrl ] = useState(siteDetails?.previewUrl || '');
    const [ showFileInput, setShowFileInput ] = useState(!previewUrl);
    const [ startDateTime, setStartDateTime ] = useState(start);
    const [ endDateTime, setEndDateTime ] = useState(end);
    const [ formErrors, setFormErrors ] = useState({});

    const autocompleteRef = useRef(null);

    useEffect(() => {
        if (isLoaded && !autocompleteRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(
                document.getElementById('siteAddress'),
                { types: ['geocode'] }
            );
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                if (!place.geometry || !place.geometry.location) {
                    setIsAddressValid(false);
                    return;
                }
            
                const addressComponents = place.address_components;
                const getAddressComp = (type) => {
                    const comp = addressComponents.find(comp => comp.types.includes(type));
                    return comp ? comp.long_name : '';
                };
                const structuredAddress = {
                    street: `${getAddressComp('street_number')} ${getAddressComp('route')}`.trim(),
                    city: getAddressComp('locality'),
                    state: getAddressComp('administrative_area_level_1'),
                    postalCode: getAddressComp('postal_code'),
                    country: getAddressComp('country'),
                    coordinates: {
                        latitude: place.geometry.location.lat(),
                        longitude: place.geometry.location.lng(),
                    },
                };
            
                setSiteAddress(structuredAddress);
                setIsAddressValid(true);
            });            
        }
    }, [isLoaded]);

    useEffect(() => {
        if (siteDetails) {
            setSiteName(siteDetails.siteName || placeholderName);
            setSiteDescription(siteDetails.siteDescription || placeholderDescription);
            setSiteAddress(siteDetails.siteAddress || '');
            setSiteIcon(siteDetails.siteIcon);
            setPreviewUrl(siteDetails.previewUrl);
            setStartDateTime(new Date(siteDetails.startDateTime || start));
            setEndDateTime(new Date(siteDetails.endDateTime || end));
            setIsAddressValid(!!siteDetails.siteAddress);
        }
    }, [siteDetails]);

    const handleAddressChange = (e) => {
        setSiteAddress(e.target.value);
        setIsAddressValid(false);
    };

    const handleIconChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSiteIcon(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
                setShowFileInput(false);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleChangeIconClick = () => {
        setShowFileInput(true);
    };

    const isDateTimeValid = () => {
        const minimumDuration = 60 * 60 * 1000;
        const currentDateTime = new Date();
        const startDateTimeObj = new Date(startDateTime);
        
        let isValid = true;
        if(startDateTimeObj < currentDateTime) {
            isValid = false;
        } else if (endDateTime <= startDateTimeObj) {
            isValid = false;
        } else if (endDateTime.getTime() - startDateTimeObj.getTime() < minimumDuration) {
            isValid = false;
        }
        return isValid;
    };

    const handleBack = (e) => {
        e.preventDefault();
        saveDetails();
        prevStep();
    };

    const saveDetails = () => {
        const details = {
            siteName,
            siteDescription,
            siteAddress,
            siteIcon,
            startDateTime: startDateTime.toISOString(),
            endDateTime: endDateTime.toISOString(),
            previewUrl
        };
        setSiteDetails(details);
    }

    const handleValidation = (field) => {
        let errors = { ...formErrors };
        switch (field) {
            case 'siteName':
                if (!siteName || siteName === placeholderName) {
                    errors.siteName = 'Please enter a valid site name.';
                } else {
                    if(type === 2) {
                        const existingSites = getSelectedEvent().sites || [];
                        const isNameTaken = existingSites.some(site => site.name.toLowerCase() === siteName.toLowerCase());
                        
                        if (isNameTaken) {
                            errors.siteName = 'Site name already exists. Please choose a different name.';
                        } else {
                            delete errors.siteName;
                        }
                    }
                }
                break;
            case 'siteDescription':
                if (!siteDescription || siteDescription === placeholderDescription) {
                    errors.siteDescription = 'Please enter a valid site description.';
                } else {
                    delete errors.siteDescription;
                }
                break;
            case 'siteAddress':
                if (!isAddressValid) {
                    errors.siteAddress = 'Please select a valid address from the suggestions.';
                } else {
                    delete errors.siteAddress;
                }
                break;
            case 'siteIcon':
                if (!siteIcon) {
                    errors.siteIcon = 'Please upload a site icon.';
                } else {
                    delete errors.siteIcon;
                }
                break;
        }
        setFormErrors(errors);
    };

    useEffect(() => {
        handleValidation('startDateTime');
        handleValidation('endDateTime');
    }, [startDateTime, endDateTime]);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleValidation('siteName');
        handleValidation('siteDescription');
        handleValidation('siteAddress');
        handleValidation('siteIcon');
    
        if (Object.keys(formErrors).length === 0 && isFormCurrentlyValid()) {
            saveDetails();
            setSave(true);
        }
    };

    const isFormCurrentlyValid = () => {
        const hasErrors = Object.keys(formErrors).length > 0;
    
        const fieldsAreValid = siteName && siteDescription && isAddressValid && siteIcon && isDateTimeValid();
        return !hasErrors && fieldsAreValid;
    };

    return (
        <div className='create-wizard-site'>
            <h1>Create New Site</h1>
            <p>A Site is the location where your content will be placed. Sites have a start and end datetime, and use an address and GPS coordinates to define the location and size of the site. These details help users of the mobile app to find and engage with your content in the real world.</p>
            
            <form className='create-wizard-site-form' onSubmit={handleSubmit}>
                <div className='create-wizard-site-form-text'>
                    <div className='create-wizard-site-form-group'>
                        <label htmlFor='siteName'>Site Name</label>
                        <input
                            type='text'
                            id='siteName'
                            placeholder={placeholderName}
                            value={siteName}
                            onChange={(e) => setSiteName(e.target.value)}
                            onBlur={() => handleValidation('siteName')}
                            required
                        />
                    </div>
                    {formErrors.siteName && <p className='error'>{formErrors.siteName}</p>}
                    <div className='create-wizard-site-form-group'>
                        <label htmlFor='siteDescription'>Site Description</label>
                        <textarea
                            id='siteDescription'
                            placeholder={placeholderDescription}
                            value={siteDescription}
                            onChange={(e) => setSiteDescription(e.target.value)}
                            onBlur={() => handleValidation('siteDescription')}
                            required
                        ></textarea>
                    </div>
                    {formErrors.siteDescription && <p className='error'>{formErrors.siteDescription}</p>}
                </div>
                
                <div className='create-wizard-site-form-group'>
                    <label htmlFor='siteAddress'>Site Address</label>
                    <input
                        type='text'
                        id='siteAddress'
                        value={typeof siteAddress === 'object' ? `${siteAddress.street}, ${siteAddress.city}, ${siteAddress.state}, ${siteAddress.postalCode}, ${siteAddress.country}` : siteAddress}
                        onChange={handleAddressChange}
                        required
                    />
                </div>
                {formErrors.siteAddress && <p className='error'>{formErrors.siteAddress}</p>}
                <div className='create-wizard-site-form-group'>
                    {showFileInput ? (
                        <>
                            <label htmlFor='siteIcon' className='fileInputLabel'>Select Site Icon</label>
                            <input
                                type='file'
                                id='siteIcon'
                                onChange={handleIconChange}
                                accept='image/*'
                                style={{display: 'none'}}
                            />
                        </>
                    ) : (
                        <>
                            <button type='button' onClick={handleChangeIconClick}>Change Icon</button>
                        </>
                    )}
                    {previewUrl && (
                        <img src={previewUrl} alt='Site Icon Preview' style={{maxWidth: '100%', maxHeight: '200px'}} />
                    )}
                </div>
                {formErrors.siteIcon && <p className='error'>{formErrors.siteIcon}</p>}

                <div className='create-wizard-site-form-group'>
                    <label htmlFor='startDateTime'>Start Date and Time</label>
                    <input
                        type='datetime-local'
                        id='startDateTime'
                        value={startDateTime.toISOString().slice(0, 16)}
                        onChange={(e) => setStartDateTime(new Date(e.target.value))}
                        required
                    />
                </div>

                <div className='create-wizard-event-date'>
                    <label htmlFor='endDateTime'>End Date and Time</label>
                    <input
                        type='datetime-local'
                        id='endDateTime'
                        value={endDateTime.toISOString().slice(0, 16)}
                        onChange={(e) => setEndDateTime(new Date(e.target.value))}
                        required
                    />
                </div>


            </form>
            <div className='create-wizard-navigation'>
                    <button onClick={finish}>Cancel</button>
                    {
                        type !== 2 && <button type='button' onClick={handleBack}>Back</button>
                    }
                    <button type='submit' onClick={handleSubmit} disabled={!isFormCurrentlyValid()}>{ctaButtonText}</button>
                </div>
        </div>
    );
};

const AddMedia = ({ finish }) => {
    const { getSelectedSite } = useEditorManagement();
    const { addMediaToSite } = useMediaManagement();

    const [ selectedSite, setSelectedSite] = useState(getSelectedSite());

    const ctaButtonText = 'Finish Adding Media';

    useEffect(() => {
        setSelectedSite(getSelectedSite());
    }, [addMediaToSite]);
    
    return (
        <div className='create-wizard-medialibrary-container'>
            <div>Place Media</div>
            <div>Now add media to the site. This could be a 3D model, an image, a video, or an audio file. Once uploaded, you can place the media at the site. You will be able to set media properties, such as its size, orientation, and interactivity in the editor after this step.</div>
            <div className='create-wizard-medialibrary'>
                <MediaLibrary addingSite={true}/>
            </div>
            <div>You can always add more media anytime in the future.</div>
            <div className='create-wizard-navigation'>
                { selectedSite.media.length > 0 && <button onClick={finish}>{ctaButtonText}</button>}
            </div>
        </div>
    );
};

