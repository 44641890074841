// PrivacyPolicy.js
import React from 'react';

import './LegalPage.scss';

const PrivacyPolicy = () => {
  return (
    <div className='panel'>
      <div className='legal-page'>
        <h1>Privacy Policy for Cylandar</h1>

        <h2>Introduction</h2>
        <p>Cylandar is a location-based augmented reality platform providing a service that allows users to create and participate in augmented reality Events. These Events, designed by users, can include various Activities like quests or tours, enhanced by geofenced Sites containing multimedia content such as images, audio, video, and 3D meshes. Our platform consists of a website for account creation, subscription purchases, and Event management, alongside a mobile application that displays the augmented reality content in real-world locations. At Cylandar, we prioritize the privacy of our users, understanding the sensitive nature of the geolocation and multimedia data we handle. We are committed to protecting your personal information and ensuring transparency about the data we collect, how we use it, and the measures we take to secure it.</p>

        <h2>Information Collection</h2>
        <h3>Types of information collected:</h3>
        <ul>
          <li><strong>Personal Information:</strong> We collect personal details such as names, email addresses, and contact information when you create an account on our website. This information facilitates account management and user support.</li>
          <li><strong>Geolocation Data:</strong> To provide our augmented reality services, we collect precise location data to determine when your mobile device is within the vicinity of a Site, enabling us to display the relevant Event’s content appropriately.</li>
          <li><strong>Multimedia Content:</strong> Users can upload and manage various forms of media, including images, audio files, videos, and 3D meshes, which are essential to creating the immersive experiences our platform offers.</li>
          <li><strong>Activity Data:</strong> We track how users interact with Events and Activities, such as participation in quests or tours, to improve and tailor the experiences offered by Cylandar.</li>
          <li><strong>Analytics Data:</strong> Using analytics tools integrated with our website editor, we gather data on how users engage with the content created by our community, aiding in the continuous improvement of our service.</li>
        </ul>
        <h3>Methods of data collection:</h3>
        <p>We collect information through various means, primarily via our website and mobile application. When you interact with our services, such as by creating an account, purchasing subscriptions, uploading content, or participating in Events, we gather the necessary data. Additionally, our platform utilizes Firebase for backend services and data storage, ensuring a secure and efficient data management system. For financial transactions, we partner with Stripe to process payments securely, ensuring that your purchase information is handled with the utmost care and in compliance with industry standards.</p>

        <h2>Use of Information</h2>
        <p>Cylandar utilizes the collected information primarily to deliver and enhance the services we offer. The ways in which we use this data include:</p>
        <ul>
          <li>Creating, Managing, and Participating in Events and Activities: We use the personal, geolocation, and multimedia content data to facilitate the creation and management of Events and Activities on our platform. This enables users to design, deploy, and engage with augmented reality content tailored to specific locations.</li>
          <li>Location-based Services and Augmented Reality Experiences: The geolocation data collected is crucial for providing the core functionality of our augmented reality platform. It allows us to present the right content at the right place, offering users immersive and interactive experiences based on their physical location.</li>
          <li>Account Management, Customer Support, and Communication: Personal information is used for managing user accounts, providing customer support, and communicating important updates or information about our services. This ensures a smooth and efficient user experience and allows us to respond effectively to user inquiries and feedback.</li>
        </ul>

        <h2>Data Sharing and Disclosure</h2>
        <p>Cylandar respects the privacy of our users and is committed to protecting your personal information. The circumstances under which we might share data with third parties include:</p>
        <ul>
          <li>Service Providers: We engage with trusted service providers who perform services on our behalf, such as hosting, data analysis, payment processing, and customer service. These entities are given access to personal information needed to perform their functions but are restricted from using it for other purposes.</li>
          <li>Legal Requirements: We may disclose personal information if required by law, such as in response to a court order or a subpoena, or in connection with a legal investigation. We may also share data to protect the rights, property, or safety of Cylandar, our users, or others.</li>
          <li>Assurance Against Selling Personal Information: Cylandar does not sell, rent, or trade personal information to third parties. Any data sharing is conducted strictly in accordance with this privacy policy and with the aim of improving the service provided to our users.</li>
        </ul>

        <h2>Data Security</h2>
        <p>The security of your data is a top priority for Cylandar. We implement a range of security measures to protect against unauthorized access, alteration, disclosure, or destruction of personal information. These measures include:</p>
        <ul>
          <li>Encryption and Secure Servers: We use encryption technologies to secure data transmissions and store all user data on secure servers. These practices help safeguard your information from unauthorized access and potential breaches.</li>
          <li>User Responsibility: While we take significant steps to protect your data, the security of your account also depends on your actions. We advise users to choose strong passwords, keep them confidential, and be cautious about the information they share in public areas of the platform.</li>
        </ul>
        <p>By maintaining a robust security framework and promoting responsible user behavior, Cylandar strives to protect the integrity and privacy of the data entrusted to us.</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
