// PrivacyPolicy.js
import React from 'react';

import './LegalPage.scss';

const TermsOfService = () => {
  return (
    <div className='panel'>
      <div className='legal-page'>
        <h1>Terms of Service for Cylandar</h1>
        
        <h2>Acceptance of Terms</h2>
        <h3>1. Introduction to Cylandar</h3>
        <p>Cylandar is a location-based augmented reality platform as a service, designed to offer immersive, augmented reality experiences through geofenced events and sites. Users can create, manage, and engage with content in a three-dimensional space correlated to real-world locations.</p>

        <h3>2. Acknowledgment and Acceptance</h3>
        <p>By accessing or using Cylandar's services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service. If you do not agree, you must not use Cylandar's services.</p>

        <h2>User Responsibilities</h2>
        <h3>1. Content Responsibility</h3>
        <p>You are solely responsible for the content you upload to Cylandar, including its legality, reliability, and appropriateness. You must ensure that your content complies with all applicable copyright and legal standards.</p>

        <h3>2. Acceptable Use Policy</h3>
        <p>You agree not to use Cylandar’s services for any purpose that is illegal, harmful, or prohibited by these terms. Prohibited activities include uploading content that is offensive, infringing on intellectual property rights, or otherwise damaging to Cylandar or its users.</p>

        <h2>Account Management</h2>
        <h3>1. Account Creation and Management</h3>
        <p>To access certain features of Cylandar, you must create an account. You are responsible for maintaining the confidentiality of your account details and for all activities conducted through your account.</p>

        <h3>2. Suspension and Termination</h3>
        <p>Cylandar reserves the right to suspend or terminate your account if you violate these Terms of Service. We will provide notice of such actions and an opportunity to appeal, where appropriate.</p>

        <h2>Subscription and Payment</h2>
        <h3>1. Subscription Details</h3>
        <p>Subscriptions to Cylandar services are managed through the website. Users can purchase, renew, and cancel subscriptions as needed. Cancellations will prevent future charges but won’t refund current subscriptions.</p>

        <h3>2. Financial Transactions</h3>
        <p>Payments are processed through Stripe. Cylandar ensures that all financial transactions are secure and comply with industry standards for payment processing.</p>

        <h2>Content and Intellectual Property</h2>
        <h3>1. Content Ownership</h3>
        <p>Users retain ownership of the content they upload to Cylandar but grant Cylandar a license to use, display, and distribute that content in connection with the service.</p>

        <h3>2. Intellectual Property Rights</h3>
        <p>Cylandar respects intellectual property rights and expects users to do the same. We reserve the right to remove content that infringes upon others' intellectual property rights.</p>

        <h2>Privacy and Data Usage</h2>
        <h3>1. Data Collection and Use</h3>
        <p>Cylandar collects personal, geolocation, and multimedia content data to facilitate the creation and management of Events and Activities. This data is essential for delivering our services and enhancing user experiences.</p>

        <h3>2. Privacy Compliance</h3>
        <p>We adhere to stringent data protection laws and ensure that user data is handled securely and responsibly, as detailed in our Privacy Policy.</p>

        <h2>Liability and Disclaimer</h2>
        <h3>1. Limitations of Liability</h3>
        <p>Cylandar is not liable for any direct, indirect, incidental, or consequential damages arising from your use of our services, except to the extent prohibited by law.</p>

        <h3>2. Service Disclaimer</h3>
        <p>Cylandar provides services on an "as is" basis without warranties of any kind, express or implied. We do not guarantee that the services will meet your specific requirements or be uninterrupted.</p>

        <h2>Changes to the Terms of Service</h2>
        <h3>Amendments</h3>
        <p>We reserve the right to modify these Terms of Service at any time. Changes will be effective immediately upon posting, and continued use of the service will constitute acceptance of those changes.</p>

        <h2>Governing Law and Dispute Resolution</h2>
        <h3>Jurisdiction</h3>
        <p>These Terms of Service are governed by the laws of the jurisdiction in which Cylandar operates. Disputes arising will be resolved through arbitration or a competent court in the jurisdiction.</p>

        <h2>Contact Information</h2>
        <h3>Customer Support</h3>
        <p>For any inquiries or issues related to these Terms of Service, please contact Cylandar through the provided contact details on our website.</p>

        <h2>Additional Considerations</h2>
        <h3>Modification and Deletion of Content</h3>
        <p>Users can modify or delete their uploaded content, with Cylandar reserving the right to retain or remove content based on these Terms.</p>

        <h3>Interactivity and User Conduct</h3>
        <p>Guidelines for user conduct during Events and Activities are set to ensure a respectful and engaging environment for all users.</p>

        <h3>Accessibility</h3>
        <p>Cylandar is committed to making its services accessible to users with disabilities, ensuring compliance with accessibility laws and standards.</p>

        <h3>Age Restrictions</h3>
        <p>Users must meet the minimum age requirement, as defined in our policies, to create an account and engage with Cylandar’s services.</p>

        <h3>International Use</h3>
        <p>While Cylandar aims to provide its services internationally, users are responsible for compliance with local laws, including those relating to data transfer and privacy.</p>
      </div>
    </div>
  );
};

export default TermsOfService;
