import React, { useEffect, useContext } from 'react';
import EventDetails from './EventDetails';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useMediaManagement from '../Hooks/UseMediaManagement';

import EventMap from './EventMap';
import EventActivities from './EventActivities';
import SiteMap from './SiteMap';
import ThreeDMap from './ThreeDMap';
import MediaLibrary from './MediaLibrary';

import { UserContext } from '../Contexts/UserContext';

import './Viewport.scss';

const Viewport = () => {
    const { clearCache } = useMediaManagement();
    const { viewportType, setViewportType, getSelectedEvent, getSelectedSite  } = useEditorManagement();

    const { user } = useContext(UserContext);
    
    const selectedEvent = getSelectedEvent();
    const selectedSite = getSelectedSite();

    useEffect(() => {
        if(selectedSite === undefined) return;
        clearCache();
    }, [user, selectedEvent, selectedSite?.id]);

    useEffect(() => {
        setViewportType(0)
    }, []);

    const handledViewportChange = (viewportType) => {
        setViewportType(viewportType);
    };

    return (
        <div className='viewport'>
            <div className='viewport-menu'>
                <button 
                    className={viewportType === 0 ? 'selected' : ''} 
                    onClick={() => handledViewportChange(0)}
                >
                    Event Details
                </button>
                <button 
                    className={viewportType === 1 ? 'selected' : ''} 
                    onClick={() => handledViewportChange(1)}
                >
                    Event Map
                </button>
                <button 
                    className={viewportType === 2 ? 'selected' : ''} 
                    onClick={() => setViewportType(2)}
                >
                    Site Map
                </button>
                <button 
                    className={viewportType === 3 ? 'selected' : ''} 
                    onClick={() => handledViewportChange(3)}
                >
                    3D Editor
                </button>
                <button 
                    className={viewportType === 4 ? 'selected' : ''} 
                    onClick={() => handledViewportChange(4)}
                >
                    Media Library
                </button>
                <button 
                    className={viewportType === 5 ? 'selected' : ''} 
                    onClick={() => setViewportType(5)}
                >
                    Activities
                </button>
            </div>
            <div className='viewport-view'>
                {viewportType === 0 && <EventDetails />}
                {viewportType === 1 && <EventMap />}
                {viewportType === 2 && <SiteMap />}
                {viewportType === 3 && <ThreeDMap />}
                {viewportType === 4 && <MediaLibrary addSite={false}/>}
                {viewportType === 5 && <EventActivities />}
            </div>
        </div>
    );
};

export default Viewport;

