import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

import { fetchDocumentData } from '../Utilities/firestoreUtils.js'

export const createRectangularGrid = ( selectedSite ) => {
    const grid = new THREE.Group();

    // Extract coordinates from selectedSite
    const nwLat = selectedSite.coordinates.northwest.latitude;
    const nwLon = selectedSite.coordinates.northwest.longitude;
    const seLat = selectedSite.coordinates.southeast.latitude;
    const seLon = selectedSite.coordinates.southeast.longitude;

    // Calculate distance for grid size
    const width = calculateDistance(nwLat, nwLon, nwLat, seLon);
    const height = calculateDistance(nwLat, nwLon, seLat, nwLon);

    // Define divisions (can be adjusted as needed)
    const divisionsWidth = width;
    const divisionsHeight = height;

    const stepX = width / divisionsWidth;
    const stepY = height / divisionsHeight;

    const centerLonMaterial = new THREE.LineBasicMaterial({ color: 0x0000FF });
    const centerLatMaterial = new THREE.LineBasicMaterial({ color: 0xFF0000 });
    const normalMaterial = new THREE.LineBasicMaterial({ color: 0xCCCCCC });

    // Calculate the center position
    const halfWidth = width / 2;
    const halfHeight = height / 2;

    // Draw lines parallel to Y-axis (Longitude lines)
    for (let i = 0; i < divisionsWidth; i++) {
        let posX = (i - divisionsWidth / 2) * stepX;
        let material = (i === Math.floor(divisionsWidth / 2)) ? centerLonMaterial : normalMaterial;

        const geometry = new THREE.BufferGeometry().setFromPoints([
            new THREE.Vector3(posX, 0, -halfHeight),
            new THREE.Vector3(posX, 0, halfHeight),
        ]);
        const line = new THREE.Line(geometry, material);
        grid.add(line);
    }

    // Draw lines parallel to X-axis (Latitude lines)
    for (let i = 0; i < divisionsHeight; i++) {
        let posY = (i - divisionsHeight / 2) * stepY;
        let material = (i === Math.floor(divisionsHeight / 2)) ? centerLatMaterial : normalMaterial;

        const geometry = new THREE.BufferGeometry().setFromPoints([
            new THREE.Vector3(-halfWidth, 0, posY),
            new THREE.Vector3(halfWidth, 0, posY),
        ]);
        const line = new THREE.Line(geometry, material);
        grid.add(line);
    }

    // Add orange boundary around the grid
    const boundaryMaterial = new THREE.LineBasicMaterial({ color: 0xFFA500, linewidth: 2 });
    const boundaryGeometry = new THREE.BufferGeometry().setFromPoints([
        new THREE.Vector3(-halfWidth, 0, -halfHeight),
        new THREE.Vector3(halfWidth, 0, -halfHeight),
        new THREE.Vector3(halfWidth, 0, halfHeight),
        new THREE.Vector3(-halfWidth, 0, halfHeight),
        new THREE.Vector3(-halfWidth, 0, -halfHeight) // Close the loop
    ]);

    const boundaryLine = new THREE.LineLoop(boundaryGeometry, boundaryMaterial);
    grid.add(boundaryLine);

    return grid;
};

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;

    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c; // in metres
};

export const createMapPlane = (zoomRange) => {
    const planeGeometry = new THREE.PlaneGeometry(zoomRange.max, zoomRange.max);

    // Load the map texture
    const mapTexture = null

    // Create a canvas to draw the radial gradient
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const size = 512; // or any size you prefer
    canvas.width = size;
    canvas.height = size;

    // Draw the radial gradient with a softer transition
    const gradient = context.createRadialGradient(size / 2, size / 2, 0, size / 2, size / 2, size / 2);
    gradient.addColorStop(0, 'rgba(255, 255, 255, 1)');
    gradient.addColorStop(0.25, 'rgba(255, 255, 255, 1)'); // Intermediate step for a softer transition
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0)');
    context.fillStyle = gradient;
    context.fillRect(0, 0, size, size);

    // Create a texture from the canvas
    const gradientTexture = new THREE.CanvasTexture(canvas);
    gradientTexture.needsUpdate = true;

    // Combine the map texture and gradient texture
    const combinedTexture = new THREE.MeshBasicMaterial({
        map: mapTexture,
        alphaMap: gradientTexture,
        transparent: true,
        side: THREE.DoubleSide
    });

    const newPlane = new THREE.Mesh(planeGeometry, combinedTexture);
    newPlane.rotation.x = -Math.PI / 2;
    newPlane.scale.x = 100;
    newPlane.scale.y = 100;
    newPlane.scale.z = 100;
    return newPlane;
};

export const loadMapTexture = (type, selectedSite, onTextureLoaded, mediaCache, googleMapsApiKey) => {
    const mapType = type === 'map' ? 'roadmap' : 'satellite';
    const latitude = selectedSite.coordinates.center.latitude;
    const longitude = selectedSite.coordinates.center.longitude;
    const zoomLevel = 18;
    const mapWidth = 1000;
    const mapHeight = 1000;
    const apiKey = googleMapsApiKey;
    const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=${zoomLevel}&size=${mapWidth}x${mapHeight}&maptype=${mapType}&key=${apiKey}`;

    const textureId = `map_${latitude}_${longitude}_${zoomLevel}_${mapType}`; // Unique ID for the texture
    const cachedTexture = mediaCache.getCachedAsset(textureId);

    if (cachedTexture) {
        if (onTextureLoaded) {
            onTextureLoaded(cachedTexture);
        }
    } else {
        const loader = new THREE.TextureLoader();
        loader.load(
            mapUrl,
            (texture) => {
                // Cache the loaded texture
                mediaCache.setCachedAsset(textureId, texture);

                if (onTextureLoaded) {
                    onTextureLoaded(texture);
                }
            },
            undefined,
            (error) => {
                console.error('Error loading texture:', error);
            }
        );
    }
};

// Media Creation
export const createMediaMeshes = async (scene, selectedSite, selectableObjects, useMediaCache) => {
    for (let index = 0; index < selectedSite.media.length; index++) {
        const mediaItem = selectedSite.media[index];
        let mesh;

        if (mediaItem.fileType.includes('image')) {
            try {
                const texture = await loadImageTexture(mediaItem, useMediaCache);
                const aspectRatio = texture.image.width / texture.image.height;
                const height = 5;
                const width = height * aspectRatio;
                const geometry = new THREE.PlaneGeometry(width, height);
                geometry.translate(0, height / 2, 0);

                const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
                mesh = new THREE.Mesh(geometry, material);

            } catch (error) {
                continue; 
            }
        } else if (mediaItem.fileType.includes('video')) {
            try {
                let videoTexture = await loadVideoTexture(mediaItem, useMediaCache);
    
                const geometry = new THREE.PlaneGeometry(5, 3);
                const material = new THREE.MeshBasicMaterial({ map: videoTexture, side: THREE.DoubleSide });
                mesh = new THREE.Mesh(geometry, material);
    
            } catch (error) {
                console.error("Failed to load video media item:", mediaItem, error);
                continue;
            }
        } 
        else if (mediaItem.fileType === 'mesh/obj' || mediaItem.fileType === 'mesh/fbx') {
            try {
                mesh = await loadModel(mediaItem, useMediaCache);
                if (mesh && mesh.animations && mesh.animations.length > 0) {
                    const mixer = new THREE.AnimationMixer(mesh);
                    mesh.animations.forEach(clip => {
                        mixer.clipAction(clip).play();
                    });
                    mesh.userData.mixer = mixer;
                }

            } catch (error) {
                console.error(`Failed to load ${mediaItem.fileType} media item:`, mediaItem, error);
                continue;
            }
        }
        
        else {
            const geometry = new THREE.BoxGeometry(1, 1, 1);
            const material = new THREE.MeshBasicMaterial({ color: new THREE.Color(`hsl(${(index / selectedSite.media.length) * 360}, 100%, 50%)`) });
            mesh = new THREE.Mesh(geometry, material);

            // Set mesh properties
            setMeshProperties(mesh, mediaItem);
        }

        setMeshProperties(mesh, mediaItem);
        selectableObjects.push(mesh);
        scene.add(mesh);
    }
    return selectableObjects;
};

export const loadImageTexture = async (mediaItem, useMediaCache) => {
    const cachedTexture = useMediaCache.getCachedAsset(mediaItem.url);
    if (cachedTexture) {
        return cachedTexture;
    } else {
        const fullMediaDetails = await fetchDocumentData('media', mediaItem.id);

        if (!fullMediaDetails) {
            console.error(`Media details not found for ID: ${mediaItem.id}`);
            return new THREE.TextureLoader().load(createMissingImageTexture());
        }
        return await new Promise((resolve) => {
            new THREE.TextureLoader().load(fullMediaDetails.url, loadedTexture => {
                useMediaCache.setCachedAsset(mediaItem.url, loadedTexture);
                resolve(loadedTexture);
            }, undefined, () => {
                // console.error(`Error loading texture for ${fullMediaDetails.fullPath}. Using fallback image.`);
                resolve(createMissingImageTexture());
            });
        });
    }
};

export const loadVideoTexture = async (mediaItem, useMediaCache) => {
    const cachedTexture = useMediaCache.getCachedAsset(mediaItem.url);

    if (cachedTexture) {
        return cachedTexture;
    } else {
        const fullMediaDetails = await fetchDocumentData('media', mediaItem.id);
        
        if (!fullMediaDetails) {
            console.error(`Media details not found for ID: ${mediaItem.id}`);
            return createMissingImageTexture(); // Fallback texture
        }

        const video = document.createElement('video');
        video.src = fullMediaDetails.url;
        video.crossOrigin = "anonymous";
        video.loop = true;

        return new Promise((resolve, reject) => {
            video.addEventListener('canplaythrough', () => {
                const videoTexture = new THREE.VideoTexture(video);
                useMediaCache.setCachedAsset(mediaItem.url, videoTexture);
                resolve(videoTexture);
            });

            video.addEventListener('error', () => {
                // console.error(`Error loading video for ${mediaItem.id}`);
                resolve(createMissingImageTexture()); // Fallback texture
            });

            video.load(); // Explicitly call load to trigger the video load process
        });
    }
};

export const loadModel = async (mediaItem, useMediaCache) => {
    try {
        const meshMedia = await fetchDocumentData('media', mediaItem.id);
        let texture;

        if (meshMedia.texture) {
            const textureMedia = await fetchDocumentData('media', meshMedia.texture);
            const texturePath = textureMedia.url;

            texture = useMediaCache.getCachedAsset(texturePath);

            if (!texture) {
                texture = await new Promise((resolve, reject) => {
                    new THREE.TextureLoader().load(texturePath, resolve, undefined, reject);
                });
                useMediaCache.setCachedAsset(texturePath, texture);
            }
        } else {
            texture = createMissingImageTexture();
        }

        let model = useMediaCache.getCachedAsset(meshMedia.url);
        if(!model) {
            model = await loadMesh(meshMedia, texture);
            // useMediaCache.setCachedAsset(meshMedia.url, model);
        }
        return model;
    } catch (error) {
        console.error("Error in loadModel:", error);
        return createFallbackCube();
    }
};

const loadMesh = async ( mediaItem, texture) => {
    const LoaderType = mediaItem.fileType === 'mesh/obj' ? OBJLoader : FBXLoader;
    const loader = new LoaderType();

    return new Promise((resolve, reject) => {
        loader.load(mediaItem.url, object => {
            object.traverse(child => {
                if (child.isMesh) {
                    child.material = child.material.clone(); // Clone material

                    child.material.map = texture;
                    child.material.transparent = true;
                    child.material.needsUpdate = true;
                    child.material.depthTest = true;
                    child.material.depthWrite = true;
                    child.material.side = THREE.DoubleSide;
                    if (child.isSkinnedMesh) {
                        child.material.skinning = true;
                    }
                }
            });
            resolve(object);
        }, undefined, reject);
    });
};

const createFallbackCube = () => {
    console.warn("Using fallback cube for media item");
    const geometry = new THREE.BoxGeometry(1, 1, 1); // Size of the cube
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 }); // Green color
    return new THREE.Mesh(geometry, material);
};

function createMissingImageTexture() {
    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 128;
    const context = canvas.getContext('2d');

    // Fill background
    context.fillStyle = '#ff0000'; // Red background for visibility
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Draw text
    context.fillStyle = '#ffffff'; // White text
    context.font = '24px Arial';
    context.textAlign = 'center';
    context.textBaseline = 'middle';
    context.fillText('Missing Image', canvas.width / 2, canvas.height / 2);

    return new THREE.CanvasTexture(canvas);
}

function setMeshProperties(mesh, mediaItem) {
    if (!Array.isArray(mediaItem.position) || mediaItem.position.length < 3) {
        console.error("Invalid position data for media item:", mediaItem);
        return;
    }
    if (!Array.isArray(mediaItem.rotation) || mediaItem.rotation.length < 3) {
        console.error("Invalid rotation data for media item:", mediaItem);
        return;
    }
    if (!Array.isArray(mediaItem.scale) || mediaItem.scale.length < 3) {
        console.error("Invalid scale data for media item:", mediaItem);
        return;
    }

    mesh.userData.guid = mediaItem.guid;
    mesh.position.set(mediaItem.position[0], mediaItem.position[1], mediaItem.position[2]);
    mesh.rotation.set(mediaItem.rotation[0], mediaItem.rotation[1], mediaItem.rotation[2]);
    mesh.scale.set(mediaItem.scale[0], mediaItem.scale[1], mediaItem.scale[2]);
}

export const createsSky = (scene) => {
    const vertexShader = `
    varying vec3 vWorldPosition;
    void main() {
        vec4 worldPosition = modelMatrix * vec4(position, 1.0);
        vWorldPosition = worldPosition.xyz;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`;

    const fragmentShader = `
    uniform vec3 topColor;
    uniform vec3 bottomColor;
    uniform float offset;
    uniform float exponent;
    varying vec3 vWorldPosition;
    void main() {
        float h = normalize(vWorldPosition + offset).y;
        gl_FragColor = vec4(mix(bottomColor, topColor, max(pow(max(h, 0.0), exponent), 0.0)), 1.0);
    }`;

    const uniforms = {
        topColor: { value: new THREE.Color(0x1111ff) }, // sky color
        bottomColor: { value: new THREE.Color(0xffffff) }, // ground color
        offset: { value: 33 },
        exponent: { value: 0.6 }
    };

    const skyGeo = new THREE.SphereGeometry(500, 32, 15);
    const skyMat = new THREE.ShaderMaterial({
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        uniforms: uniforms,
        side: THREE.BackSide
    });

    const sky = new THREE.Mesh(skyGeo, skyMat);
    scene.add(sky);
    scene.fog = new THREE.Fog(0xffffff, 400, 1000);
};

export const createLights = (scene) => {
    const keyLight = new THREE.DirectionalLight(0xffffff, 1.0);
    keyLight.position.set(100, 100, 100);
    scene.add(keyLight);
    
    // Fill Light - Less intense and positioned on the opposite side of the key light
    const fillLight = new THREE.DirectionalLight(0xffffff, 0.5);
    fillLight.position.set(-100, 50, 50);
    scene.add(fillLight);
    
    // Back Light - Positioned behind the subject
    const backLight = new THREE.DirectionalLight(0xffffff, 0.7);
    backLight.position.set(0, 50, -100);
    scene.add(backLight);
}
