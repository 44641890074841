import { useEffect, useState, useContext } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Suspense } from 'react';
import { Html, OrbitControls } from '@react-three/drei';

import useMediaManagement from '../Hooks/UseMediaManagement';

import { loadModel } from '../Utilities/ThreeDMapUtils';

const ObjModel = ({ model, mixer }) => {
  useFrame((state, delta) => {
    if (mixer) {
      mixer.update(delta);
    }

    if (model) {
      model.rotation.y += 0.005;
    }
  });

  return model ? <primitive object={model} /> : null;
};

export const ThreeDModelViewer = ({ mediaItem }) => {
  const [cameraParams, setCameraParams] = useState({
    focus: new THREE.Vector3(0, 0, 0),
    position: new THREE.Vector3(0, 0, 2),
    nearClippingPlane: 1,
    farClippingPlane: 10000,
  });

  const [lightPositions, setLightPositions] = useState({
    keyLight: new THREE.Vector3(10, 10, 10),
    fillLight: new THREE.Vector3(10, 10, 10),
    backLight: new THREE.Vector3(10, 10, 10),
  });

  const { getCachedAsset, setCachedAsset } = useMediaManagement();
  const [ model, setModel ] = useState(null);
  const [ mixer, setMixer ] = useState(null); // Add state for the mixer

  useEffect(() => {
    let isMounted = true;

    const initModel = async () => {
      if (mediaItem && isMounted) {
        const loadedModel = await loadModel(mediaItem, { getCachedAsset, setCachedAsset });
        setModel(loadedModel);

        const box = new THREE.Box3().setFromObject(loadedModel);
        const center = box.getCenter(new THREE.Vector3());
        const size = box.getSize(new THREE.Vector3());
        const maxDimension = Math.max(size.x, size.y, size.z);
        const fov = 45 * (Math.PI / 180);
        const cameraDistance = (maxDimension / 2) / Math.tan(fov / 2);
        const lightDistance = maxDimension * 1.5; // Adjust the factor as needed

        setLightPositions({
          keyLight: new THREE.Vector3(center.x + lightDistance, center.y + lightDistance, center.z),
          fillLight: new THREE.Vector3(center.x - lightDistance, center.y - lightDistance / 2, center.z),
          backLight: new THREE.Vector3(center.x, center.y + lightDistance, center.z - lightDistance)
        });
        
        setCameraParams({
          ...cameraParams,
          focus: center,
          position: new THREE.Vector3(0, 0, cameraDistance),
        });

        if (loadedModel.animations && loadedModel.animations.length > 0) {
          const newMixer = new THREE.AnimationMixer(loadedModel);
          const action = newMixer.clipAction(loadedModel.animations[0]);
          action.play();
          setMixer(newMixer);
        }
      }
    };

    initModel();

    return () => {
      if (model && isMounted) {
        model.traverse((object) => {
          if (object.isMesh) {
            if (object.geometry) object.geometry.dispose();
            if (object.material) {
              if (object.material.map) object.material.map.dispose();
              object.material.dispose();
            }
          }
        });
      }
      if (mixer) {
        mixer.stopAllAction();
      }
      isMounted = false;
    };
    
  }, [mediaItem]);

  const CameraUpdater = ({ cameraParams }) => {
    const { camera } = useThree();
  
    useEffect(() => {
      camera.position.set(...cameraParams.position.toArray());
      camera.lookAt(...cameraParams.focus.toArray());
      camera.near = cameraParams.nearClippingPlane;
      camera.far = cameraParams.farClippingPlane;
      camera.updateProjectionMatrix();
    }, [camera, cameraParams]);
  
    return null;
  };

  return (
    <Canvas style={{ width: '100%', height: '100%', backgroundColor: 'skyblue' }}>
      <CameraUpdater cameraParams={cameraParams} />
        <Suspense fallback={<Html><div>Loading...</div></Html>}>
          <ambientLight intensity={1} />
          <spotLight position={lightPositions.keyLight} intensity={5} />
          <spotLight position={lightPositions.fillLight} intensity={0.4} />
          <spotLight position={lightPositions.backLight} intensity={0.5} />
          <ObjModel model={model} mixer={mixer} />
          <OrbitControls target={cameraParams.focus} />
        </Suspense>
      </Canvas>
  );
};
