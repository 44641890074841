import React from 'react';

import SiteMap from '../../Components/SiteMap';

import '../Modal.scss';
import './LocationEditorUI.scss';

const LocationEditorUI = ({onClose}) => {
    return (
        <div className="location-editor-ui">
            <SiteMap onClose={onClose}/>
        </div>
    );
};

export default LocationEditorUI;
