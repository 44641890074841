import React, { useContext, useRef, useState, useEffect } from 'react';

import useEditorManagement from '../Hooks/UseEditorManagement';

import { ModalContext } from '../Contexts/ModalContext';

import './NameDescription.scss'; 

const NameDescription = ({collection}) => {
    const { getSelectedEvent, getSelectedSite, getSelectedMedia } = useEditorManagement();
    const { showModal } = useContext(ModalContext);

    const componentRef = useRef(null); // Step 1: Create a ref
    const [position, setPosition] = useState({ top: 0, left: 0 }); // Initial position state

    useEffect(() => {
        // Function to calculate and update position
        const calculatePosition = () => {
            if (componentRef.current) {
                const rect = componentRef.current.getBoundingClientRect();
                setPosition({
                    top: rect.bottom,
                    left: rect.left
                });
            }
        };

        calculatePosition();
        window.addEventListener('resize', calculatePosition);

        return () => window.removeEventListener('resize', calculatePosition);
    }, []);

    let data;
    if (collection === 'event') {
        data = getSelectedEvent();
    } else if (collection === 'site') {
        data = getSelectedSite();
    }
     else if (collection === 'media' || collection === 'sitemedia') {
        data = getSelectedMedia();
        if (!data) {
            return;
        }
    }


    const handleShowModal = () => {
        showModal('namedescription', collection, data, position);
    };

    return (
        <div className='name-description component' onClick={() => handleShowModal()} ref={componentRef}>
            <div>
                Details {data.name}
            </div>
            <div>
                Edit
            </div>
        </div>
    );
};

export default NameDescription;

