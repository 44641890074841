import React from 'react';
import { useNavigate } from 'react-router-dom';

import image_01 from '../Assets/design_02.webp';
import image_02 from '../Assets/create_02.webp';
import image_03 from '../Assets/share_01.webp';

import './HomePage.scss';

function HomePage() {
  const navigate = useNavigate();

  const handleJoin = () => {
    navigate('/join');
  }

  return (
    <div className='home-page '>
      <div className='box container'>
        <img src={image_01} alt='My WebP' /> 
        <div className='box-bkg'> </div>
        <div className='box-text'>Design</div>
      </div>
      <div className='box container'>
        <img src={image_02} alt='My WebP' />
        <div className='box-bkg'> </div>
        <div className='box-text'>Create</div>
      </div>
      <div className='box container'>
        <img src={image_03} alt='My WebP' />
        <div className='box-bkg'> </div>
        <div className='box-text'>Share</div>
      </div>
      <button className='cta-button' onClick={handleJoin} >
        <p>Location-based Augmented Reality Starting at $1 a day. Start Now!</p>
      </button>
    </div>
  );
}


export default HomePage;