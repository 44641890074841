// PrivacyPolicy.js
import React from 'react';

import './LegalPage.scss';

const DMCAPolicy = () => {
  return (
    <div className='panel'>
      <div className='legal-page'>
        <h1>DMCA Policy</h1>
        <p>Cylandar respects the intellectual property rights of others and expects its users to do the same. In accordance with the Digital Millennium Copyright Act (DMCA), Cylandar has adopted the following policy towards copyright infringement.</p>
        
        <h2>Copyright Infringement Notification</h2>
        <p>If you believe that your copyrighted work has been copied in a way that constitutes copyright infringement and is accessible on the Cylandar platform, you may notify our Copyright Agent as follows:</p>
        <ul>
          <li><strong>Designated Agent:</strong> [Name of the Designated Agent for DMCA Notices]</li>
          <li><strong>Address:</strong> [Physical Address]</li>
          <li><strong>Email:</strong> [Email Address]</li>
          <li><strong>Telephone:</strong> [Telephone Number]</li>
          <li><strong>Fax:</strong> [Fax Number]</li>
        </ul>

        <p>For your complaint to be valid under the DMCA, you must provide the following information in writing:</p>
        <ol>
          <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
          <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site.</li>
          <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material.</li>
          <li>Information reasonably sufficient to permit the service provider to contact the complainant, such as an address, telephone number, and, if available, an electronic mail address at which the complainant may be contacted.</li>
          <li>A statement that the complainant has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
          <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complainant is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
        </ol>

        <p>Upon receipt of the written notification containing the information as outlined above:</p>
        <ul>
          <li>Cylandar will remove or disable access to the infringing material.</li>
          <li>Cylandar will take reasonable steps to notify the infringer (“User”) that the material has been removed or access to it has been disabled.</li>
          <li>Cylandar will take reasonable steps to terminate the User’s Cylandar account if they are found to be a repeat infringer.</li>
        </ul>

        <h2>Counter-Notification</h2>
        <p>If you, as the User, believe that the material that was removed or to which access was disabled is not infringing, or you believe you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to post and use the material in your submission, you may send a counter-notification containing the following information to the Copyright Agent:</p>
        <ol>
          <li>Your physical or electronic signature.</li>
          <li>Identification of the material that has been removed or to which access has been disabled and the location at which the material appeared before it was removed or access to it was disabled.</li>
          <li>A statement under penalty of perjury that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification of the material to be removed or disabled.</li>
          <li>Your name, address, and telephone number, and a statement that you consent to the jurisdiction of the federal court in [Jurisdiction], and that you will accept service of process from the person who provided notification of the alleged infringement.</li>
        </ol>

        <p>If a counter-notification is received by the Copyright Agent, Cylandar may send a copy of the counter-notification to the original complaining party informing them that Cylandar may replace the removed material or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the User, the removed material may be replaced or access to it restored in 10 to 14 business days or more after receipt of the counter-notification, at Cylandar’s discretion.</p>

        <h2>Modifications</h2>
        <p>Cylandar reserves the right to modify the contents of this DMCA Policy at any time. You are advised to review this Policy periodically for any changes.</p>

        <h2>Contact Information</h2>
        <p>For any inquiries regarding this DMCA Policy, please contact Cylandar using the contact information provided above.</p>
      </div>
    </div>
    );
};

export default DMCAPolicy;
