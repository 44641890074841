import React, { useState } from 'react';

import useEditorManagement from '../../Hooks/UseEditorManagement';

import '../Modal.scss';
// import './NameDescriptionUI.scss';

const NameDescriptionUI = ({ collection, data }) => {
    const { updateItem } = useEditorManagement();
    const [ startName ] = useState(data.name);
    const [ name, setName ] = useState(data.name);
    const [ startDescription ] = useState(data.description);
    const [ description, setDescription ] = useState(data.description);
    const [ hasChanged, setHasChanged ] = useState(false);

    const handleNameChange = (e) => { 
        setName(e);
        setHasChanged(true);
    }

    const handleDescriptionChange = (e) => { 
        setDescription(e);
        setHasChanged(true);
    }

    const handleReset = () => {
        setName(startName);
        setDescription(startDescription);
        setHasChanged(false);
    };

    const handleSave = async() => {
        if(name !== startName) {
            await updateItem(collection, {['name']: name});
        }
        if(description !== startDescription) {
            await updateItem(collection, {['description']: description});
        }
        setHasChanged(false);
    }

    return (
        <div className="name-decriptionUI">
            <div className='site-details-site-icon'>
                Name:
                <input
                    type='text'
                    field={'name'}
                    value={name}
                    onChange={(e) => handleNameChange(e.target.value)}
                />
                Description:
                <input
                    type='text'
                    field={'description'}
                    value={description}
                    onChange={(e) => handleDescriptionChange(e.target.value)}
                />
            </div>
            {hasChanged && (
                <div className="save-button-container">
                    <button onClick={handleSave} className="save-button">
                        Save Changes
                    </button>
                    <button onClick={handleReset} className="reset-button">
                        Reset Changes
                    </button>
                </div>
            )}
        </div>
    );
}

export default NameDescriptionUI;
