import React, { useState, useRef, useEffect, useContext } from 'react';
import { GoogleMap, Marker, Rectangle, OverlayView } from '@react-google-maps/api';
import axios from 'axios';

import useEditorManagement from '../Hooks/UseEditorManagement.js';

import { GoogleMapsContext } from '../Contexts/GoogleMapsContext';

import SiteAddress from './SiteAddress';

import './SiteMap.scss';

const SiteMap = () => {
    const { isLoaded, loadError, zoomRange, mapStyle, googleMapsApiKey } = useContext(GoogleMapsContext);

    const { updateItem, getSelectedSite, setViewportType } = useEditorManagement();
    const [ siteDetails, setSiteDetails ] = useState(getSelectedSite());
    const [initialSiteDetails, setInitialSiteDetails] = useState(null);

    const [ gpsChanged, setGPSChanged ] = useState(false);

    const bounds = siteDetails?.coordinates? {
        north: Number(siteDetails.coordinates.northwest.latitude),
        south: Number(siteDetails.coordinates.southeast.latitude),
        east: Number(siteDetails.coordinates.southeast.longitude),
        west: Number(siteDetails.coordinates.northwest.longitude),
    } : null;    
    
    const mapRef = useRef(null);
    const [ rectangleBounds, setRectangleBounds ] = useState(bounds);
    const [ mapLoaded, setMapLoaded ] = useState(false);

    const circleIcon = window.google ? {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: 'blue',
        fillOpacity: 0.6,
        scale: 8,
        strokeColor: 'white',
        strokeWeight: 2
    } : null;

    const mapContainerStyle = { width: '100%', height: '100%' };

    const mapOptions = {
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: "greedy",
        styles: mapStyle
    };

    const MIN_DISTANCE_METERS = zoomRange.min;
    const MAX_DISTANCE_METERS = zoomRange.max;

    const mapCenter = siteDetails?.coordinates?.center
    ? { lat: Number(siteDetails.coordinates.center.latitude), lng: Number(siteDetails.coordinates.center.longitude) }
    : null;

    useEffect(() => {
        // Store the initial site details when the component mounts or when siteDetails change significantly
        if (siteDetails && siteDetails.coordinates && !initialSiteDetails) {
            setInitialSiteDetails(JSON.parse(JSON.stringify(siteDetails)));
        }
    }, [siteDetails]);

    useEffect(() => {
        if (isLoaded && rectangleBounds && mapRef.current) {
            zoomToRectangleBounds();
        }
    }, [isLoaded, rectangleBounds, mapLoaded]);

    useEffect(() => {
        if (siteDetails?.coordinates) {
            const newBounds = {
                north: Number(siteDetails.coordinates.northwest.latitude),
                south: Number(siteDetails.coordinates.southeast.latitude),
                east: Number(siteDetails.coordinates.southeast.longitude),
                west: Number(siteDetails.coordinates.northwest.longitude),
            };
            setRectangleBounds(newBounds);
        }
    }, [siteDetails]);

    const handleMapLoad = (map) => {
        mapRef.current = map;
        setMapLoaded(true);
    };
    
    const zoomToRectangleBounds = () => {
        if (mapRef.current && rectangleBounds) {
            const bounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(rectangleBounds.south, rectangleBounds.west),
                new window.google.maps.LatLng(rectangleBounds.north, rectangleBounds.east)
            );
            mapRef.current.fitBounds(bounds);
        }
    };

    const calculateDimensions = () => {
        const northEast = new window.google.maps.LatLng(rectangleBounds.north, rectangleBounds.east);
        const northWest = new window.google.maps.LatLng(rectangleBounds.north, rectangleBounds.west);
        const southWest = new window.google.maps.LatLng(rectangleBounds.south, rectangleBounds.west);
    
        const length = window.google.maps.geometry.spherical.computeDistanceBetween(northWest, southWest);
        const width = window.google.maps.geometry.spherical.computeDistanceBetween(northWest, northEast);
    
        const offsetDistance = 5;

        const lengthLabelPosition = window.google.maps.geometry.spherical.computeOffset(
            new window.google.maps.LatLng(
                (rectangleBounds.north + rectangleBounds.south) / 2,
                rectangleBounds.west
            ),
            offsetDistance,
            -90
        );
    
        const widthLabelPosition = window.google.maps.geometry.spherical.computeOffset(
            new window.google.maps.LatLng(
                rectangleBounds.north,
                (rectangleBounds.east + rectangleBounds.west) / 2
            ),
            offsetDistance,0
        );
    
        return { length, width, lengthLabelPosition, widthLabelPosition };
    };
    
    const renderDimensionLabels = () => {
        const { length, width, lengthLabelPosition, widthLabelPosition } = calculateDimensions();
    
        return (
            <>
                <OverlayView
                    position={lengthLabelPosition}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                    <div className="site-map-label">{`${length.toFixed(2)} meters`}</div>
                </OverlayView>
    
                <OverlayView
                    position={widthLabelPosition}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                    <div className="site-map-label">{`${width.toFixed(2)} meters`}</div>
                </OverlayView>
            </>
        );
    };
    
    const handleMarkerDrag = (e, corner) => {
        const newLat = e.latLng.lat();
        const newLng = e.latLng.lng();
    
        const newBounds = { ...rectangleBounds };
        if (corner === 'northwest') {
            newBounds.north = newLat;
            newBounds.west = newLng;
        } else if (corner === 'southeast') {
            newBounds.south = newLat;
            newBounds.east = newLng;
        }
    
        setRectangleBounds(newBounds);
        setGPSChanged(true);
    };    

    const handleMarkerDragEnd = (e, corner) => {
        let newLat = e.latLng.lat();
        let newLng = e.latLng.lng();
        const centerLat = siteDetails.coordinates.center.latitude;
        const centerLng = siteDetails.coordinates.center.longitude;
    
        if (corner === 'northwest') {
            newLat = Math.max(newLat, centerLat);
            newLng = Math.min(newLng, centerLng);
        } else {
            newLat = Math.min(newLat, centerLat);
            newLng = Math.max(newLng, centerLng);
        }
    
        let latDistance = window.google.maps.geometry.spherical.computeDistanceBetween(
            new window.google.maps.LatLng(newLat, centerLng), 
            new window.google.maps.LatLng(centerLat, centerLng)
        );
        if (latDistance > MAX_DISTANCE_METERS) {
            newLat = window.google.maps.geometry.spherical.computeOffset(
                new window.google.maps.LatLng(centerLat, centerLng), 
                MAX_DISTANCE_METERS, 
                (corner === 'northwest' ? 0 : 180)
            ).lat();
        } else if (latDistance < MIN_DISTANCE_METERS) {
            newLat = window.google.maps.geometry.spherical.computeOffset(
                new window.google.maps.LatLng(centerLat, centerLng), 
                MIN_DISTANCE_METERS, 
                (corner === 'northwest' ? 0 : 180)
            ).lat();
        }
        let lngDistance = window.google.maps.geometry.spherical.computeDistanceBetween(
            new window.google.maps.LatLng(centerLat, newLng), 
            new window.google.maps.LatLng(centerLat, centerLng)
        );
        if (lngDistance > MAX_DISTANCE_METERS) {
            newLng = window.google.maps.geometry.spherical.computeOffset(
                new window.google.maps.LatLng(centerLat, centerLng), 
                MAX_DISTANCE_METERS, 
                (corner === 'northwest' ? 270 : 90)
            ).lng();
        } else if (lngDistance < MIN_DISTANCE_METERS) {
            newLng = window.google.maps.geometry.spherical.computeOffset(
                new window.google.maps.LatLng(centerLat, centerLng), 
                MIN_DISTANCE_METERS, 
                (corner === 'northwest' ? 270 : 90)
            ).lng();
        }
    
        const updatedBounds = { ...rectangleBounds };
        if (corner === 'northwest') {
            updatedBounds.north = newLat;
            updatedBounds.west = newLng;
        } else {
            updatedBounds.south = newLat;
            updatedBounds.east = newLng;
        }
        setRectangleBounds(updatedBounds);
    };
    
    const handleCenterMarkerDrag = (e) => {
        const newCenterLat = e.latLng.lat();
        const newCenterLng = e.latLng.lng();
    
        const displacementLat = newCenterLat - mapCenter.lat;
        const displacementLng = newCenterLng - mapCenter.lng;
    
        const newBounds = {
            north: rectangleBounds.north + displacementLat,
            south: rectangleBounds.south + displacementLat,
            east: rectangleBounds.east + displacementLng,
            west: rectangleBounds.west + displacementLng
        };
    
        setRectangleBounds(newBounds);
    };
    
    const handleCenterMarkerDragEnd = async () => {
        const newCenterLat = rectangleBounds.north - ((rectangleBounds.north - rectangleBounds.south) / 2);
        const newCenterLng = rectangleBounds.west - ((rectangleBounds.west - rectangleBounds.east) / 2);
    
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${newCenterLat},${newCenterLng}&key=${googleMapsApiKey}`);
            if (response.data.status === "OK") {
                const results = response.data.results;
                if (results[0]) {
                    const addressComponents = results[0].address_components;
                    const streetNumber = addressComponents.find(comp => comp.types.includes('street_number'))?.long_name || '';
                    const route = addressComponents.find(comp => comp.types.includes('route'))?.long_name || '';
                    const city = addressComponents.find(comp => comp.types.includes('locality'))?.long_name || '';
                    const state = addressComponents.find(comp => comp.types.includes('administrative_area_level_1'))?.short_name || '';
                    const postalCode = addressComponents.find(comp => comp.types.includes('postal_code'))?.long_name || '';
                    const country = addressComponents.find(comp => comp.types.includes('country'))?.long_name || '';
                    const street = `${streetNumber} ${route}`.trim();
    
                    const updatedSiteDetails = {
                        ...siteDetails,
                        address: {
                            street,
                            city,
                            state,
                            postalCode,
                            country,
                            coordinates: {
                                latitude: newCenterLat,
                                longitude: newCenterLng,
                            }
                        },
                        coordinates: {
                            ...siteDetails.coordinates,
                            center: { 
                                latitude: newCenterLat, 
                                longitude: newCenterLng 
                            },
                            northwest: { latitude: rectangleBounds.north, longitude: rectangleBounds.west },
                            southeast: { latitude: rectangleBounds.south, longitude: rectangleBounds.east }
                        }
                    };
    
                    setSiteDetails(updatedSiteDetails);
                    setGPSChanged(true);
                }
            }
        } catch (error) {
            console.error("Error fetching address from Google API:", error);
        }
    };
    
    
    const resetMarkers = () => {
        if (initialSiteDetails) {
            setSiteDetails(JSON.parse(JSON.stringify(initialSiteDetails)));
    
            const initialBounds = {
                north: Number(initialSiteDetails.coordinates.northwest.latitude),
                south: Number(initialSiteDetails.coordinates.southeast.latitude),
                east: Number(initialSiteDetails.coordinates.southeast.longitude),
                west: Number(initialSiteDetails.coordinates.northwest.longitude),
            };
            setRectangleBounds(initialBounds);
            setGPSChanged(false);
        }
    };

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps...</div>;

    const onSave = async () => {
        const address = {['address']: siteDetails.address};
        await updateItem('site', address);
        const coordinates = {['coordinates']: siteDetails.coordinates};
        await updateItem('site', coordinates);
        setGPSChanged(false);
    };

    return (
        <div id='sit-emap' className='site-map panel'>
            <SiteAddress key={siteDetails.address} siteDetails={siteDetails} setSiteDetails={setSiteDetails}/>
            <div className="save-button-container">
                { gpsChanged && <>
                    <button onClick={onSave}> Save Changes </button>
                    <button onClick={resetMarkers}>Reset Changes</button>
                </>
                }
            </div>
            {mapCenter && (
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={17}
                    center={mapCenter}
                    onLoad={handleMapLoad}
                    options={mapOptions}
                >
                    <Marker
                        position={mapCenter}
                        draggable={true}
                        onDrag={handleCenterMarkerDrag}
                        onDragEnd={handleCenterMarkerDragEnd}
                        onClick={() => {
                            setViewportType(3);
                        }}
                    />
                    <Rectangle 
                        bounds={rectangleBounds}
                        options={{
                            strokeColor: '#00AAFF',
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillColor: '#CCFFFF',
                            fillOpacity: 0.35,
                        }}
                    />
                    <Marker 
                        position={{ lat: rectangleBounds.north, lng: rectangleBounds.west }}
                        icon={circleIcon}
                        title="Northwest Corner"
                        draggable={true}
                        onDrag={(e) => handleMarkerDrag(e, 'northwest')}
                        onDragEnd={(e) => handleMarkerDragEnd(e, 'northwest')}
                    />
                    <Marker 
                        position={{ lat: rectangleBounds.south, lng: rectangleBounds.east }}
                        icon={circleIcon}
                        title="Southeast Corner"
                        draggable={true}
                        onDrag={(e) => handleMarkerDrag(e, 'southeast')}
                        onDragEnd={(e) => handleMarkerDragEnd(e, 'southeast')}
                    />

                    {renderDimensionLabels()}
                </GoogleMap>
            )}
        </div>
    );
};

export default SiteMap;