import React, { useContext, useRef, useState, useEffect } from 'react';

import useEditorManagement from '../Hooks/UseEditorManagement';

import { ModalContext } from '../Contexts/ModalContext';

import './LocationEditor.scss'; 

const LocationEditor = () => {
    const { getSelectedSite } = useEditorManagement();
    const { showModal } = useContext(ModalContext);

    const data = getSelectedSite();

    const componentRef = useRef(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        const calculatePosition = () => {
            if (componentRef.current) {
                const rect = componentRef.current.getBoundingClientRect();
                setPosition({
                    top: 64,
                    left: rect.right
                });
            }
        };

        calculatePosition();
        window.addEventListener('resize', calculatePosition);

        return () => window.removeEventListener('resize', calculatePosition);
    }, []);

    const handleShowModal = () => {
        showModal('location', 'site', data, position);
    };

    return (
        !getSelectedSite().promo ? (
            <div className='location-editor component' onClick={handleShowModal} ref={componentRef}>
                <div>
                    Address: {data.address.street}
                </div>
                <div>
                    Edit
                </div>
        </div>
        ) : (
            <div className='location-editor-inactive component-inactive'>
                <div>No Address</div>
            </div>
        )
    );
};

export default LocationEditor;
