import React, { useState, useContext } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

import useSalesManagement from '../Hooks/UseSalesManagement';

import { UserContext } from '../Contexts/UserContext';
import { useMessage } from '../Contexts/SystemMessageContext';

import './CheckoutForm.scss';

const CheckoutForm = ({ selectedProduct }) => {
  const { user, customerDetails } = useContext( UserContext );
  const { createStripeTrialSubscription, createStripeSubscription } = useSalesManagement();
  const stripe = useStripe();
  const elements = useElements();
  const { setWaiting, setWaitingMessage } = useMessage();

  const [ error, setError ] = useState('');
  const [ changeCard, setChangeCard ] = useState(false);
  const [ isDefaultCard, setIsDefaultCard ] = useState(true);
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    cardholderName: '',
    setDefaultCard: isDefaultCard
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#ffffff",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#ffffff"
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      },
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe) {
      return;
    }
    
    setWaiting(true);
    const message = !user.trial.started ? 'Starting trial...' : 'Processing payment...';
    setWaitingMessage(message)
    if (user.trial.started) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      let newUsetData = false;
      if (!customerDetails.customer.invoice_settings.default_payment_method) {
        newUsetData = true;
      }

      await createStripeSubscription(selectedProduct, stripe, formData, cardNumberElement, setError, changeCard, newUsetData);
    } else {
      await createStripeTrialSubscription(selectedProduct);
    }
    setWaiting(true);
    setWaitingMessage('')
  };

  const handleCardChange = () => {
    setChangeCard(!changeCard);
  }
  
  return (
    <div className='credit-card-signup'>
        <form className='credit-card-signup-form' onSubmit={handleSubmit}>
          {((!customerDetails.customer.invoice_settings.default_payment_method && user.trial.started)|| changeCard) && (
            <>
            <h2>Add a card</h2>
            <div>
              {!customerDetails.customer.invoice_settings.default_payment_method ? 'You have no saved cards.  PLease add a card to subscribe' : 'Enter card details'}
            </div>
                <div className='credit-card-signup-form-element'>
                    <label>Full Name</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} />
                </div>
                <div className='credit-card-signup-form-phone-email'>
                    <div className='credit-card-signup-form-element'>
                        <label>Email Address</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className='credit-card-signup-form-element'>
                        <label>Phone Number</label>
                        <input type="tel" name="phone" value={formData.phone} onChange={handleChange} />
                    </div>
                </div>
                <div className='credit-card-signup-form-address'>
                    <div className='credit-card-signup-form-address-street'>
                        <label>Street Address</label>
                        <input type="text" name="address" value={formData.address} onChange={handleChange} />
                    </div>
                    <div className='credit-card-signup-form-address-postal'>
                        <div className='credit-card-signup-form-element'>
                            <label>City</label>
                            <input type="text" name="city" value={formData.city} onChange={handleChange} />
                        </div>
                        <div className='credit-card-signup-form-element'>
                            <label>State/Province</label>
                            <input type="text" name="state" value={formData.state} onChange={handleChange} />
                        </div>
                        <div className='credit-card-signup-form-element'>
                            <label>ZIP/Postal Code</label>
                            <input type="text" name="zip" value={formData.zip} onChange={handleChange} />
                        </div>
                        <div className='credit-card-signup-form-element'>
                            <label>Country</label>
                            <input type="text" name="country" value={formData.country} onChange={handleChange} />
                        </div>
                    </div>
                </div>
              <label>
                Card Number
                <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
              </label>
              <label>
                Expiration Date
                <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
              </label>
              <label>
                CVC
                <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
              </label>
              <label>
                Set as default payment card
                <input type="checkbox" name="setDefaultCard" checked={formData.setDefaultCard} onChange={handleChange} />
              </label>
            </>
          )}
          <div className="error-message">
            {error && <p>{error}</p>}
          </div>
          { !user.trial.started ?
            (<button type="submit" disabled={!stripe}>
              Start Free trial
            </button>)  :
            (
              <div>
                {!customerDetails.customer.invoice_settings.default_payment_method ?
                  (<button type="submit" disabled={!stripe}>
                    {'Add Card and Subscribe'}
                  </button>) :
                  (
                    <div>
                      <button type="submit" disabled={!stripe}>
                        {!changeCard ? 'Purchase using saved card' : 'Purchase using this card'}
                      </button>
                      <button type="button" onClick={()=> handleCardChange()} >
                        {changeCard ? 'cancel add new card' : 'Change Card'}
                      </button>
                    </div>
                  )}
              </div>
            )
          } 
        </form>
    </div>
  );
};

export default CheckoutForm;
