import React from 'react';
import ReactDOM from 'react-dom'
import { useMessage } from '../Contexts/SystemMessageContext';

import './SystemNotification.scss'; 

const SystemWaiting = () => {
    const rootElement = document.getElementById('app');
    const { waiting, waitingMessage } = useMessage();

    if (!rootElement) {
        return null;
    }

    if (!waiting) {
        return null;
    }

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 9999,
    };
    
    return ReactDOM.createPortal(
        <div className='modal-backdrop'>
            <div className='modal-content' style={modalStyle}>
                <h1>{waitingMessage}</h1>
            </div>
        </div>,
        rootElement
    );
};

export default SystemWaiting;
