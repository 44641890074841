import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';

import { GoogleMapsContext } from '../Contexts/GoogleMapsContext';

const SiteAddress = ({siteDetails, setSiteDetails}) => {
    const { googleMapsApiKey, isLoaded } = useContext(GoogleMapsContext);

    const [ originalAddress ] = useState(siteDetails);
    const [ addressChanged, setAddressChanged ] = useState(false);

    const [ editAddress, setEditAddress ] = useState(false);
    const [ isValidAddress, setIsValidAddress ] = useState(false);
    
    const [ gps ] = useState(false);

    const searchInputRef = useRef(null);

    const addressStr = `${siteDetails.address.street}, ${siteDetails.address.city}, ${siteDetails.address.state}, ${siteDetails.address.country}`;
    
    const getAddressFromGoogle = async (addressStr) => {
        const endpoint = `https://maps.googleapis.com/maps/api/geocode/json`;
        try {
            const response = await axios.get(endpoint, {
                params: {
                    address: addressStr,
                    key: googleMapsApiKey
                }
            });
            return response;
        } catch (error) {
            console.error("Error validating address with Google API:", error);
            throw error;
        }
    };

    const checkValidAddress = async () => {
        if (siteDetails?.address?.street && siteDetails?.address?.city && siteDetails?.address?.state && siteDetails?.address?.country) {
            const addressStr = `${siteDetails.address.street}, ${siteDetails.address.city}, ${siteDetails.address.state}, ${siteDetails.address.country}`;
            try {
                const response = await getAddressFromGoogle(addressStr);
                const results = response.data.results;
    
                if (response.data.status === "OK" && results.length > 0) {
                    // Check if the returned address matches the given address
                    const matchedResult = results.find(result => {
                        const addressComponents = result.address_components;
                        const streetNumber = addressComponents.find(comp => comp.types.includes('street_number'))?.long_name;
                        const route = addressComponents.find(comp => comp.types.includes('route'))?.long_name;
                        const fullStreet = `${streetNumber} ${route}`.trim();
    
                        return siteDetails.address.street === fullStreet;
                    });
                    setIsValidAddress(!!matchedResult);
                } else {
                    setIsValidAddress(false);
                }
            } catch (error) {
                console.error("Error validating address with Google API:", error);
                setIsValidAddress(false);
            }
        } else {
            setIsValidAddress(false);
        }
    };
    
    const getLatLonForAddress = async () => {
        const addressStr = `${siteDetails.address.street}, ${siteDetails.address.city}, ${siteDetails.address.state}, ${siteDetails.address.country}`;
    
        try {
            const response = await getAddressFromGoogle(addressStr);
    
            if (response.data.status === "OK" && response.data.results && response.data.results.length > 0) {
                const location = response.data.results[0].geometry.location;
                const lat = Number(location.lat);
                const lon = Number(location.lng);
                
                if (!isNaN(lat) && !isNaN(lon)) {
                    return {lat: lat, lon: lon};
                } else {
                    console.error("Latitude or longitude is not a number.");
                }
            }
        } catch (error) {
            console.error("Error getting lat and lon:", error);
        }
    };
    
    useEffect(() => {
        if (siteDetails && siteDetails.address && siteDetails.address.street && siteDetails.address.city && siteDetails.address.state && siteDetails.address.country) {
            checkValidAddress();
        }
    }, [siteDetails.address]);
    
    useEffect(() => {
        if (gps && isValidAddress) {
            getLatLonForAddress();
        }

    }, [gps, isValidAddress]);

    let newAddress;

    const AddressSearch = () => {
        useEffect(() => {
            if (isLoaded) {
                const autocomplete = new window.google.maps.places.Autocomplete(searchInputRef.current);
                autocomplete.addListener("place_changed", () => {
                    const selectedPlace = autocomplete.getPlace();
                    const latitude = selectedPlace.geometry.location.lat();
                    const longitude = selectedPlace.geometry.location.lng();
                    if (selectedPlace.address_components) {
                        const addressComponents = selectedPlace.address_components;
                        
                        const streetNumber = addressComponents.find(comp => comp.types.includes('street_number'))?.long_name || '';
                        const route = addressComponents.find(comp => comp.types.includes('route'))?.long_name || '';
                        const street = `${streetNumber} ${route}`.trim();
                        
                        const city = addressComponents.find(comp => comp.types.includes('locality'))?.long_name || '';
                        const state = addressComponents.find(comp => comp.types.includes('administrative_area_level_1'))?.short_name || '';
                        const postalCode = addressComponents.find(comp => comp.types.includes('postal_code'))?.long_name || '';
                        const country = addressComponents.find(comp => comp.types.includes('country'))?.long_name || '';
                        
                        newAddress = {
                            ...siteDetails,
                            address: {
                                street,
                                city,
                                state,
                                postalCode,
                                country,
                                coordinates: {
                                    latitude,
                                    longitude,
                                }
                            }
                        };

                        setSiteDetails(newAddress);
                        setEditAddress(false);
                        setIsValidAddress(true);
                        setAddressChanged(true);
                    }
                });
            }
        }, [isLoaded]);

        return (
            <div>
                Search for Address
                <input ref={searchInputRef} type="text" placeholder="Search for address" />
                <button onClick={() => setEditAddress(false)}>Cancel</button>
            </div>
        )
    }

    const handleUseAddressClick = async () => {
        try {
            const coords = await getLatLonForAddress();
            const newcoords = {
                ...siteDetails,
                coordinates: 
                    {center: {
                        latitude: coords.lat,
                        longitude: coords.lon
                    },
                    northwest: {
                        latitude: coords.lat + 0.001,
                        longitude: coords.lon - 0.001
                    },
                    southeast: {
                        latitude: coords.lat - 0.001,
                        longitude: coords.lon + 0.001
                    }
                }
            };

            setSiteDetails(newcoords);
            setAddressChanged(false);
        } catch (error) {
            console.error("Error in getting coordinates:", error);
        }
    };

    const handleCancelClick = () => {
        setSiteDetails(originalAddress);
        setEditAddress(false);
        setAddressChanged(false);
    };

    return  (
        <div>
            {!isValidAddress ? (
                <AddressSearch />
            ) : (
                <>
                   {!editAddress ? (
                    <>
                        Address {addressStr }
                        <button onClick={() => setEditAddress(!editAddress)}> Edit </button>
                        {addressChanged && (
                            <>
                                <button onClick={handleUseAddressClick}>Use Address</button>
                                <button onClick={handleCancelClick}>Cancel</button>
                            </>
                        )}
                        </>) : (
                        <>
                            <AddressSearch />
                        </>
                    )}
                </>
            )}
        </div>
    )
};

export default SiteAddress;

