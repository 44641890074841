import React, { useState, useEffect } from 'react';

const AccountDetailsPage = () => {
  const [message, setMessage] = useState('Loading...');

  useEffect(() => {
    fetch('https://us-central1-playformia-d3d22.cloudfunctions.net/countUsers')
      .then(response => response.json())
      .then(data => {
        setMessage(data.count);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  return (
    <div className="payment-setup-page panel">
      <div className="payment-setup-header">{message}</div>
      <h1>Subscribe to our service</h1>
    </div>
  );
};

export default AccountDetailsPage;
