import React, { useState, useRef, useContext, useEffect } from 'react';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useMediaManagement from '../Hooks/UseMediaManagement';
import useSalesManagement from '../Hooks/UseSalesManagement';

import { EditorContext } from '../Contexts/EditorContext';
import { ModalContext } from '../Contexts/ModalContext';
import { MediaContext } from '../Contexts/MediaContext';

import MediaPicker, { MediaThumbnail } from '../Controls/MediaPicker';

import './MediaLibrary.scss';

const MediaLibrary = ({addingSite}) => {
    const { showModal } = useContext(ModalContext);
    const [ renderIndex, setRenderIndex] = useState(0);

    const { userEvents, getSelectedEvent, updateItem } = useEditorManagement();
    const { getSelectedSubscription } = useSalesManagement();
    const selectedEvent = getSelectedEvent();

    const { uploadFiles, deleteMedia, copyMedia, renameMedia, addMediaToSite, getMediaLibrary } = useMediaManagement();
    const { mediaLibraryFiles, selectedMediaType, setSelectedMediaType, selectedMediaFile, setSelectedMediaFile, setMediaLibraryFiles } = useContext(MediaContext);

    const [ dragOver, setDragOver ] = useState(false);
    const [ uploadStatus, setUploadStatus ] = useState([]);
    const [ selectFirst, setUSelectFirst] = useState(false);

    const [ mediaPickerType, setMediaPickerType ] = useState('');
    const [ currentOnSelect, setCurrentOnSelect ] = useState(null);

    const [ showMediaPicker, setShowMediaPicker ] = useState(false);
    const [ activeMediaType, setActiveMediaType ] = useState('all');
    
    const fileInputRef = useRef(null);
    const { selectedEventId } = useContext(EditorContext);

    const [ subscription, setSubscription ] = useState(null);

    const [ imageCount, setImageCount ] = useState(0);
    const [ audioCount, setAudioCount ] = useState(0);
    const [ videoCount, setVideoCount ] = useState(0);
    const [ meshCount, setMeshCount ] = useState(0);


    useEffect(() => {
        const loadSubscription = async () => {
            const selectedSubscription = await getSelectedSubscription();
            if (selectedSubscription) {
                setSubscription(selectedSubscription);
            }
        };

        loadSubscription();
    }, []);

    useEffect(() => {
        if (renderIndex < mediaLibraryFiles.length) {
            const timer = setTimeout(() => {
                setRenderIndex(renderIndex + 1);
            }, 100); // Adjust the delay as needed
    
            return () => clearTimeout(timer);
        }
    }, [renderIndex, mediaLibraryFiles.length]);

    useEffect(() => {
      if (selectedEventId) {
        getMediaLibrary();
      }
    }, [selectedEventId]);

    useEffect(() => {
        if (renderIndex < mediaLibraryFiles.length) {
            const timer = setTimeout(() => {
                setRenderIndex(renderIndex + 1);
            }, 0);

            return () => clearTimeout(timer);
        }
    }, [renderIndex, mediaLibraryFiles.length])

    const handleMediaTypeChange = (mediaType) => {
        setSelectedMediaType(mediaType);
        setActiveMediaType(mediaType);
    };

    const filteredMediaFiles = mediaLibraryFiles.filter(mediaItem => {
        if (selectedMediaType === 'all') return true;
        return mediaItem.fullPath.includes(`/${selectedMediaType}/`);
    });

    useEffect(() => {
        if (selectFirst) return;
        if (filteredMediaFiles.length > 0) {
            setSelectedMediaFile(filteredMediaFiles[0]);
            setUSelectFirst(true);
        } else {
            setSelectedMediaFile(null);
        }
    }, [filteredMediaFiles, setSelectedMediaFile]);

    useEffect(() => {
        const imageFiles = mediaLibraryFiles.filter(file => file.fileType.includes('image'));
        const audioFiles = mediaLibraryFiles.filter(file => file.fileType.includes('audio'));
        const videoFiles = mediaLibraryFiles.filter(file => file.fileType.includes('video'));
        const meshFiles = mediaLibraryFiles.filter(file => file.fileType.includes('mesh'));

        setImageCount(imageFiles.length);
        setAudioCount(audioFiles.length);
        setVideoCount(videoFiles.length);
        setMeshCount(meshFiles.length);
    }, [mediaLibraryFiles]);

    if (subscription === null) {
        // Return a loader or null while subscription is not loaded
        return <div>Loading...</div>;
    }

    const maxMediaCount = parseInt(subscription.mediaCount.split('/')[1], 10);

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        let remainingQuota = maxMediaCount - mediaLibraryFiles.length;
    
        if (remainingQuota <= 0) {
            alert('You have reached the maximum number of media files allowed in your subscription.');
            return;
        }
    
        if (files.length > remainingQuota) {
            alert(`You can only upload ${remainingQuota} more file(s).`);
            files.splice(remainingQuota);
        }
    
        if (files.length) {
            uploadFiles(files, setUploadStatus);
        }
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        const files = Array.from(e.dataTransfer.files);
        let remainingQuota = maxMediaCount - mediaLibraryFiles.length;
    
        if (remainingQuota <= 0) {
            alert('You have reached the maximum number of media files allowed in your subscription.');
            return;
        }
    
        if (files.length > remainingQuota) {
            alert(`You can only upload ${remainingQuota} more file(s).`);
            files.splice(remainingQuota);  // Adjust the array to contain only the number of files that can be uploaded
        }
    
        if (files.length) {
            uploadFiles(files, setUploadStatus);
        }
    };
   

    const handleMediaSelect = (media) => {
        setSelectedMediaFile(media);
    };

    const handlAddMediaToSite = async (media) => {
        addMediaToSite(media);
    };
    
    const handleDeleteMedia = async (media) => {
        let isMediaUsed = false;
        let sitesUsingMedia = [];
        let isUsedAsTexture = false;
        let mediaUsingAsTexture = [];

        for (const event of userEvents) {
            if (event.icon === media.id) {
                isMediaUsed = true;
                sitesUsingMedia.push(`(Event Icon: ${event.name})`);
            }
            for (const site of selectedEvent.sites) {
                // Check if media is used in the site
                if (site.media.some(m => m.id === media.id)) {
                    isMediaUsed = true;
                    sitesUsingMedia.push(`(Site:${site.name}) (Event: ${event.name})`);
                }
                if (site.icon === media.id) {
                    isMediaUsed = true;
                    sitesUsingMedia.push(`(Site Icon: ${site.name})`);
                }

                // Check if media is used as a texture in any media of the site
                site.media.forEach(m => {
                    if (m.texture === media.id) {
                        isUsedAsTexture = true;
                        mediaUsingAsTexture.push(`${m.name} in ${site.name} (event : ${event.name})`);
                    }
                });
            }
        }

        mediaLibraryFiles.forEach(mediaItem => {
            if (mediaItem.texture === media.id) {
                isUsedAsTexture = true;
                mediaUsingAsTexture.push(mediaItem.name);
            }
        });
    
        if (isMediaUsed) {
            alert(`Cannot delete. This media is used in the following: ${sitesUsingMedia.join(', ')}`);
        } else if (isUsedAsTexture) {
            alert(`Cannot delete. This media is used as a texture in the following media: ${mediaUsingAsTexture.join(', ')}`);
        } else {
            if (window.confirm('Are you sure you want to delete this media?')) {
                await deleteMedia(media)
                setSelectedMediaFile(null);
            }
        }
    };

    const handleRename = async  () => {
        const position = { top: 0, left: 0 };
        showModal('rename', null, { newName: selectedMediaFile.name }, position, renameMedia);
    };

    const handleCopyMedia = async (media) => {
        copyMedia(media);
    };

    const triggerFileSelect = () => {
        fileInputRef.current.click();
    };
    
    const handleTextureSelect = async (textureImage) => {
        if (selectedMediaFile && selectedMediaFile.fileType.includes('mesh')) {  
            try {
                const updatedMediaFile = { ...selectedMediaFile, texture: textureImage.id };
                await updateItem('mediaFile', updatedMediaFile);
                setSelectedMediaFile(updatedMediaFile);

                const updatedMediaFiles = mediaLibraryFiles.map(item => {
                    if (item.id === updatedMediaFile.id) {
                      return updatedMediaFile;
                    }
                    return item;
                  });
                  
                  // Assuming you have a way to update the mediaLibraryFiles state or context
                  setMediaLibraryFiles(updatedMediaFiles);
            } catch (error) {
                console.error('Error updating media in Firestore:', error);
            }
        }
        setShowMediaPicker(false);
    };    

    const showTexturePicker = () => {
        setShowMediaPicker(true);
        setMediaPickerType('image');
        setCurrentOnSelect(() => handleTextureSelect);
    };

    const handleAudioSelect = async (audioItem) => {
        if (selectedMediaFile) {
            try {
                const updatedMediaFile = { ...selectedMediaFile, audio: audioItem.id };
                await updateItem('mediaFile', updatedMediaFile);
                setSelectedMediaFile(updatedMediaFile);
    
                const updatedMediaFiles = mediaLibraryFiles.map(item => {
                    if (item.id === updatedMediaFile.id) {
                        return updatedMediaFile;
                    }
                    return item;
                });
    
                setMediaLibraryFiles(updatedMediaFiles);
            } catch (error) {
                console.error('Error updating media in Firestore:', error);
            }
        }
        setShowMediaPicker(false);
    };
    
    const handleRemoveAudio = async () => {
        if (selectedMediaFile && selectedMediaFile.audio) {
            try {
                const updatedMediaFile = { ...selectedMediaFile, audio: '' }; // Remove audio by setting it to an empty string
                await updateItem('mediaFile', updatedMediaFile); // Assuming you have a function to update items in your backend
                setSelectedMediaFile(updatedMediaFile); // Update local state
    
                // Update the mediaLibraryFiles array to reflect this change
                const updatedMediaFiles = mediaLibraryFiles.map(item => {
                    if (item.id === updatedMediaFile.id) {
                        return updatedMediaFile;
                    }
                    return item;
                });
    
                setMediaLibraryFiles(updatedMediaFiles); // Assuming you have a way to update the mediaLibraryFiles state
            } catch (error) {
                console.error('Error removing audio from media in Firestore:', error);
            }
        }
    };
      
    const showAudioPicker = () => {
        setMediaPickerType('audio');
        setCurrentOnSelect(() => handleAudioSelect);
        setShowMediaPicker(true);
    };

    return (
        <div className='media-library'>
            <div className='media-library-left panel'>
                { selectedMediaFile !== null && (
                    <div className='selected-media-info'>
                        <div className='selected-media-details'>
                            { mediaLibraryFiles.length > 0 ? (
                                <div className='selected-media-viewer panel'>
                                    <MediaThumbnail key={selectedMediaFile.id} mediaItem={selectedMediaFile} label={true} />
                                </div>
                                ) : (<div className='selected-media-viewer panel'>
                                        <div className='no-media-message'>No media in library</div>
                                    </div>
                            )}
                            <div> { selectedMediaFile.fileType}</div>
                            <div> { (selectedMediaFile.size / 1024).toFixed(2) } KB</div>
                        </div>
                        <div className='selected-media-options'> 
                            <button onClick={() => handlAddMediaToSite(selectedMediaFile)}>Add to Site</button>
                            {!addingSite && (
                                <div className='selected-media-options'>
                                    <button onClick={handleRename}>Rename</button>
                                    {selectedMediaFile && selectedMediaFile.fileType.includes('mesh') && (
                                        <button onClick={() => handleCopyMedia(selectedMediaFile)}>Duplicate Media</button>
                                    )}                        
                                    <button onClick={() => handleDeleteMedia(selectedMediaFile)}>Delete Media</button>
                                    {File && selectedMediaFile.fileType.includes('mesh') && (
                                        <button onClick={showTexturePicker}>
                                            {selectedMediaFile.texture && selectedMediaFile.texture !== '' ? 'Change Texture' : 'Add Texture'}
                                        </button>
                                    )}
                                    {File && (selectedMediaFile.fileType.includes('image') || selectedMediaFile.fileType.includes('mesh')) && (
                                        <button onClick={showAudioPicker}>
                                            {selectedMediaFile.audio && selectedMediaFile.audio !== '' ? 'Change Audio' : 'Add Audio'}
                                        </button>
                                    )}
                                    {selectedMediaFile && selectedMediaFile.audio && selectedMediaFile.audio !== '' && (
                                        <button onClick={handleRemoveAudio}>Remove Audio</button>
                                    )}
                                </div>
                            )} 
                        </div>
                    </div>
                )}
                <div className={`drop-area ${dragOver ? 'drag-over' : ''}`}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                Drag and drop files here
                    <button onClick={triggerFileSelect}>Or select files</button>
                    <input 
                        ref={fileInputRef}
                        type='file' 
                        multiple 
                        onChange={handleFileChange} 
                        style={{ display: 'none' }}
                    />
                </div>
                {uploadStatus.length > 0 && (
                    <div className='upload-status'>
                        <div>Upload Status:</div>
                        <ul>
                            {uploadStatus.map((file, index) => (
                                <li key={index}>
                                    {file.name} - 
                                    {file.status === 'uploading' && 'Uploading...'}
                                    {file.status === 'successful' && 'Uploaded'}
                                    {file.status === 'failed' && `Failed: ${file.reason}`}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className='media-right panel'>
                <div className='media-filter-buttons panel'>
                    <button
                        className={activeMediaType === 'all' ? 'selected' : ''}
                        onClick={() => handleMediaTypeChange('all')}
                    >
                        All {mediaLibraryFiles.length > 0 ? `(${mediaLibraryFiles.length} / ${maxMediaCount})` : ''}
                    </button>
                    <button
                        className={activeMediaType === 'image' ? 'selected' : ''}
                        onClick={() => handleMediaTypeChange('image')}
                    >
                        Images {`(${imageCount})`}
                    </button>
                    <button
                        className={activeMediaType === 'audio' ? 'selected' : ''}
                        onClick={() => handleMediaTypeChange('audio')}
                    >
                        Audio {`(${audioCount})`}
                    </button>
                    <button
                        className={activeMediaType === 'videos' ? 'selected' : ''}
                        onClick={() => handleMediaTypeChange('videos')}
                    >
                        Videos {`(${videoCount})`}
                    </button>
                    <button
                        className={activeMediaType === 'mesh' ? 'selected' : ''}
                        onClick={() => handleMediaTypeChange('mesh')}
                    >
                        3D Models {`(${meshCount})`}
                    </button>
                </div>
                {filteredMediaFiles.length > 0 ? (
                    <div className='media-files-grid'> 
                        {filteredMediaFiles.map((mediaItem, index) => (
                            <div 
                                key={index} 
                                className={`media-files-grid-item ${selectedMediaFile?.id === mediaItem?.id ? 'selected' : ''}`} 
                                onClick={() => handleMediaSelect(mediaItem)}
                            >
                                <MediaThumbnail key={`${mediaItem.id}_${mediaItem.texture}`} mediaItem={mediaItem} label={true} shouldLoad={index <= renderIndex}/>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='no-media-message'>
                        {selectedMediaType === 'all' ? 
                            "No files of any type in your library." : 
                            `No files of type '${selectedMediaType}' in your library.`}
                    </div>
                )}
                {showMediaPicker && (
                    <MediaPicker
                        mediaType={mediaPickerType}
                        onSelect={currentOnSelect}  
                        onClose={() => setShowMediaPicker(false)}
                    />
                )}
            </div>
        </div>
    );    
};

export default MediaLibrary;
