import React, { useEffect, useState } from 'react';

import SiteDetails from './SiteDetails';
import MediaList from './MediaList';
import Viewport from './Viewport';
import SystemNotification from '../Controls/SystemNotification';

import useEditorManagement from '../Hooks/UseEditorManagement';
import useMediaManagement from '../Hooks/UseMediaManagement';

import { useMessage } from '../Contexts/SystemMessageContext';

import './EventEditor.scss';

const EventEditor = () => {  
  const { message, showMessage, clearMessage  } = useMessage();
  const { selectItem, getSelectedSite, mapLoadingStatus, setActiveComponent } = useEditorManagement();
  const { getMediaLibrary } = useMediaManagement();
  const [ siteDetails ] = useState(getSelectedSite());
  const [ deferredMediaSelection, setDeferredMediaSelection ] = useState(null);
  const [ notificationKey, setNotificationKey ] = useState(0);

  const handleMediaSelect = (media) => {
    if (mapLoadingStatus === 'loaded') {
      selectItem({ itemType: 'media', id: media?.guid });
    } else {
        setDeferredMediaSelection(media);
    }
  };

  useEffect(() => {
    getMediaLibrary()
  }, []);

  useEffect(() => {
    if (message) {
      setNotificationKey(prevKey => prevKey + 1);
    }
  }, [message, showMessage, clearMessage ]);

  // deferred selection
  useEffect(() => {
      if (mapLoadingStatus === 'loaded' && deferredMediaSelection) {
          selectItem({ itemType: 'media', id: deferredMediaSelection?.guid });
          setDeferredMediaSelection(null);
      }
  }, [mapLoadingStatus, deferredMediaSelection]);

  return (
    <div className='event-editor'>
      <div className='event-editor-left-panel'>
      <button onClick={() => setActiveComponent('userAccount')}>
        User Account
      </button>
        {siteDetails ? (
          <div className = "site-details-container">
            <SiteDetails />
          </div>
        ) : null}
        {siteDetails ? (
          <div className = "media-details-container">
            <MediaList handleMediaSelect={handleMediaSelect} />
          </div>
        ) : null}
      </div>
      <div className = "viewport-container">
          <SystemNotification message={message} key={notificationKey}/>
          <Viewport />
      </div>
    </div>
  );
};

export default EventEditor;
