import React, { useEffect, useRef, useState, useContext } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';

import useEditorManagement from '../Hooks/UseEditorManagement.js';

import { GoogleMapsContext } from '../Contexts/GoogleMapsContext.js';

import './EventMap.scss';

const mapContainerStyle = {
    width: '100%',
    height: '100%',
};

const EventMap = () => {
    const { getSelectedEvent, getSelectedSite, selectItem, setViewportType } = useEditorManagement();

    const eventSites = getSelectedEvent().sites;
    const selectedSite = getSelectedSite();
    const { isLoaded, loadError, mapStyle } = useContext(GoogleMapsContext);

    const mapRef = useRef(null);
    const [ center, setCenter ] = useState({ lat: 32, lng: -97 });
    const [ boundsSet, setBoundsSet ] = useState(false);

    const mapOptions = {
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: "greedy",
        styles: mapStyle
    };

    useEffect(() => {
        if (isLoaded && eventSites.length > 0) {
            onIdle();
            setBoundsSet(false);
        }
    }, [isLoaded, selectedSite]);

    const onLoad = map => {
        mapRef.current = map;
    };

    const onIdle = () => {
        if (!mapRef.current || boundsSet || eventSites.length === 0) return;

        const bounds = new window.google.maps.LatLngBounds();
        eventSites.forEach(site => {
            if (site.coordinates?.center?.latitude && site.coordinates?.center?.longitude) {
                bounds.extend(new window.google.maps.LatLng(Number(site.coordinates.center.latitude), Number(site.coordinates.center.longitude)));
            }
        });

        if (!bounds.isEmpty()) {
            mapRef.current.fitBounds(bounds);
            const center = bounds.getCenter();
            setCenter({ lat: center.lat(), lng: center.lng() });
            setBoundsSet(true);
        }
    };

    if (loadError) return <div>Error loading maps</div>;
    if (!isLoaded) return <div>Loading Maps...</div>;

    return (
        <div className='event-map'>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={15}
                onLoad={onLoad}
                onIdle={onIdle}
                options={mapOptions}
            >
                {eventSites.map(site => {
                    const position = { lat: Number(site.coordinates.center.latitude), lng: Number(site.coordinates.center.longitude) };
                    const isSiteSelected = selectedSite && site.id === selectedSite.id;
                    return <Marker 
                        key={site.id}
                        position={position}
                        onClick={() => {
                            if (site.id !== selectedSite?.id) {
                                selectItem({ itemType: 'site', id: site.id });
                            } else {
                                setViewportType(2);
                            }
                        }}

                        label={{
                            text: site.name, 
                            color: "Green",
                            fontSize: "16px",
                            fontWeight: "bold",
                        }}
                        icon={{
                            url: isSiteSelected ? "http://maps.google.com/mapfiles/ms/icons/green-dot.png" : "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // Green for selected, red for others
                            scaledSize: new window.google.maps.Size(32, 32),
                            labelOrigin: new window.google.maps.Point(16, -10)
                        }}
                    />;
                })
              
                } 
            </GoogleMap>
        </div>
    );
};

export default EventMap;